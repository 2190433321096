import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'pubnubDate',
})
export class PubnubDatePipe implements PipeTransform {
  transform(value: Date): string {
    const now = new Date();
    let day = '';
    if (now.getDate() - 1 === value.getDate()) {
      day = 'Yesterday';
    } else if (now.getDate() === value.getDate()) {
      day = 'Today';
    } else {
      day = moment(value).format('dddd, MMMM, DD, YYYY');
    }
    const time = moment(value).format('HH:mmA');
    return `${day}, ${time}`;
  }
}
