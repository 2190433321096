import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OfferService } from '../offer.service';
import { UserService } from '../../user/user.service';
import { UserInterface } from '../../user/user.interface';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { ListingService } from '../../listings/listing.service';
import { OfferInterface, OfferStatusEnum } from '../../listings/listing.interface';
import { PubnubService } from '../pubnub/pubnub.service';

export type Modes = 'seller' | 'investor';
export type OffersOrder = 'newest' | 'oldest' | 'highest-offer';
export type PageMode = 'desktop' | 'offers' | 'pubnub';

@Component({
  selector: 'offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit, OnDestroy {
  user: UserInterface;

  isInvestor: boolean;

  mode: PageMode = 'desktop';

  isModalShowed = false;

  isRateShowed = false;

  isModalsChecked = false;

  offerID: number;

  unreadMessages: Set<number> = new Set();

  investors$: Observable<OfferInterface[]>;

  currentOffer: OfferInterface;

  offersOrder: OffersOrder = 'highest-offer';

  private destroy$ = new Subject<void>();

  private refresh$ = new BehaviorSubject(true);

  private offerUpdated$ = new Subject<number>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private pubnubService: PubnubService,
    private offerService: OfferService,
    private listingService: ListingService,
    private userService: UserService,
    private headerFooterStateService: HeaderFooterStateService,
  ) {}

  ngOnInit(): void {
    if (window.innerWidth <= 1024) {
      this.mode = 'offers';
    }
    this.headerFooterStateService.setView('noFooter');
    this.offerUpdated$.subscribe((id) => {
      this.refresh$.next(true);
      this.pubnubService.sendRefreshListing(id);
    });
    this.investors$ = this.refresh$.pipe(
      switchMap(() => this.activatedRoute.params),
      takeUntil(this.destroy$),
      filter((res) => res.id !== this.offerID),
      switchMap((params) => {
        this.unreadMessages.clear();
        this.offerID = parseInt(params.id, 10);
        return this.offerService.getOffer(this.offerID).pipe(
          tap((offer) => {
            this.isModalShowed = false;
            this.currentOffer = offer;
            this.user = this.userService.getUser();
            this.isInvestor = this.user.email === offer.buyer.email;
            this.showModals(offer);
          }),
          filter(() => !this.isInvestor),
          switchMap((res) => this.listingService.getOffersForListening(res.listing.id)),
          map((listing) => listing.offers),
        );
      }),
    );
    this.pubnubService
      .getUpdateOffer$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.refreshDataHandler(true);
      });
  }

  deleteListing(id: number): void {
    this.pubnubService.deleteListing(id);
  }

  showModals(offer: OfferInterface): void {
    if (this.isInvestor && this.currentOffer.status === OfferStatusEnum.deal_closed) {
      if (!this.isModalShowed) {
        this.offerService.checkDealClosed(offer, this.offerUpdated$);
        this.isModalShowed = true;
      }
    }
    if (
      this.isModalsChecked &&
      this.isInvestor &&
      this.currentOffer.status === OfferStatusEnum.deal_rejected
    ) {
      this.offerService.loseDeal();
    }
    this.isModalsChecked = true;
  }

  refreshDataHandler(event: boolean): void {
    this.refresh$.next(event);
  }

  changeOrder(order: OffersOrder): void {
    this.offersOrder = order;
  }

  changeMode(mode: PageMode): void {
    if (this.mode !== 'desktop') {
      this.mode = mode;
    }
  }

  updateNewMessage(offer: number): void {
    this.unreadMessages.add(+offer);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
