import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleErrorService } from './handle-error.service';

@Injectable({
  providedIn: 'root',
})
export class ServerErrorsInterceptor implements HttpInterceptor {
  constructor(private error: HandleErrorService) {}

  // intercept function
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // returning an observable to complete the request cycle
    return next.handle(req).pipe(
      catchError((err) => {
        this.error.handleError(err);

        return throwError(err);
      }),
    );
  }
}
