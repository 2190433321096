<ng-container *ngIf="!notFoundError">
  <div class="post-page" *ngIf="listing && user">
    <div class="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
      <ul class="breadcrumbs__list ul-reset">
        <li
          class="breadcrumbs__item"
          itemscope=""
          property="itemListElement"
          itemtype="http://schema.org/ListItem"
        >
          <a routerLink="/deals" itemprop="item" class="breadcrumbs__link">
            <span itemprop="name">BACK TO LISTINGS</span>
          </a>
          <meta itemprop="position" content="1" />
        </li>
      </ul>
    </div>
    <section class="post-page__section">
      <div class="post-page__price-box">
        <div class="post-page__price-container">
          <div class="post-page__price-left">
            <div class="post-page__price-suptitle">LISTING PRICE</div>
            <div class="post-page__price-value">$ {{ listing.price | number: '.2' }}</div>
          </div>
          <div class="post-page__price-right">
            <div class="post-page__price-suptitle">AFTER REPAIR VALUE</div>
            <div class="post-page__price-value">$ {{ listing.ARV_price | number: '.2' }}</div>
          </div>
          <div class="post-page__price-right">
            <div class="post-page__price-suptitle">CURRENT PRICE</div>
            <div class="post-page__price-value">$ {{ listing.higher_offer_price | number: '.2' }}</div>
          </div>
          <div class="d-flex">
            <share [shareLink]="shareLink"></share>
            <button
              class="add-favorite"
              [class.add-favorite__liked]="listing.is_liked_by_user"
              (click)="toggleLike()"
            >
              {{ listing.likes ? (listing.likes | number) : '' }}
            </button>
          </div>
        </div>
      </div>
      <div class="photo-slider">
        <div class="photo-slider__wrapper">
          <div class="photo-slider__main" (click)="openFullScreen(0)">
            <ng-container *ngTemplateOutlet="mediaWrapper; context: listingMedia[0]"></ng-container>
          </div>
          <div class="photo-slider__right">
            <div class="photo-slider__alt" (click)="openFullScreen(1)">
              <ng-container
                *ngTemplateOutlet="mediaWrapper; context: listingMedia[1]"
              ></ng-container>
            </div>
            <div class="photo-slider__alt" (click)="openFullScreen(2)">
              <ng-container
                *ngTemplateOutlet="mediaWrapper; context: listingMedia[2]"
              ></ng-container>
            </div>
            <button class="photo-slider__more" *ngIf="listingMedia.length > 3">
              +{{ listingMedia.length - 3 }}
            </button>
          </div>
          <media-fullscreen-view
            [media]="listingMedia"
            [mediaIndex]="selectedImage"
            [hideDelete]="true"
            [showPreview]="showFullScreenFlag"
            (nextMedia)="imageMoveRight()"
            (prevMedia)="imageMoveLeft()"
            (closePreview)="closeFullScreen()"
          >
          </media-fullscreen-view>
        </div>
      </div>
      <div class="post-page__author">
        <div class="post-page__author-block">
          <img
            [src]="user.photo || '../../../images/icons/avatar.png'"
            alt=""
            class="post-page__author-photo"
          />
          <div class="post-page__author-text">
            <div class="post-page__author-name">{{ user.first_name + ' ' + user.last_name }}</div>
            <div class="post-page__author-rating">
              {{ user.rating || 0 | number: '1.1-1' }} Rating
            </div>
          </div>
        </div>
        <div class="post-page__author-date">Posted {{ listing.created_at | dateAgo }}</div>
      </div>
      <div class="post-page__description">
        {{ listing.description }}
      </div>
      <div class="post-page__details">
        <div class="post-page__details-box">
          <div class="post-page__details-title">LISTING DETAILS</div>
          <div class="post-page__details-table">
            <div class="post-page__details-row">
              <div class="post-page__details-caption">
                Estimated Repair Cost
                <span class="post-page__details-info"> (est)</span>
              </div>
              <div class="post-page__details-value">
                $ {{ listing.repair_price | number: '.2' }}
              </div>
            </div>
            <div class="post-page__details-row">
              <div class="post-page__details-caption">Bedrooms</div>
              <div class="post-page__details-value">{{ listing.bedroom_amount }} Bedrooms</div>
            </div>
            <div class="post-page__details-row">
              <div class="post-page__details-caption">Bathrooms</div>
              <div class="post-page__details-value">{{ listing.bathroom_amount }} Bathrooms</div>
            </div>
            <div class="post-page__details-row">
              <div class="post-page__details-caption">LOT Size</div>
              <div class="post-page__details-value">{{ listing.lot_size | number: '.2' }} SQFT</div>
            </div>
          </div>
        </div>
        <div class="post-page__details-box">
          <div class="post-page__details-title">LISTING DETAILS</div>
          <agm-map
            class="post-page__details-map"
            [latitude]="listing.address.point.x"
            [longitude]="listing.address.point.y"
            [zoom]="16"
            [disableDefaultUI]="true"
          >
            <agm-marker
              [iconUrl]="mapIconOptions"
              [latitude]="listing.address.point.x"
              [longitude]="listing.address.point.y"
              [label]="labelOptions"
            ></agm-marker>
            <agm-circle
              [latitude]="listing.address.point.x"
              [longitude]="listing.address.point.y"
              [radius]="200"
              [circleDraggable]="false"
              [fillColor]="'#4268EC'"
              [fillOpacity]="0.25"
              [editable]="false"
            ></agm-circle>
          </agm-map>
        </div>
      </div>
      <ng-container *ngIf="listing.comparison">
        <div class="post-page__details-title">COMPARISON DETAILS</div>
        <div class="post-page__deal">
          <div class="post-page__deal-img" [ngClass]="{ 'min-h-400': !listing.comparison.photo }">
            <img [src]="listing.comparison.photo" alt="" />
          </div>
          <div class="post-page__deal-box">
            <div class="post-page__deal-suptitle">SALE VALUE</div>
            <div class="post-page__deal-price">$ {{ listing.comparison.price | number: '.2' }}</div>
            <div class="post-page__deal-table">
              <div class="post-page__deal-row">
                <div class="post-page__deal-caption">Bedrooms</div>
                <div class="post-page__deal-value">
                  {{ listing.comparison.bedroom_amount }} Bedrooms
                </div>
              </div>
              <div class="post-page__deal-row">
                <div class="post-page__deal-caption">Bathrooms</div>
                <div class="post-page__deal-value">
                  {{ listing.comparison.bathroom_amount }} Bathrooms
                </div>
              </div>
              <div class="post-page__deal-row">
                <div class="post-page__deal-caption">Date Sold</div>
                <div class="post-page__deal-value">
                  {{ comparisonDateSold | date: 'd of MMMM yyyy' | dateSuffix }}
                </div>
              </div>
              <div class="post-page__deal-row">
                <div class="post-page__deal-caption">Address</div>
                <div class="post-page__deal-value">
                  {{ listing.comparison.address.readable_address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="listing.similar_listings?.length">
        <div class="post-page__similar">
          <div class="post-page__similar-title">SIMILAR POSTS IN AREA</div>
          <div class="post-page__similar-box">
            <div
              class="post-page__similar-item"
              *ngFor="let similarListing of listing.similar_listings"
            >
              <a [routerLink]="'/listing/' + similarListing.id">
                <img alt="" class="post-page__similar-img" [src]="getSource(similarListing)" />
                <div class="post-page__similar-caption">
                  {{ similarListing.address.readable_address }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
  </div>
  <footer class="footer post-page__footer" *ngIf="displayAdditionalFooter">
    <div
      class="post-page__footer-container"
      *ngIf="!listing.is_user_has_offer && !isClosedListing(listing)"
    >
      <button class="message-user btn active" (click)="placeMessage()">Message User</button>
      <button class="place-offer btn submit" (click)="placeOffer()">Place Offer</button>
    </div>
    <div
      class="post-page__footer-container"
      *ngIf="listing.is_user_has_offer && !isClosedListing(listing)"
    >
      <button class="place-offer btn submit" [routerLink]="'/offer/' + listing.is_user_has_offer">
        Open My Offer
      </button>
    </div>
    <div class="post-page__footer-closed" *ngIf="isClosedListing(listing)">
      <span class="closed-text">
        Sorry, but this listing has already been closed. Hope you'll find a worthy property on
        DealUp!
      </span>
    </div>
  </footer>
</ng-container>

<ng-container *ngIf="notFoundError">
  <p class="not-found">
    Thank you for visiting this page. You are not authorized to view it, probably this Listing was
    closed or removed by publisher.
  </p>
</ng-container>

<ng-template #mediaWrapper let-image="image" let-video="video" let-preview_image="preview_image">
  <ng-container *ngIf="image">
    <img imageLoader [src]="image" alt="" />
  </ng-container>
  <ng-container *ngIf="video">
    <video [src]="video" [poster]="preview_image"></video>
    <button class="photo-slider__video"></button>
  </ng-container>
</ng-template>
