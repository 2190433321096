/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { environment } from '../../environments/environment';
import { UserInterface } from '../user/user.interface';

Sentry.init({
  dsn: environment.sentryDSN,
  enabled: true,
  environment: environment.sentryEnvName,
  attachStacktrace: true,
  ignoreErrors: [],
  maxValueLength: 250 * 10,
  beforeBreadcrumb(crumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null {
    if (crumb.category === 'console' && crumb.level === Sentry.Severity.Log) {
      return null;
    }
    if (crumb.category === 'console' && crumb.level === Sentry.Severity.Error) {
      if (crumb.data.extra.arguments && crumb.data.extra.arguments.length === 0) {
        // eslint-disable-next-line no-param-reassign
        delete crumb.data.extra;
        // eslint-disable-next-line no-param-reassign
        delete crumb.data.logger;
      }
    }
    return crumb;
  },
} as Sentry.BrowserOptions);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private serviceInjector: Injector) {}

  handleError(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any,
    silent = false,
    goback = true,
    showNotification = false,
    customMessage?: string,
  ): void {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(error);
    } else {
      Sentry.withScope((scope: Sentry.Scope) => {
        const user: UserInterface = JSON.parse(localStorage.getItem('user'));
        if (user && user.email) {
          const userForSentry: Sentry.User = {
            username: user.first_name,
            id: String(user.id),
            email: user.email,
          };
          scope.setUser(userForSentry);
          scope.setExtra('userRawData', user);
        }
        Sentry.captureException(error);
      });
    }
  }
}
