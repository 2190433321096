<main class="post-view">
  <section class="post-view__offer hide-scrollbar">
    <div class="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
      <ul class="breadcrumbs__list ul-reset">
        <li
          class="breadcrumbs__item"
          itemscope=""
          property="itemListElement"
          itemtype="http://schema.org/ListItem"
        >
          <a
            [routerLink]="isInvestor ? '/my-offers' : '/my-listings'"
            itemprop="item"
            class="breadcrumbs__link"
          >
            <span itemprop="name">{{ isInvestor ? 'BACK TO OFFERS' : 'BACK TO LISTINGS' }}</span>
          </a>
          <meta itemprop="position" content="1" />
        </li>
      </ul>
    </div>
    <div class="post-view__section">
      <div class="post-view__price-box">
        <div class="post-view__price-left">
          <div class="post-view__price-suptitle">LISTING PRICE</div>
          <div class="post-view__price-value">$ {{ offer?.listing.price }}</div>
        </div>
        <div class="post-view__price-right">
          <div class="post-view__price-suptitle">AFTER REPAIR VALUE</div>
          <div class="post-view__price-value">$ {{ offer?.listing.ARV_price }}</div>
        </div>
      </div>
      <div class="photo-slider-small">
        <div class="photo-slider-small__wrapper">
          <div class="photo-slider-small__main">
            <ng-container *ngFor="let el of getArr(); let index = index; trackBy: trackByFn">
              <img
                [class.d-hidden]="imageIndex !== index"
                imageLoader
                [src]="el.image || el.preview_image"
                alt=""
              />
            </ng-container>
          </div>
        </div>
        <div class="photo-slider-small__dot">
          <ng-container *ngFor="let image of getArr(); let index = index">
            <span
              [class.active]="imageIndex === index"
              class="dots"
              (click)="changeImage(index)"
            ></span>
          </ng-container>
        </div>
      </div>
      <div class="post-view__author-date" *ngIf="daysAfterCreation > 0">
        Posted {{ daysAfterCreation }} days ago
      </div>
      <div class="post-view__author-date" *ngIf="daysAfterCreation === 0">Posted Today</div>
      <div class="post-view__description">
        {{ offer?.listing.description }}
      </div>
      <div class="post-view__details">
        <div class="post-view__details-title">LISTING DETAILS</div>
        <div class="post-view__details-table">
          <div class="post-view__details-row">
            <div class="post-view__details-caption">
              Estimated Repair Cost
              <span class="post-view__details-info">(est)</span>
            </div>
            <div class="post-view__details-value">$ {{ offer?.listing.repair_price }}</div>
          </div>
          <div class="post-view__details-row">
            <div class="post-view__details-caption">Bedrooms</div>
            <div class="post-view__details-value">{{ offer?.listing.bedroom_amount }} Bedrooms</div>
          </div>
          <div class="post-view__details-row">
            <div class="post-view__details-caption">Bathrooms</div>
            <div class="post-view__details-value">
              {{ offer?.listing.bathroom_amount }} Bathrooms
            </div>
          </div>
          <div class="post-view__details-row">
            <div class="post-view__details-caption">LOT Size</div>
            <div class="post-view__details-value">{{ offer?.listing.lot_size }} SQFT</div>
          </div>
          <!-- <div class="post-view__details-row">
          <div class="post-view__details-caption">Year Built</div>
          <div class="post-view__details-value">TODO</div>
        </div> -->
        </div>
      </div>
    </div>
  </section>
</main>
