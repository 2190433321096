<ng-container *ngIf="viewSubscription | async as view" [ngSwitch]="view">
  <ng-container *ngSwitchCase="'noFooter'" class="auth-footer"></ng-container>
  <div *ngSwitchCase="'auth' || 'auth-black'" class="auth-footer">
    <section class="left-block-blank"></section>
    <section class="auth-container">
      <footer class="footer">
        <nav class="footer-menu">
          <ul class="footer-menu__list ul-reset">
            <li class="footer-menu__item">
              <a routerLink="terms-of-service" class="footer-menu__link">TERMS OF SERVICE</a>
            </li>
            <li class="footer-menu__item">
              <a routerLink="/privacy-policy" class="footer-menu__link">PRIVACY POLICY</a>
            </li>
            <li class="footer-menu__item">
              <a routerLink="/faq" class="footer-menu__link">FAQ</a>
            </li>
          </ul>
        </nav>
        <ul class="socials ul-reset">
          <li>
            <a class="socials__shareicon fb" href="#" target="_blank" aria-label="Facebook"></a>
          </li>
          <li>
            <a
              class="socials__shareicon instagram"
              href="#"
              target="_blank"
              aria-label="Instagram"
            ></a>
          </li>
        </ul>
      </footer>
    </section>
  </div>
  <div
    *ngSwitchDefault
    class="footer-container"
  >
    <footer class="footer">
      <nav class="footer-menu">
        <ul class="footer-menu__list ul-reset">
          <li class="footer-menu__item">
            <a routerLink="terms-of-service" class="footer-menu__link">TERMS OF SERVICE</a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="/privacy-policy" class="footer-menu__link">PRIVACY POLICY</a>
          </li>
          <li class="footer-menu__item">
            <a routerLink="/faq" class="footer-menu__link">FAQ</a>
          </li>
        </ul>
      </nav>
      <ul class="socials ul-reset">
        <li>
          <a class="socials__shareicon fb" href="#" target="_blank" aria-label="Facebook"></a>
        </li>
        <li>
          <a
            class="socials__shareicon instagram"
            href="#"
            target="_blank"
            aria-label="Instagram"
          ></a>
        </li>
      </ul>
    </footer>
  </div>
</ng-container>
