/* eslint-disable no-param-reassign */
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { OfferService } from './offer.service';
import {
  DealStatusEnum,
  ListingInfo,
  OfferInterface,
  OfferStatusEnum,
  UnreadMessageInterface,
} from '../listings/listing.interface';
import { UserService } from '../user/user.service';
import { PubnubService } from './pubnub/pubnub.service';
import { HeaderFooterStateService } from '../common/services/header-footer-state.service';
import { GeneralDialogComponent } from '../common/dialogs/general-dialog/general-dialog.component';

@Component({
  selector: 'my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.scss'],
})
export class MyOffersComponent implements OnDestroy {
  openOffers: OfferInterface[];

  closedOffers: OfferInterface[];

  offers: OfferInterface[];

  userId: number;

  private refresh$ = new BehaviorSubject(true);

  private destroy$ = new Subject<void>();

  constructor(
    private offersService: OfferService,
    private userService: UserService,
    private pubnubService: PubnubService,
    private headerFooterStateService: HeaderFooterStateService,
    private dialog: MatDialog,
  ) {
    this.userId = this.userService.getUser().id;
    this.refresh$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          return this.offersService.getMyOffers();
        }),
      )
      .subscribe((res) => {
        res.map((offer) => {
          offer.hasUnreadMessage = false;
          return offer;
        });
        this.updateOffers(res);
        this.pubnubService.emitNewMessages();
      });
    this.pubnubService
      .getNewMessages$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages) => this.updateUnreadStatus(messages));
    this.pubnubService
      .getUpdateOffer$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refresh$.next(true));
    if (this.userService.isVerified()) {
      this.headerFooterStateService.setView('main');
    }
  }

  checkFirstAcceptedOffer(): void {
    const modalState = this.userService.getModalState();
    if (
      !modalState.firstAcceptedOfferBuyer &&
      this.offers.filter(
        (offer) =>
          offer.status === 'deal_closed' &&
          offer.listing.status === 'open' &&
          offer.listing.seller.id !== this.userId,
      ).length >= 1
    ) {
      modalState.firstAcceptedOfferBuyer = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Congratulations',
          text: `Your offer has been accepted. Please coordinate with the lister 
to get an assignment agreement in place and work with the lister to open and close escrow. 


Lastly, we highly recommend asking the lister for the Purchase and Sale Agreement to verify what you are buying.`,
          buttonText: 'Thank you',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  updateOffers(offers: OfferInterface[]): void {
    this.offers = offers;
    this.openOffers = offers.filter((item) => {
      return (
        item.status !== OfferStatusEnum.deal_rejected &&
        item.listing.status !== DealStatusEnum.closed &&
        item.listing.seller.id !== this.userId
      );
    });
    this.closedOffers = offers.filter((item) => {
      return (
        item.listing.status === DealStatusEnum.closed &&
        item.status !== OfferStatusEnum.deal_rejected &&
        item.listing.seller.id !== this.userId
      );
    });
    this.checkFirstAcceptedOffer();
  }

  updateUnreadStatus(unreadMessage: UnreadMessageInterface): void {
    Object.keys(unreadMessage).forEach((key) => {
      this.offers = this.offers?.map((offer) => {
        if (offer.id.toString() === key) {
          offer.hasUnreadMessage = unreadMessage[key].hasNewMessage || false;
        }
        return offer;
      });
    });
    if (this.openOffers) {
      this.openOffers = this.openOffers.sort((a, b) => {
        return +b.hasUnreadMessage - +a.hasUnreadMessage;
      });
    }
  }

  trackByFn(index: number, item: OfferInterface): number {
    return item.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getSource(listing: ListingInfo): string {
    return listing.photo.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }
}
