export const DealsCarouselData: DealsCarouselInterface[] = [
  {
    header: 'Post Your Listing',
    text: 'If you’re a seller, you can post your listing for potential buyers to see.',
    image: '/images/slider-1.png',
    card: {
      price: {
        value: '$500,000.00',
        type: 'AVR',
      },
      descriptionTypes: ['3 BED', '3 BATH', '1,425 SQFT'],
      address: 'Orange County, CL',
    },
  },
  {
    header: 'Get Bids on Listings',
    text: "You'll automatically be notified when you receive a bid on one of your listings.",
    image: '/images/slider-2.png',
  },
  {
    header: 'Accepting a bid',
    text:
      "Once you're happy with the offer, simply press accept bid and start the process of moving in into escrow.",
    image: '/images/slider-3.png',
  },
];

export interface DealsCarouselInterface {
  header: string;
  text: string;
  image: string;
  card?: {
    price: {
      value: string;
      type: string;
    };
    descriptionTypes: string[];
    address: string;
  };
}
