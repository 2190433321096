<div>
  <div class="image-wrapper">
    <div class="close-icon-container">
      <img src="../../../../images/icons/Close.png" (click)="closeDialog()" class="close-icon" />
    </div>
    <div class="crop-image-header">Crop Your Image</div>
    <image-cropper
      [imageFile]="initialImage"
      [maintainAspectRatio]="true"
      [aspectRatio]="1"
      [resizeToWidth]="800"
      [resizeToHeight]="800"
      format="png"
      (imageCropped)="imageCropped($event)"
    ></image-cropper>
    <img *ngIf="croppedImage" class="cropped-image-example" [src]="croppedImage" />
  </div>
  <button type="submit" class="submit btn" [mat-dialog-close]="croppedImage">Finish</button>
</div>
