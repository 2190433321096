import { AfterViewInit, ElementRef, Directive, Renderer2 } from '@angular/core';

@Directive({
  selector: '[videoToThumbnail]',
})
export class VideoToThumbnailDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.getThumbnailForVideo();
  }

  getThumbnailForVideo(): void {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');
    const image = new Image();
    video.src = this.el.nativeElement.style.backgroundImage.replace('url("', '').replace('")', '');
    video.currentTime = 1;
    video.oncanplaythrough = () => {
      canvas.width = video.videoWidth * 1;
      canvas.height = video.videoHeight * 1;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      image.src = canvas.toDataURL();
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url("${image.src}")`);
    };
  }
}
