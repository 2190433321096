import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_BASE_HREF, DatePipe, DecimalPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { AgmCoreModule } from '@agm/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CropImageComponent } from './common/dialogs/crop-image/crop-image.component';
import { UserModule } from './user/user.module';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { PhotoFileValidator } from './common/directives/photo-file-validator/photo-file-validator.directive';
import { DragAndDropDirective } from './common/directives/drag-and-drop/drag-and-drop.directive';
import { HeaderFooterStateService } from './common/services/header-footer-state.service';
import { DealsContainerComponent } from './deals/deals-container/deals-container.component';
import { AuthGuard } from './common/guards/auth-guard.service';
import { FaqComponent } from './documents/faq/faq.component';
import { TermsOfServiceComponent } from './documents/terms-of-service/terms-of-service.component';
import { PrivacePolicyComponent } from './documents/privace-policy/privace-policy.component';
import { HandleErrorService } from './common/interceptor/handle-error.service';
import { ServerErrorsInterceptor } from './common/interceptor/server-errors.interceptor';
import { HttpLoaderInterceptor } from './common/interceptor/http-loader.interceptor';
import { AddEditListingComponent } from './listings/add-edit-listing/add-edit-listing.component';
import { GalleryContainerComponent } from './common/gallery-container/gallery-container.component';
import { ConfirmationDialogComponent } from './common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddEditComparisonComponent } from './listings/add-edit-comparison/add-edit-comparison.component';
import { MyListingsComponent } from './listings/my-listings/my-listings.component';
import { AddEditListingContainerComponent } from './listings/add-edit-listing-container/add-edit-listing-container.component';
import { ListingService } from './listings/listing.service';
import { ListingPostComponent } from './listings/listing-post/listing-post.component';
import { PostedDialogComponent } from './common/dialogs/posted-dialog/posted-dialog.component';
import { DateSuffixPipe } from './common/pipes/date-suffix.pipe';
import { DateAgoPipe } from './common/pipes/date-ago.pipe';
import { PlaceOfferDialogComponent } from './common/dialogs/place-offer-dialog/place-offer-dialog.component';
import { PlaceMessageDialogComponent } from './common/dialogs/place-message-dialog/place-message-dialog.component';
import { AutosizeDirective } from './common/directives/autosize/autosize.directive';
import { LoaderComponent } from './common/loader/loader.component';
import { SentryErrorHandler } from './utils/sentry-error-handler';
import { MyOffersComponent } from './my-offers/my-offers.component';
import { PubnubComponent } from './my-offers/pubnub/pubnub.component';
import { OfferService } from './my-offers/offer.service';
import { OfferComponent } from './my-offers/offer/offer.component';
import { VideoToThumbnailDirective } from './common/directives/video-to-thumbnail/video-to-thumbnail.directive';
import { OfferInfoComponent } from './my-offers/offer-info/offer-info.component';
import { NdaConfirmationDialogComponent } from './common/dialogs/nda-confirmation-dialog/nda-confirmation-dialog.component';
import { PubnubDatePipe } from './common/pipes/pubnub-date.pipe';
import { OffersOrderPipe } from './common/pipes/offers-order.pipe';
import { environment } from '../environments/environment';
import { RatePersonDialogComponent } from './common/dialogs/rate-person-dialog/rate-person-dialog.component';
import { GeneralDialogComponent } from './common/dialogs/general-dialog/general-dialog.component';
import { MediaContainerComponent } from './common/media-container/media-container.component';
import { DropdownDirective } from './common/directives/dropdown/dropdown.directive';
import { PhoneNumberDialogComponent } from './common/dialogs/phone-number-dialog/phone-number-dialog.component';
import { SharedDependenciesModule } from './common/shared-dependencies/shared-dependencies.module';
import { MediaFullscreenViewComponent } from './common/media-fullscreen-view/media-fullscreen-view.component';
import { VideoDataTypeConverterPipe } from './common/pipes/video-data-type-converter.pipe';
import { ShareComponent } from './common/share/share.component';
import { SharedListingsComponent } from './listings/shared-listings/shared-listings.component';
import { ImageLoaderDirective } from './common/directives/image-loader/image-loader.directive';
import { SideBarComponent } from './common/side-bar/side-bar.component';
import { NavService } from './common/services/nav.service';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ImageCropperModule,
    UserModule,
    HttpClientModule,
    GooglePlaceModule,
    ReactiveFormsModule,
    MatIconModule,
    SharedDependenciesModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
    }),
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
    }),
    ClipboardModule,
    Ng2TelInputModule,
  ],
  declarations: [
    AppComponent,
    CropImageComponent,
    FooterComponent,
    HeaderComponent,
    MyAccountComponent,
    PhotoFileValidator,
    DragAndDropDirective,
    DealsContainerComponent,
    FaqComponent,
    TermsOfServiceComponent,
    PrivacePolicyComponent,
    AddEditListingComponent,
    GalleryContainerComponent,
    ConfirmationDialogComponent,
    AddEditComparisonComponent,
    MyListingsComponent,
    AddEditListingContainerComponent,
    ListingPostComponent,
    PostedDialogComponent,
    DateSuffixPipe,
    DateAgoPipe,
    PlaceOfferDialogComponent,
    PlaceMessageDialogComponent,
    AutosizeDirective,
    LoaderComponent,
    MyOffersComponent,
    PubnubComponent,
    OfferComponent,
    VideoToThumbnailDirective,
    OfferInfoComponent,
    NdaConfirmationDialogComponent,
    PubnubDatePipe,
    OffersOrderPipe,
    RatePersonDialogComponent,
    GeneralDialogComponent,
    MediaContainerComponent,
    DropdownDirective,
    PhoneNumberDialogComponent,
    ShareComponent,
    MediaFullscreenViewComponent,
    VideoDataTypeConverterPipe,
    SharedListingsComponent,
    ImageLoaderDirective,
    SideBarComponent,
  ],
  bootstrap: [AppComponent],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoaderInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    HeaderFooterStateService,
    HandleErrorService,
    AuthGuard,
    ListingService,
    OfferService,
    MatDatepickerModule,
    DatePipe,
    DateSuffixPipe,
    DateAgoPipe,
    DecimalPipe,
    DeviceDetectorService,
    NavService,
  ],
})
export class AppModule {}
