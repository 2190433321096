<main class="login" [ngSwitch]="view">
  <ng-container *ngSwitchCase="'emailPaste'">
    <section class="left-block">
      <h2 class="left-block__text">Let the <span class="blue">leads</span> find you.</h2>
      <div class="left-block__bg signup-2"></div>
    </section>
    <section class="login-container">
      <div class="login-wrapper">
        <h1 class="login-title">Forgot Password</h1>
        <p class="login-subtitle">
          Enter your email address and we’ll send you a password reset link.
        </p>
        <form
          id="phone-verification"
          class="sign-form"
          #emailForm="ngForm"
          (ngSubmit)="emailForm.valid && emailRequest(email.value)"
        >
          <div class="sign-field">
            <input
              type="email"
              class="sign-input input"
              placeholder="Enter Your Email Address"
              id="email"
              name="email"
              #email="ngModel"
              ngModel
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
            />
            <div
              class="error"
              *ngIf="
                (email.invalid && (email.dirty || email.touched)) ||
                (email.invalid && emailForm.submitted)
              "
            >
              Please enter a valid email address
            </div>
            <div class="error" *ngIf="emailForm.control.errors">User not found</div>
          </div>
          <button type="submit" class="submit btn" [class.submit-disabled]="email.invalid">
            Confirm
          </button>
        </form>
        <div class="w-100 text-center">
          <a class="cancel-reset" routerLink="/login">Cancel Password Reset</a>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'emailSent'">
    <section class="left-block">
      <h2 class="left-block__text">Wholesaling done <span class="blue">right.</span></h2>
      <div class="left-block__bg signup-2"></div>
    </section>
    <section class="login-container">
      <div class="login-wrapper">
        <h1 class="login-title">Email Sent</h1>
        <p class="login-subtitle">
          Email resent link has been sent, be sure to check your spam folder if it doesn’t arrive
          shortly.
        </p>
        <button class="submit btn" routerLink="/login">Back to login</button>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'newPassword'">
    <section class="left-block">
      <h2 class="left-block__text">
        Simply buy and sell your real estate <span class="blue">deals.</span>
      </h2>
      <div class="left-block__bg signup-1"></div>
    </section>
    <section class="login-container">
      <div class="login-wrapper">
        <h1 class="login-title">Enter New Password</h1>
        <form
          id="signup"
          action=""
          class="sign-form"
          #passwordForm="ngForm"
          (ngSubmit)="passwordForm.valid && changePassword(passwordForm.form)"
        >
          <div class="sign-field">
            <input
              type="password"
              class="sign-input input"
              placeholder="Enter Your New Password"
              name="newPassword"
              ngModel
              #newPassword="ngModel"
              required
              minlength="8"
              validateEqual="newPassword2"
              reverse="true"
            />
            <div
              *ngIf="
                (newPassword.invalid && (newPassword.dirty || newPassword.touched)) ||
                (newPassword.invalid && passwordForm.submitted)
              "
            >
              <div class="error" *ngIf="newPassword.errors.minLength">
                The password must contain at least 8 characters
              </div>
              <div class="error" *ngIf="newPassword.errors.required">
                Please enter your password
              </div>
            </div>
          </div>
          <div class="sign-field">
            <input
              type="password"
              class="sign-input input"
              placeholder="Re-Enter New Password"
              ngModel
              name="newPassword2"
              #newPassword2="ngModel"
              required
              minlength="8"
              validateEqual="newPassword"
            />
            <div
              *ngIf="
                (newPassword2.invalid && (newPassword2.dirty || newPassword2.touched)) ||
                (newPassword2.invalid && passwordForm.submitted)
              "
            >
              <div class="error" *ngIf="newPassword2.errors.required">
                Please enter your password
              </div>
              <div class="error" *ngIf="newPassword2.errors.compare">
                Your passwords needs to be identical
              </div>
              <div class="error" *ngIf="newPassword2.errors.minLength">
                The password must contain at least 8 characters
              </div>
            </div>
          </div>
          <button type="submit" class="submit btn" [class.submit-disabled]="passwordForm.invalid">
            Confirm
          </button>
        </form>
      </div>
    </section>
  </ng-container>

  <ng-container *ngSwitchCase="'passwordChanged'">
    <section class="left-block">
      <h2 class="left-block__text">Wholesaling done <span class="blue">right.</span></h2>
      <div class="left-block__bg signup-2"></div>
    </section>
    <section class="login-container">
      <div class="login-wrapper">
        <h1 class="login-title">Password Changed</h1>
        <p class="login-subtitle">
          You’ve successfully changed your password and can now login using it.
        </p>
        <button class="submit btn" routerLink="/login">Back to login</button>
      </div>
    </section>
  </ng-container>
</main>
