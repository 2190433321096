import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { OfferInterface } from 'src/app/listings/listing.interface';
import { OffersOrder } from 'src/app/my-offers/offer/offer.component';

@Pipe({
  name: 'offersOrderPipe',
})
export class OffersOrderPipe implements PipeTransform {
  transform(offers: OfferInterface[], order: OffersOrder): OfferInterface[] {
    if (offers) {
      switch (order) {
        case 'newest':
          return offers.sort(
            (a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf(),
          );
        case 'oldest':
          return offers.sort(
            (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
          );
        case 'highest-offer':
          return offers.sort((a, b) => parseInt(b.price, 10) - parseInt(a.price, 10));
        default:
          return offers;
      }
    }
    return [];
  }
}
