<section class="account-edit__container">
  <h3 class="account-edit__title">Your Personal Information</h3>
  <p class="account-edit__subtitle">
    You may change your contact details here, it is important that they are accurate.
  </p>
  <form class="account-edit__form" #form="ngForm" (ngSubmit)="form.valid && submit(form)">
    <div
      class="photo-box drag-n-drop"
      dragAndDrop
      (fileDropped)="photoUpload($event)"
      [ngClass]="{ loadedImage: user.photo }"
    >
      <input
        class="input-file-upload"
        data-url=""
        id="image_upload"
        name="image_upload"
        type="file"
        (change)="photoUpload($event.target.files[0])"
      />
      <ng-container *ngIf="!user.photo">
        <h4 class="photo-box__caption">Drag and Drop Image</h4>
        <span class="photo-box__caption2">
          or click
          <label for="image_upload" class="link">here</label>
          to upload
        </span>
        <div class="error photo-box__error" *ngIf="imageValidationError">
          Supported formats for avatar are .jpg, .jpeg and .png only.
        </div>
      </ng-container>
      <ng-container *ngIf="user.photo">
        <img [src]="user.photo" class="photo-result" />
        <label for="image_upload" class="link">
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="photo-change-icon"
          >
            <g filter="url(#filter0_b)">
              <circle cx="32" cy="32" r="32" fill="white" />
            </g>
            <path
              d="M46.6654 41.3333C46.6654 42.0406 46.3844 42.7189 45.8843 43.219C45.3842 43.719 44.7059 44 43.9987 44H19.9987C19.2915 44 18.6132 43.719 18.1131 43.219C17.613 42.7189 17.332 42.0406 17.332 41.3333V26.6667C17.332 25.9594 17.613 25.2811 18.1131 24.781C18.6132 24.281 19.2915 24 19.9987 24H25.332L27.9987 20H35.9987L38.6654 24H43.9987C44.7059 24 45.3842 24.281 45.8843 24.781C46.3844 25.2811 46.6654 25.9594 46.6654 26.6667V41.3333Z"
              stroke="#93A5B4"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M32.0013 38.6667C34.9468 38.6667 37.3346 36.2789 37.3346 33.3333C37.3346 30.3878 34.9468 28 32.0013 28C29.0558 28 26.668 30.3878 26.668 33.3333C26.668 36.2789 29.0558 38.6667 32.0013 38.6667Z"
              stroke="#93A5B4"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <filter
                id="filter0_b"
                x="-39"
                y="-39"
                width="142"
                height="142"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="19.5" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </label>
      </ng-container>
    </div>
    <div class="account-edit__fields">
      <div class="account-field">
        <label class="label" for="firstName">First Name</label>
        <input
          type="text"
          name="firstName"
          class="account-input input"
          placeholder="First Name"
          [(ngModel)]="user.first_name"
          #firstName="ngModel"
          required
        />
        <div
          class="error"
          *ngIf="
            (firstName.invalid &&
              (firstName.dirty || firstName.touched) &&
              firstName.errors.required) ||
            (firstName.invalid && form.submitted)
          "
        >
          Please enter your first name
        </div>
      </div>
      <div class="account-field">
        <label class="label" for="lastName">Last Name</label>
        <input
          type="text"
          name="lastName"
          class="account-input input"
          placeholder="Last Name"
          [(ngModel)]="user.last_name"
          #lastName="ngModel"
          required
        />
        <div
          class="error"
          *ngIf="
            (lastName.invalid &&
              (lastName.dirty || lastName.touched) &&
              lastName.errors.required) ||
            (lastName.invalid && form.submitted)
          "
        >
          Please enter your second name
        </div>
      </div>
      <div class="account-field">
        <label class="label" for="lastName">Email</label>
        <input
          type="email"
          name="email"
          class="account-input input"
          placeholder="Email Address"
          [(ngModel)]="user.email"
          #email="ngModel"
          required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        />
        <div
          *ngIf="
            (email.invalid && (email.dirty || email.touched)) || (email.invalid && form.submitted)
          "
        >
          <div class="error" *ngIf="email.errors.required">Please enter your email address</div>
          <div class="error" *ngIf="email.errors.pattern">Please enter a valid email address</div>
        </div>
      </div>
      <div class="account-field">
        <label class="label" for="phone">Phone Number</label>
        <input
          type="tel"
          name="phone"
          class="account-input input"
          placeholder="(XXX) XXX-XXXX"
          [(ngModel)]="user.phone_number"
          #phoneNumber="ngModel"
          phoneMask
          required
        />
        <div
          class="error"
          *ngIf="
            (phoneNumber.invalid &&
              (phoneNumber.dirty || phoneNumber.touched) &&
              phoneNumber.errors.required) ||
            (phoneNumber.invalid && form.submitted)
          "
        >
          Please enter a valid phone number
        </div>
      </div>
      <div class="account-field">
        <label class="label" for="street">Address</label>
        <input
          type="text"
          name="street"
          class="account-input input"
          placeholder="Enter Your Address"
          [(ngModel)]="user.address"
          #address="ngModel"
        />
      </div>
      <div class="account-field">
        <label class="label" for="price-range">Price Range</label>
        <div class="select input">
          <select
            name="price-range"
            class="account-input input"
            [(ngModel)]="selectedItem"
            #priceRange="ngModel"
          >
            <option value="" disabled selected style="display: none; color: #93a5b4">
              Select Preferred Price Range
            </option>
            <option>$0 - $50,000</option>
            <option>$50,000 - $100,000</option>
            <option>$100,000 - $150,000</option>
            <option>$150,000 - 200,000</option>
            <option>$200,000 - 250,000</option>
            <option>$250,000 - $300,000</option>
            <option>$300,000+</option>
          </select>
        </div>
      </div>
      <div class="account-field">
        <label class="label">Preferred cities</label>
        <cities-input
          [placeholder]="'Enter the cities you wish the see deals in.'"
          [cities]="user.cities"
        ></cities-input>
      </div>
      <ng-container *ngIf="!user.is_social">
        <div class="account-field">
          <div
            class="change-password-label"
            (click)="toggleWantToChangePassword()"
            [ngClass]="{ 'blue-color': !wantToChangePassword }"
          >
            Want to change your password?
          </div>
        </div>
        <div class="account-field" *ngIf="wantToChangePassword">
          <label class="label" for="password">Current Password</label>
          <input
            type="password"
            name="password"
            class="account-input input"
            placeholder="Current Password"
            ngModel
            #password="ngModel"
            [required]="wantToChangePassword"
          />
          <div
            class="error"
            *ngIf="
              (password.invalid &&
                (password.dirty || password.touched) &&
                password.errors.required) ||
              (password.invalid && form.submitted)
            "
          >
            Please enter your password
          </div>
          <div class="error" *ngIf="form.control.errors">Wrong password</div>
        </div>
        <div class="account-field" *ngIf="wantToChangePassword">
          <label class="label" for="newPassword">New Password</label>
          <input
            type="password"
            name="newPassword"
            class="account-input input"
            placeholder="New Password"
            minlength="8"
            ngModel
            #newPassword="ngModel"
            [required]="wantToChangePassword"
            validateEqual="newPassword2"
            reverse="true"
          />
          <div
            *ngIf="
              (newPassword.invalid && (newPassword.dirty || newPassword.touched)) ||
              (newPassword.invalid && form.submitted)
            "
          >
            <div class="error" *ngIf="newPassword.errors.minlength">
              The password must contain at least 8 characters
            </div>
            <div class="error" *ngIf="newPassword.errors.required">Please enter your password</div>
          </div>
        </div>
        <div class="account-field" *ngIf="wantToChangePassword">
          <label class="label" for="newPassword2">Confirm Password</label>
          <input
            type="password"
            name="newPassword2"
            class="account-input input"
            minlength="8"
            placeholder="Confirm New Password"
            ngModel
            #newPassword2="ngModel"
            [required]="wantToChangePassword"
            validateEqual="newPassword"
          />
          <div
            *ngIf="
              (newPassword2.invalid && (newPassword2.dirty || newPassword2.touched)) ||
              (newPassword2.invalid && form.submitted)
            "
          >
            <div class="error" *ngIf="newPassword2.errors.required">Please enter your password</div>
            <div class="error" *ngIf="newPassword2.errors.compare">
              Your passwords needs to be identical
            </div>
            <div class="error" *ngIf="newPassword2.errors.minlength">
              The password must contain at least 8 characters
            </div>
          </div>
        </div>
      </ng-container>
      <button type="submit" class="submit btn" [class.submit-disabled]="form.invalid">
        Save Changes
      </button>
      <div class="logout-button-container" (click)="logout()">
        <div class="logout-button">Log Out</div>
      </div>
    </div>
  </form>
</section>
