/* eslint-disable no-param-reassign */
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/common/dialogs/general-dialog/general-dialog.component';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ListingService } from '../listing.service';
import {
  ListingInterface,
  DealStatusEnum,
  UnreadMessageInterface,
  ListingAndOffers,
} from '../listing.interface';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { UserService } from '../../user/user.service';
import { PubnubService } from '../../my-offers/pubnub/pubnub.service';
import { UserInterface } from '../../user/user.interface';

@Component({
  selector: 'my-listings',
  templateUrl: './my-listings.component.html',
  styleUrls: ['./my-listings.component.scss'],
})
export class MyListingsComponent implements OnDestroy {
  listings: ListingInterface[] = [];

  user: UserInterface;

  openListings: ListingInterface[] = [];

  closedListings: ListingInterface[] = [];

  listingsAndOffers: ListingAndOffers[] = [];

  shareLink: string;

  private destroy$ = new Subject<void>();

  private refresh$ = new BehaviorSubject(true);

  constructor(
    private listingService: ListingService,
    private headerFooterStateService: HeaderFooterStateService,
    private userService: UserService,
    private pubnubService: PubnubService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.headerFooterStateService.setView('additional-footer');
    this.user = this.userService.getUser();
    this.shareLink = `${window.location.origin}/listings/${this.user.id}`;
    this.refresh$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() =>
          this.listingService.getMyListings().pipe(
            takeUntil(this.destroy$),
            tap((res) => this.updateListings(res)),
            switchMap((listings) => {
              const obs$ = listings.map((listing) =>
                this.listingService.getOffersForListening(listing.id),
              );
              return forkJoin(obs$);
            }),
          ),
        ),
      )
      .subscribe((listingsAndOffers) => {
        this.listingsAndOffers = listingsAndOffers;
        this.checkUserHaveFirstOffer();
        this.pubnubService.emitNewMessages();
      });
    this.pubnubService
      .getUpdateOffer$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refresh$.next(true));
    this.pubnubService
      .getNewMessages$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages) => this.updateUnreadStatus(messages));
  }

  checkUserHaveFirstOffer(): void {
    const modalState = this.userService.getModalState();
    if (
      !modalState.firstSellerOffer &&
      this.listingsAndOffers.some((el) => el.offers?.length >= 1)
    ) {
      modalState.firstSellerOffer = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Congratulations',
          text:
            'You have received your first offer. Please take the time to review your offer and do your due diligence on the buyer.',
          subText: 'This includes: proof of funds, closed properties, etc.',
          buttonText: 'Thanks! Let’s get started',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  updateListings(listings: ListingInterface[]): void {
    this.listings = listings.map((listing) => {
      listing.hasUnreadMessage = false;
      return listing;
    });
    this.openListings = listings.filter((listing) => listing.status !== DealStatusEnum.closed);
    this.closedListings = listings.filter((listing) => listing.status === DealStatusEnum.closed);
    if (listings.length) localStorage.setItem('isFirstListing', JSON.stringify(true));
  }

  getSource(listing: ListingInterface): string {
    return listing.photo?.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }

  updateUnreadStatus(unreadMessage: UnreadMessageInterface): void {
    Object.keys(unreadMessage)
      .filter((item) => unreadMessage[item].hasNewMessage === true)
      .forEach((key) => {
        // TODO The listing might be deleted so the following check should skip it
        const index = this.listings?.findIndex(
          (listing) => listing.id === this.pubnubService.offerToListing.get(+key),
        );
        if (index >= 0) {
          this.listings[index].hasUnreadMessage = true;
        }
      });
    if (this.openListings && Object.keys(unreadMessage).length > 0) {
      this.openListings = this.openListings.sort((a, b) => {
        return +b.hasUnreadMessage - +a.hasUnreadMessage;
      });
    }
  }

  openOffers(id: number): void {
    const listingAndOffers = this.listingsAndOffers.find((el) => el.listing?.id === id);
    if (listingAndOffers && listingAndOffers.offers?.length) {
      this.router.navigate([`/offer/${listingAndOffers.offers[0].id}`]);
    } else {
      this.router.navigate([`/edit-listing/${id}`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
