import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'phone-number-dialog',
  templateUrl: './phone-number-dialog.component.html',
  styleUrls: ['./phone-number-dialog.component.scss'],
})
export class PhoneNumberDialogComponent {
  constructor(public dialogRef: MatDialogRef<PhoneNumberDialogComponent>) {}

  public phone = '';

  public countryCode = '';

  countryCodeChange(event): void {
    this.countryCode = `+${event.dialCode}`;
  }

  close(): void {
    this.dialogRef.close(this.countryCode + this.phone.replace(' ', '').replace('-', ''));
  }
}
