import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'place-offer-dialog',
  templateUrl: './place-offer-dialog.component.html',
  styleUrls: ['./place-offer-dialog.component.scss'],
})
export class PlaceOfferDialogComponent {
  offer_price: number;

  error = false;

  constructor(
    public dialogRef: MatDialogRef<PlaceOfferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public listing_price: string,
  ) {}

  submit(): void {
    if (this.offer_price) {
      this.error = false;
      this.dialogRef.close(this.offer_price);
    } else {
      this.error = true;
    }
  }
}
