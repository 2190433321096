<div class="modal" id="popup">
  <div id="welcome-to-dealup" class="modal__content">
    <h3 class="modal__title">Enter phone number</h3>
    <form #form="ngForm" class="phone-form">
      <div class="account-field">
        <input
          type="tel"
          name="phone"
          id="phone"
          ng2TelInput
          class="account-input input"
          placeholder="(XXX) XXX-XXXX"
          [(ngModel)]="phone"
          #phoneNumber="ngModel"
          phoneMask
          required
          minlength="14"
          (countryChange)="countryCodeChange($event)"
        />
        <div
          class="error"
          *ngIf="
            (phoneNumber.invalid &&
              (phoneNumber.dirty || phoneNumber.touched) &&
              phoneNumber.errors.required) ||
            (phoneNumber.invalid && form.submitted)
          "
        >
          Please enter a valid phone number
        </div>
      </div>
      <button
        [class.submit-disabled]="form.invalid"
        type="submit"
        class="submit btn"
        (click)="close()"
      >
        Confirm
      </button>
    </form>
    <span class="close close-popup" (click)="dialogRef.close()"></span>
  </div>
</div>
