<main class="create-listing">
  <div class="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <ul class="breadcrumbs__list ul-reset">
      <li
        class="breadcrumbs__item"
        itemscope=""
        property="itemListElement"
        itemtype="http://schema.org/ListItem"
      >
        <a itemprop="item" class="breadcrumbs__link" routerLink="/my-listings">
          <span itemprop="name">BACK TO MY LISTINGS</span>
        </a>
        <meta itemprop="position" content="1" />
      </li>
    </ul>
  </div>
  <section class="create-listing__container">
    <div class="title-row">
      <h1 class="create-listing__title" *ngIf="isAddMode">Add Comparison</h1>
      <h1 class="create-listing__title" *ngIf="!isAddMode">Edit Comparison</h1>
      <p class="create-listing__subtext">
        Comparison sales greatly boost your chance of selling the property, we highly recommend
        adding adding one. You can skip this step.
      </p>
    </div>
    <form
      class="create-listing__form"
      #form="ngForm"
      (ngSubmit)="
        ((!isFormEmpty && form.valid && listing.comparison.photo) || isFormEmpty) && submit()
      "
      id="ngForm"
    >
      <div class="create-left-block">
        <div class="title-row">
          <span class="title-row__caption">IMAGES</span>
        </div>
        <div class="photo-box big drag-n-drop" dragAndDrop (fileDropped)="photoUpload($event)">
          <ng-container *ngIf="!listing.comparison.photo">
            <h4 class="photo-box__caption">Drag and Drop Your Image</h4>
            <span class="photo-box__caption2"
              >or click <span id="upNewImg" class="link">here</span> to upload</span
            >
            <input
              class="input-file-upload"
              data-url=""
              id="image_upload"
              multiple=""
              name="image_upload"
              type="file"
              (change)="photoUpload($event.target.files[0])"
            />
            <ng-container *ngIf="!isFormEmpty">
              <div class="error photo-error" *ngIf="imageValidationError">
                Supported formats for avatar are .jpg, .jpeg and .png only.
              </div>
              <div class="error photo-error" *ngIf="form.submitted && !listing.comparison.photo">
                Please upload an image
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="listing.comparison.photo">
            <div
              class="image image__big"
              [ngStyle]="{ 'background-image': 'url(' + listing.comparison.photo + ')' }"
            ></div>
            <div class="full-screen" (click)="showFullScreen()"></div>
            <media-fullscreen-view
              [media]="[fullScreenImage]"
              [mediaIndex]="0"
              [showPreview]="showFullScreenFlag"
              (removeMediaItem)="removeSelectedItem()"
              (closePreview)="closeFullScreen()"
            >  
            </media-fullscreen-view>
          </ng-container>
        </div>
      </div>
      <div class="create-right-block">
        <div class="title-row">
          <span class="title-row__caption">LISTING DETAILS</span>
        </div>
        <div class="create-listing__field">
          <label class="label" for="propertyPrice">Price</label>
          <input
            required
            [(ngModel)]="listing.comparison.price"
            name="propertyPrice"
            #propertyPrice="ngModel"
            type="number"
            class="create-listing__input input"
            placeholder="Property Price"
          />
          <div
            class="error"
            *ngIf="
              !isFormEmpty &&
              ((propertyPrice.invalid && propertyPrice.dirty && propertyPrice.errors.required) ||
                (propertyPrice.invalid && form.submitted))
            "
          >
            Please enter property price
          </div>
        </div>
        <div class="create-listing__field">
          <label class="label" for="propertyAddress">Address</label>
          <input
            ngx-google-places-autocomplete
            class="create-listing__input input"
            [options]="googlePlacesOptions"
            placeholder="Example: 7 1st Ave, San Diego, CA 92187, USA"
            name="propertyAddress"
            required
            #propertyAddress="ngModel"
            [(ngModel)]="listing.comparison.address.readable_address"
            (onAddressChange)="handleAddressChange($event)"
          />
          <div
            class="error"
            *ngIf="
              !isFormEmpty &&
              ((propertyAddress.invalid &&
                propertyAddress.dirty &&
                propertyAddress.errors.required) ||
                (propertyAddress.invalid && form.submitted))
            "
          >
            Please enter property address
          </div>
          <div class="error" *ngIf="!isFormEmpty && adressFieldValidator(propertyAddress.value)">
            Address format should be "1000 1st Ave, San Diego, CA 92187, USA"
          </div>
        </div>
        <div class="create-listing__field short">
          <label class="label" for="bedroomAmount">Bedroom Amount</label>
          <input
            required
            [(ngModel)]="listing.comparison.bedroom_amount"
            name="bedroomAmount"
            #bedroomAmount="ngModel"
            type="number"
            class="create-listing__input input"
            placeholder="Bedroom Amount"
          />
          <div
            class="error"
            *ngIf="
              !isFormEmpty &&
              ((bedroomAmount.invalid && bedroomAmount.dirty && bedroomAmount.errors.required) ||
                (bedroomAmount.invalid && form.submitted))
            "
          >
            Please enter bedroom amount
          </div>
        </div>
        <div class="create-listing__field short">
          <label class="label" for="bathroomAmount">Bathroom Amount</label>
          <input
            required
            [(ngModel)]="listing.comparison.bathroom_amount"
            name="bathroomAmount"
            #bathroomAmount="ngModel"
            type="number"
            class="create-listing__input input"
            placeholder="Bathroom Amount"
          />
          <div
            class="error"
            *ngIf="
              !isFormEmpty &&
              ((bathroomAmount.invalid && bathroomAmount.dirty && bathroomAmount.errors.required) ||
                (bathroomAmount.invalid && form.submitted))
            "
          >
            Please enter bathroom amount
          </div>
        </div>
        <div class="create-listing__field">
          <label class="label" for="dateSold">Date Sold</label>
          <input
            required
            [(ngModel)]="date"
            name="dateSold"
            #dateSold="ngModel"
            type="text"
            class="create-listing__input input"
            matInput
            (dateChange)="updateDate(date)"
            [matDatepicker]="picker"
            [max]="maxDate"
            placeholder="Date Sold MM/DD/YR"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
            class="datepicker-icon"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <div
            class="error"
            *ngIf="
              !isFormEmpty &&
              ((dateSold.invalid && dateSold.dirty && dateSold.errors.required) ||
                (dateSold.invalid && form.submitted))
            "
          >
            Please enter date sold
          </div>
        </div>
      </div>
    </form>
  </section>
</main>
<footer class="footer next-step">
  <div class="next-step__container">
    <div class="next-step__col">
      <div class="next-step__text">
        <div class="next-step__mini-text">EMAIL NOTIFICATION</div>
        <div class="next-step__info">Notify me when someone offers</div>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="listing.bids_notifications" />
        <span class="switch__slider round"></span>
      </label>
    </div>
    <div class="next-step__col">
      <a class="next-step__back" (click)="backToEditListing()">Back</a>
      <button
        class="btn submit"
        form="ngForm"
        [ngClass]="{
          'submit-disabled': !isFormEmpty && (form.invalid || !listing.comparison.photo)
        }"
        type="submit"
      >
        Finish
      </button>
    </div>
  </div>
</footer>
