<main class="login">
  <section class="left-block">
    <h2 class="left-block__text">
      Simply buy and sell your real estate <span class="blue">deals.</span>
    </h2>
    <div class="left-block__bg signup-1"></div>
  </section>
  <section class="login-container">
    <div class="login-wrapper">
      <h1 class="login-title">Login</h1>
      <button class="fb-signup btn" (click)="signInWithFB()">Continue with Facebook</button>
      <button class="google-signup btn" (click)="signInWithGoogle()">Continue with Google</button>
      <div class="hr-line-text">or</div>
      <form
        id="signup"
        action=""
        class="sign-form"
        #form="ngForm"
        (ngSubmit)="form.valid && login(form.form.value)"
      >
        <div class="sign-field">
          <input
            type="email"
            class="sign-input input"
            placeholder="Email Address"
            name="email"
            ngModel
            #email="ngModel"
            required
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          />
          <div
            class="error"
            *ngIf="
              (email.invalid && (email.dirty || email.touched)) || (email.invalid && form.submitted)
            "
          >
            Please enter a valid email address
          </div>
        </div>
        <div class="sign-field">
          <input
            type="password"
            class="sign-input input"
            placeholder="Password"
            required
            ngModel
            #password="ngModel"
            name="password"
          />
          <div
            class="error"
            *ngIf="
              ((password.dirty || password.touched) && password.invalid) ||
              (password.invalid && form.submitted)
            "
          >
            Please enter your password
          </div>
          <div class="error" *ngIf="form.control.errors">
            You entered incorrect email or password
          </div>
        </div>
        <a routerLink="/password-recovery" class="forgot">Forgot your password?</a>
        <button type="submit" class="submit btn" [class.submit-disabled]="form.invalid">
          Confirm
        </button>
        <p class="sign-help">Don’t have an account? <a routerLink="/signup">Sign up</a></p>
      </form>
      <p class="privacy">
        By signing up you agree to <a href="/privacy-policy">Privacy Policy</a> and
        <a href="/terms-of-service">Terms of Service.</a>
      </p>
    </div>
  </section>
</main>
