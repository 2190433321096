import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  private showNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getShowNav(): BehaviorSubject<boolean> {
    return this.showNav$;
  }

  toggleShow(): void {
    this.showNav$.next(!this.showNav$.value);
  }

  setShowNav(value: boolean): void {
    this.showNav$.next(value);
  }
}
