import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpLoaderService {
  activeRequests$: BehaviorSubject<number>;

  isLoading$: Observable<boolean>;

  constructor() {
    this.activeRequests$ = new BehaviorSubject(0);
    this.isLoading$ = this.activeRequests$.pipe(map((requests) => requests > 0));
  }

  public onRequestStart(): void {
    this.activeRequests$.next(this.activeRequests$.value + 1);
  }

  public onRequestEnd(): void {
    this.activeRequests$.next(this.activeRequests$.value <= 0 ? 0 : this.activeRequests$.value - 1);
  }
}
