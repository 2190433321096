<main class="my-listings">
  <section class="my-listings__wrapper">
    <div class="my-listings__title">
      <h3>My Active Listings</h3>
      <share *ngIf="listings && listings.length" [shareLink]="shareLink" [user]="user"></share>
    </div>
    <div class="my-listings__list">
      <div class="card" *ngFor="let listing of openListings" (click)="openOffers(listing.id)">
        <div class="card__new-message" *ngIf="listing.hasUnreadMessage">You Have A New Message</div>
        <img imageLoader [src]="getSource(listing)" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              <div class="row ml-1">
                <div class="mb-3 w-100">
                  <span class="card__price-type">Highest Offer</span>
                  <span class="text-nowrap">
                    $ {{ listing.higher_offer_price | number: '.2' }}
                  </span>
                </div>
                <div class="w-100">
                  <span class="card__price-type">Initial Price</span>
                  <span class="text-nowrap"> $ {{ listing.price | number: '.2' }}</span>
                </div>
              </div>
            </div>
            <div class="card__description">
              <span class="card__description-type">{{ listing.bedroom_amount }} BED</span>
              <span class="card__description-type">{{ listing.bathroom_amount }} BATH</span>
              <span class="card__description-type">{{ listing.lot_size | number: '.2' }} SQFT</span>
            </div>
            <div class="card__address">{{ listing.address.city }}</div>
          </div>
          <a class="card__btn"></a>
        </div>
      </div>
    </div>

    <div class="my-listings__title">
      <h3>Closed Deals</h3>
      <p class="my-listings__subtitle">Deals that you’ve succesfully Closed</p>
    </div>
    <div
      class="my-listings__list"
      *ngIf="closedListings && closedListings[0]"
      (click)="openOffers(closedListings[0].id)"
    >
      <div class="card">
        <img imageLoader [src]="getSource(closedListings[0])" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              <span class="card__price-type">SEALED OFFER</span>
              $ {{ closedListings[0].higher_offer_price | number: '.2' }}
            </div>
            <div class="card__description">
              <span class="card__description-type">{{ closedListings[0].bedroom_amount }} BED</span>
              <span class="card__description-type"
                >{{ closedListings[0].bathroom_amount }} BATH</span
              >
              <span class="card__description-type"
                >{{ closedListings[0].lot_size | number: '.2' }} SQFT</span
              >
            </div>
            <div class="card__address">{{ closedListings[0].address.city }}</div>
          </div>
          <a class="card__btn"></a>
        </div>
      </div>
    </div>
    <div class="my-listings__table">
      <div class="my-listings__row" *ngFor="let listing of closedListings | slice: 1">
        <div class="my-listings__img">
          <img [src]="getSource(listing)" alt="" />
        </div>
        <div class="my-listings__info">
          <div class="my-listings__price">$ {{ listing.higher_offer_price | number: '.2' }}</div>
          <div class="my-listings__description">
            <span class="my-listings__description-type">{{ listing.bedroom_amount }} BED</span>
            <span class="my-listings__description-type">{{ listing.bathroom_amount }} BATH</span>
            <span class="my-listings__description-type"
              >{{ listing.lot_size | number: '.2' }} SQFT</span
            >
          </div>
        </div>
        <div class="my-listings__address">{{ listing.address.city }}</div>
        <div class="my-listings__link">
          <a class="my-listings__link-btn" (click)="openOffers(listing.id)"></a>
        </div>
      </div>
    </div>
  </section>
</main>
<footer class="footer my-listings__footer">
  <div class="my-listings__footer-container">
    <button class="message-user btn submit" routerLink="/create-listing">Create New listing</button>
  </div>
</footer>
