<main class="my-listings">
  <section class="my-listings__wrapper">
    <div class="my-listings__list">
      <div class="my-listings__title" *ngIf="user">
        <img [src]="user.photo || '../../../images/icons/avatar.png'" class="image" />
        <span class="person">{{ user.first_name + '\n' + user.last_name }}</span>
        <span class="address" *ngIf="user.address">
          {{ user.address }}
        </span>
      </div>
      <div class="card" *ngFor="let listing of listings" [routerLink]="'/listing/' + listing.id">
        <img imageLoader [src]="getSource(listing)" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              <div class="row ml-1">
                <div class="mb-3 w-100">
                  <span class="card__price-type">Highest Offer</span>
                  $ {{ listing.higher_offer_price | number: '.2' }}
                </div>
                <div class="w-100">
                  <span class="card__price-type">Initial Price</span>
                  $ {{ listing.price | number: '.2' }}
                </div>
              </div>
            </div>
            <div class="card__description">
              <span class="card__description-type">{{ listing.bedroom_amount }} BED</span>
              <span class="card__description-type">{{ listing.bathroom_amount }} BATH</span>
              <span class="card__description-type">{{ listing.lot_size | number: '.2' }} SQFT</span>
            </div>
            <div class="card__address">{{ listing.address.city }}</div>
          </div>
          <a class="card__btn"></a>
        </div>
      </div>
    </div>
  </section>
</main>
