import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { typeFilePattern, validFileFormats } from '../photo-file.constant';

@Directive({
  selector: '[validatePhotoFile]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhotoFileValidator, multi: true }],
})
export class PhotoFileValidator implements Validator {
  validate(c: FormControl): ValidationErrors {
    if (!c.value) {
      return null;
    }

    return !validFileFormats.includes(typeFilePattern.exec(c.value)[1]) ? { isPhoto: false } : null;
  }
}
