<ng-container class="login" [ngSwitch]="data.isInvestor">
    <div *ngSwitchCase="false" id="rate-deal" class="modal__content rate-popup">
        <h4 class="modal__title">Rate Deal</h4>
        <p class="modal__text">You recently closed a deal, how was your experience?</p>
        <div class="modal__author">
            <img [src]="data.person.photo || '../../../images/icons/avatar.png'" alt="" class="modal__author-photo">
            <div class="modal__author-text">
                <div class="modal__author-name">{{data.person.first_name}} {{data.person.last_name}}</div>
                <div class="modal__author-rating">{{data.person.rating || 0 | number: '1.1-1' }} Rating</div>
            </div>
        </div>
        <div class="modal__rate">
            <span *ngFor="let rate of ratingValues" class="modal__rate-star" [ngClass]="{active : rate <= rating}" (click)="setRating(rate)"></span>
        </div>
        <button type="submit" class="submit btn" (click)="dialogRef.close(rating)">Submit Rating</button>
        <button class="close close-popup" (click)="dialogRef.close()"></button>
    </div>
    <div *ngSwitchCase="true" id="rate-deal-2" class="modal__content rate-popup">
        <h4 class="modal__title">Rate Deal</h4>
        <p class="modal__text">You did an amazing job with this deal and it shows. <br>We're excited to see your next deal.</p>
        <div class="modal__author">
            <img [src]="data.person.photo || '../../../images/icons/avatar.png'" alt="" class="modal__author-photo">
            <div class="modal__author-text">
                <div class="modal__author-name">{{data.person.first_name}} {{data.person.last_name}}</div>
                <div class="modal__author-rating">{{data.person.rating || 0 | number: '1.1-1' }} Rating</div>
            </div>
        </div>
        <div class="modal__rate">
            <span *ngFor="let rate of ratingValues" class="modal__rate-star" [ngClass]="{active : rate <= rating}" (click)="setRating(rate)"></span>
        </div>
        <p class="modal__tip">To keep dealup honest and outstanding, please rate the user you worked with.</p>
        <button type="submit" class="submit btn" (click)="dialogRef.close(rating)">Submit Rating</button>
        <button class="close close-popup" (click)="dialogRef.close()"></button>
    </div>
</ng-container>