import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from '../../environments/environment';
import {
  OfferInterface,
  OfferNdaStatusEnum,
  OfferStatusEnum,
  OfferStatuses,
  TimeStamps,
} from '../listings/listing.interface';
import { NdaConfirmationDialogComponent } from '../common/dialogs/nda-confirmation-dialog/nda-confirmation-dialog.component';
import { RatePersonDialogComponent } from '../common/dialogs/rate-person-dialog/rate-person-dialog.component';
import { GeneralDialogComponent } from '../common/dialogs/general-dialog/general-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router) {}

  createOffer(id: number, price: number): Observable<OfferInterface> {
    return this.http.post<OfferInterface>(`${environment.apiUrl}listings/${id}/offer/`, {
      price,
    });
  }

  getMyOffers(): Observable<OfferInterface[]> {
    return this.http.get<OfferInterface[]>(`${environment.apiUrl}offers/`);
  }

  getOffer(id: number): Observable<OfferInterface> {
    return this.http.get<OfferInterface>(`${environment.apiUrl}offers/${id}/`);
  }

  setRate(id: number, rating: number): Observable<OfferInterface> {
    return this.http.post<OfferInterface>(`${environment.apiUrl}offers/${id}/rate_user/`, {
      rating,
    });
  }

  updateOffer(
    id: number,
    data:
      | TimeStamps
      | Pick<OfferInterface, 'status'>
      | Pick<OfferInterface, 'price'>
      | Pick<OfferInterface, 'status_nda'>
      | OfferStatuses,
  ): Observable<OfferInterface> {
    return this.http.post<OfferInterface>(`${environment.apiUrl}offers/${id}/`, data);
  }

  counterOffer(id: number, price: Pick<OfferInterface, 'price'>): Observable<OfferInterface> {
    return this.http.post<OfferInterface>(
      `${environment.apiUrl}offers/${id}/counter_offer_notification/`,
      price,
    );
  }

  showRateModal(offer: OfferInterface, isInvestor: boolean): void {
    if (offer.listing.status === 'closed' && !offer.is_buyer_rate_seller) {
      const dialogRef = this.dialog.open(RatePersonDialogComponent, {
        width: '800px',
        height: '200px',
        data: {
          isInvestor,
          person: isInvestor ? offer.listing.seller : offer.buyer,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.setRate(offer.id, result).subscribe(() => {});
        }
      });
    }
  }

  updateNdaNotification(id: number): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}offers/${id}/nda_notification/`, {});
  }

  loseDeal(): void {
    const dialoRef = this.dialog.open(GeneralDialogComponent, {
      width: '840px',
      height: '450px',
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        title: 'Deal Rejected',
        text: `Unfortunately, Deal was rejected and the Seller accepted 
someone's else Offer. 
You can visit DealUp to find more Listings.`,
        buttonText: 'OK, I got it',
        showProtip: false,
      },
    });
    dialoRef.afterClosed().subscribe(() => {
      this.router.navigate([`/my-offers`]);
    });
  }

  checkDealClosed(offer: OfferInterface, update$: Subject<number>): void {
    const dealsClosed = JSON.parse(localStorage.getItem('dealsClosed')) || [];
    if (offer.status === OfferStatusEnum.deal_closed) {
      if (offer.status_nda === 'NDA_shown') {
        const dialogRef = this.dialog.open(NdaConfirmationDialogComponent, {
          width: '840px',
          height: '600px',
        });
        dealsClosed.push(offer.id);
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe((res) => {
            if (res) {
              dealsClosed.push(offer.id);
              localStorage.setItem('dealsClosed', JSON.stringify(dealsClosed));
              this.showRateModal(offer, true);
              this.updateNdaNotification(offer.id).subscribe();
              this.updateOffer(offer.id, {
                status_nda: OfferNdaStatusEnum.accepted,
              }).subscribe(() => update$.next(offer.listing.id));
            } else {
              this.updateOffer(offer.id, {
                status_nda: OfferNdaStatusEnum.rejected,
              }).subscribe(() => update$.next(offer.listing.id));
            }
          });
      } else if (!offer.is_buyer_rate_seller) {
        this.showRateModal(offer, true);
      }
    }
  }
}
