import { Injectable } from '@angular/core';
import { PubNubAngular } from 'pubnub-angular2';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {
  MessageInterface,
  MessageStatus,
  UnreadMessageInterface,
} from '../../listings/listing.interface';
import { ListingService } from '../../listings/listing.service';
import { OfferService } from '../offer.service';
import { ConfirmationDialogComponent } from '../../common/dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PubnubService {
  private newMessages$: BehaviorSubject<UnreadMessageInterface> = new BehaviorSubject({});

  private newMessages: UnreadMessageInterface;

  private destroy$ = new Subject<void>();

  offers: string[] = [];

  getOffersChannel: string;

  userId: string;

  chats: string[] = [];

  pubnub: PubNubAngular;

  offerToListing: Map<number, number> = new Map();

  private sentMessage$ = new Subject<{
    messageNumber: number;
    messageBody: MessageInterface;
  }>();

  private updateOffer$ = new Subject<MessageInterface>();

  subscribed = false;

  constructor(
    private pubnubService: PubNubAngular,
    private offersService: OfferService,
    private listingService: ListingService,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.pubnub = pubnubService;
  }

  getNewMessages$(): BehaviorSubject<UnreadMessageInterface> {
    return this.newMessages$;
  }

  getSentMessage$(): Subject<{
    messageNumber: number;
    messageBody: MessageInterface;
  }> {
    return this.sentMessage$;
  }

  getUpdateOffer$(): Subject<MessageInterface> {
    return this.updateOffer$;
  }

  createCounterOfferMessage(userId: number, chatId: string, price: number, name: string): string {
    return `Hi, ${name}!
The Seller sent you the Counter Offer on DealUp, which is equals $${price}. You can make a new Offer and continue negotiation.
Best,
-Team DealUp`;
  }

  sendMessage(channel: number, message: string, userId: number, messageNumber?: number): void {
    this.pubnub.publish({ channel, message, meta: { user: userId } }, (status, time) => {
      const messageBody = {
        channel: channel.toString(),
        message,
        date: new Date(Math.ceil(+time.timetoken / 10000)),
        status: MessageStatus.Sent,
        timetoken: time.timetoken,
        messageType: null,
        uuid: userId.toString(),
      };
      this.sentMessage$.next({ messageNumber, messageBody });
    });
  }

  readMessage(offerID: string, timeStamp: string): void {
    this.pubnub.publish(
      {
        channel: offerID,
        message: { lastRead: timeStamp },
        meta: { user: this.userId },
      },
      () => {},
    );
    this.newMessages[offerID] = {
      message: { lastRead: timeStamp },
      hasNewMessage: false,
    };
    this.newMessages$.next(this.newMessages);
  }

  emitNewMessages(): void {
    this.newMessages$.next(this.newMessages || {});
  }

  newOffer(sellerId: number, offerID: number, listingID: number, msg?: string): void {
    if (msg) {
      this.sendMessage(offerID, msg, +this.userId);
    }
    this.pubnub.publish(
      {
        channel: `getOffers${sellerId.toString()}`,
        message: { newOffer: offerID, listing: listingID },
        meta: { user: this.userId },
      },
      () => {
        this.unsubscribePubnub();
        this.subscribePubnub(offerID.toString());
      },
    );
  }

  sendRefreshOffer(offerID: number): void {
    this.pubnub.publish(
      {
        channel: offerID.toString(),
        message: { updateOffer: offerID },
        meta: { user: this.userId },
      },
      () => {},
    );
  }

  sendRefreshListing(listingId: number): void {
    const offersList = [];
    this.listingService.getOffersForListening(listingId).subscribe((listingWithOffers) => {
      if (listingWithOffers.offers?.length) {
        offersList.push(...listingWithOffers.offers.map((offer) => offer.id.toString()));
      }
      offersList.forEach((offer) => {
        this.pubnub.publish(
          {
            channel: offer,
            message: { updateOffer: offer },
            meta: { user: this.userId },
          },
          () => {},
        );
      });
    });
  }

  subscribePubnub(newChat?: string): PubNubAngular {
    if (newChat) {
      this.chats.push(newChat);
    }
    if (!this.subscribed) {
      this.subscribed = true;
      this.pubnub.init({
        publishKey: environment.pubNubPublish,
        subscribeKey: environment.pubNubSubscribe,
        uuid: this.userId,
      });
      this.pubnub.subscribe({
        channels: this.chats,
        triggerEvents: true,
        withPresence: true,
      });
      this.updateUnreadMessage(this.offers);
    }
    return this.pubnub;
  }

  getOffersInfo(): void {
    const offersList = [];
    const listingsSubscribe = this.listingService.getMyListings().pipe(
      takeUntil(this.destroy$),
      switchMap((listings) => {
        const obs$ = listings.map((listing) => {
          return this.listingService.getOffersForListening(listing.id).pipe(
            tap((listingWithOffers) => {
              if (listingWithOffers.offers?.length) {
                offersList.push(...listingWithOffers.offers.map((offer) => offer.id.toString()));
                listingWithOffers.offers.forEach((offer) => {
                  this.offerToListing.set(offer.id, listing.id);
                });
              }
            }),
          );
        });
        return forkJoin(obs$);
      }),
    );
    const offerSubscribe = this.offersService.getMyOffers().pipe(
      takeUntil(this.destroy$),
      map((offers) => offers.map((offer) => offer.id.toString())),
    );
    forkJoin([listingsSubscribe, offerSubscribe]).subscribe((items) => {
      this.offers = items[1];
      this.unsubscribePubnub();
      this.chats.push(...this.offers);
      this.subscribePubnub();
    });
  }

  start(userId: number): void {
    this.userId = userId.toString();
    this.pubnub.init({
      publishKey: environment.pubNubPublish,
      subscribeKey: environment.pubNubSubscribe,
      uuid: userId,
    });
    this.getOffersChannel = `getOffers${userId.toString()}`;
    this.chats.push(this.getOffersChannel);
    this.subscribePubnub();
    this.pubnub.subscribe({
      channels: this.chats,
      triggerEvents: true,
      withPresence: true,
    });
    this.getOffersInfo();
  }

  unsubscribePubnub(): void {
    if (this.subscribed) {
      this.subscribed = false;
      this.newMessages$.next({});
      this.newMessages = {};
      this.pubnub.removeAllListeners();
      this.pubnub.unsubscribeAll();
      this.pubnub.stop();
    }
  }

  clear(): void {
    this.chats = [];
    this.offers = [];
    this.offerToListing.clear();
  }

  deleteListing(id: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Are you sure want to delete this Listing?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.listingService
          .deleteListing(id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.router.navigate(['/my-listings']));
      }
    });
  }

  updateUnreadMessage(offerIds: string[]): void {
    this.pubnub.addListener({
      message: (msg) => {
        if (msg.channel === this.getOffersChannel) {
          this.updateOffer$.next(msg);
          this.unsubscribePubnub();
          this.chats.push(msg.message.newOffer);
          this.offers.push(msg.message.newOffer);
          this.offerToListing.set(msg.message.newOffer, msg.message.listing);
          this.subscribePubnub();
        } else if (msg.userMetadata.user.toString() !== this.userId) {
          const message = {
            hasNewMessage: true,
            message: msg,
          };
          message.hasNewMessage =
            typeof msg.message === 'string' ||
            (this.newMessages[msg.channel] && this.newMessages[msg.channel].hasNewMessage);
          this.newMessages[msg.channel] = message;
          if (msg.message.updateOffer) {
            this.updateOffer$.next(msg);
          } else {
            this.newMessages$.next(this.newMessages);
          }
        }
      },
    });
    this.pubnub.fetchMessages({ channels: [offerIds], count: 100 }, (status, msg) => {
      const array = {};
      let lastReadMessageStamp = '0';
      if (msg) {
        Object.keys(msg.channels).forEach((key) => {
          const messages = msg.channels[key].filter(
            (message) =>
              typeof message.message === 'string' && message.uuid.toString() !== this.userId,
          );
          msg.channels[key]
            .filter(
              (item) => typeof item.message !== 'string' && Object.keys(item.message).length > 0,
            )
            .forEach((message) => {
              if (
                (message.uuid !== this.userId && lastReadMessageStamp < message.message.lastRead) ||
                message.uuid === this.userId
              ) {
                lastReadMessageStamp = message.message.lastRead;
              }
            });
          const timeToken = messages.length > 0 ? messages[messages.length - 1].timetoken : null;
          const hasNewMessage = timeToken
            ? !lastReadMessageStamp || timeToken > lastReadMessageStamp
            : false;
          array[key] = {
            hasNewMessage,
            message: msg.channels[key],
          };
        });
      }
      this.newMessages = array;
      this.newMessages$.next(this.newMessages);
    });
  }
}
