import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { finalize, switchMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import {
  ListingAddressInterface,
  ListingComparisonInterface,
  ListingInterface,
} from '../listing.interface';
import { ListingService } from '../listing.service';
import { PostedDialogComponent } from '../../common/dialogs/posted-dialog/posted-dialog.component';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { GeneralDialogComponent } from '../../common/dialogs/general-dialog/general-dialog.component';

@Component({
  selector: 'add-edit-listing-container',
  templateUrl: './add-edit-listing-container.component.html',
  styleUrls: ['./add-edit-listing-container.component.scss'],
})
export class AddEditListingContainerComponent implements OnInit, OnDestroy {
  state: 'listing' | 'comparison' = 'listing';

  listing: ListingInterface;

  id: number;

  initialAddress = '';

  requestInProgress = false;

  constructor(
    private route: ActivatedRoute,
    private listingService: ListingService,
    private dialog: MatDialog,
    private router: Router,
    private headerFooterStateService: HeaderFooterStateService,
  ) {
    this.headerFooterStateService.setView('additional-footer');
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;

    if (this.id) {
      this.listingService.getListing(this.id).subscribe((listing) => {
        this.listing = listing;
        this.initialAddress = this.listing.address.readable_address;
      });
    } else {
      this.listing = <ListingInterface>{};
      this.listing.address = <ListingAddressInterface>{};
      this.listing.comparison = <ListingComparisonInterface>{};
      this.listing.comparison.address = <ListingAddressInterface>{};
      this.listing.bids_notifications = true;
    }
  }

  ngOnDestroy(): void {
    this.listingService.mediaFiles = [];
    this.listingService.toAddMedia = [];
    this.listingService.toDeleteMedia = [];
  }

  nextState(): void {
    this.state = 'comparison';
  }

  backState(): void {
    this.state = 'listing';
    this.requestInProgress = false;
  }

  submit(listing: ListingInterface): void {
    if (!this.requestInProgress) {
      this.requestInProgress = true;
      if (this.id) {
        this.listingService
          .updateListing(
            listing.id,
            listing,
            this.initialAddress === listing.address.readable_address,
          )
          .pipe(
            switchMap(() => {
              const obs$ = [];
              if (this.listingService.toDeleteMedia.length) {
                obs$.push(this.listingService.deleteMedia(listing.id));
              }
              if (this.listingService.toAddMedia.length) {
                obs$.push(this.listingService.addMedia(listing.id));
              }
              return forkJoin(obs$);
            }),
            finalize(() => {
              this.requestInProgress = false;
              this.listingService.toAddMedia = [];
              this.listingService.toDeleteMedia = [];
              this.router.navigate(['/my-listings']);
            }),
          )
          .subscribe(() => {});
      } else {
        this.listingService.createListing(listing).subscribe(() => {
          const dialogRef = this.dialog.open(PostedDialogComponent, {
            width: '800px',
            height: '375px',
            data: {
              type: 'listing',
            },
            disableClose: true,
          });

          dialogRef.afterClosed().subscribe(() => {
            if (!JSON.parse(localStorage.getItem('isFirstListing'))) {
              localStorage.setItem('isFirstListing', JSON.stringify(true));

              const dialogRef1 = this.dialog.open(GeneralDialogComponent, {
                width: '840px',
                height: '450px',
                panelClass: 'general-dialog',
                disableClose: true,
                data: {
                  title: 'Sell Your Listing Faster',
                  text: `We want to see your listing be as successful as possible. 
                Please make sure that you filled in all of the information
                 accurately. 
              
              Hint: The more accurate the information, the quicker your deal will close.`,
                  buttonText: 'Gotcha, Let’s Go.',
                  showProtip: true,
                },
              });
              dialogRef1.afterClosed().subscribe(() => {
                this.router.navigate(['/my-listings']);
              });
            } else {
              this.router.navigate(['/my-listings']);
            }
            this.requestInProgress = false;
          });
        });
      }
    }
  }
}
