import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'nda-confirmation-dialog',
  templateUrl: './nda-confirmation-dialog.component.html',
  styleUrls: ['./nda-confirmation-dialog.component.scss'],
})
export class NdaConfirmationDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {}
}
