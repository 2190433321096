import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'cities-input',
  templateUrl: './cities-input.component.html',
  styleUrls: ['./cities-input.component.scss'],
})
export class CitiesInputComponent {
  @Input() cities: string[] = [];

  @Input() placeholder: string;

  @Input() view = 'default';

  @Output() citiesChanged = new EventEmitter<string[]>();

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;

  cityInput = '';

  googlePlacesOptions = {
    types: ['(cities)'],
    componentRestrictions: { country: 'USA' },
  };

  public handleAddressChange(address: Address): void {
    this.cityInput = '';
    const name = address.formatted_address
      .substring(0, address.formatted_address.length - 5)
      .split(',')
      .map((item, index, arr) => {
        if (index === arr.length - 1) {
          return item.replace(/[0-9]/g, '');
        }

        return item;
      })
      .join(',')
      .trim();
    if (!this.cities.some((city) => city === name)) {
      this.cities.push(name);
      this.citiesChanged.emit(this.cities);
    }
  }

  public remove(idx): void {
    this.cities.splice(idx, 1);
    this.citiesChanged.emit(this.cities);
  }
}
