<div class="preview-container" *ngIf="showPreview">
  <i *ngIf="!hideDelete" class="fa fa-trash delete-icon" (click)="removeItem()"></i>
  <div class="close-btn" (click)="close()">✕</div>
  <div class="media-container">
    <ng-container *ngFor="let mediaItem of media; let i = index">
      <img class="media-item" *ngIf="mediaItem?.image" [src]="mediaItem?.image"  [@carouselAnimation]="i === mediaIndex">
      <video controls="true" class="media-item" *ngIf="mediaItem?.video" [src]="mediaItem?.video | videoDataTypeConverter" [@carouselAnimation]="i === mediaIndex" type="video/mp4"></video>
    </ng-container> 
  </div>
  <div class="control prev" (click)="onPreviousClick()" [class.disabled]="mediaIndex === 0">
    <span class="arrow left"></span>
  </div>
  <div class="control next" (click)="onNextClick()" [class.disabled]="mediaIndex === media.length - 1">
    <span class="arrow right"></span>
  </div>
</div>