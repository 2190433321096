import { Component } from '@angular/core';
import { UserService } from '../../user/user.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  constructor(
    private userService: UserService,
    private headerFooterStateService: HeaderFooterStateService,
  ) {
    if (!this.userService.isAuthenticated()) {
      this.headerFooterStateService.setView('auth-black');
    } else {
      this.headerFooterStateService.setView('main');
    }
  }
}
