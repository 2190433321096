import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { validFileTypes } from '../../common/directives/photo-file.constant';
import {
  GalleryMediaInterface,
  ListingAddressInterface,
  ListingComparisonInterface,
  ListingInterface,
} from '../listing.interface';

@Component({
  selector: 'add-edit-comparison',
  templateUrl: './add-edit-comparison.component.html',
  styleUrls: ['./add-edit-comparison.component.scss'],
})
export class AddEditComparisonComponent implements OnInit, DoCheck {
  @Input() listing: ListingInterface;

  @Input() isAddMode: boolean;

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() saveChanges: EventEmitter<ListingInterface> = new EventEmitter<ListingInterface>();

  imageValidationError: boolean;

  maxDate: Date = new Date(Date.now());

  showFullScreenFlag = false;

  fullScreenImage: GalleryMediaInterface;

  isFormEmpty = true;

  date: Date;

  googlePlacesOptions = {
    types: ['address'],
    componentRestrictions: { country: 'USA' },
  };

  constructor(private dialog: MatDialog, private datePipe: DatePipe) {}

  ngOnInit(): void {
    if (this.listing.comparison) {
      this.fullScreenImage = { image: this.listing.comparison.photo };
      this.date = new Date(this.listing.comparison.date_sold);
    } else {
      this.listing.comparison = <ListingComparisonInterface>{};
      this.listing.comparison.address = <ListingAddressInterface>{};
    }
  }

  ngDoCheck(): void {
    this.checkIsFormEmpty();
  }

  photoUpload(file: File): void {
    if (validFileTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event): void => {
        this.listing.comparison.photo = <string>event.target.result;
        this.fullScreenImage = { image: <string>event.target.result };
      };
      this.imageValidationError = false;
    } else {
      this.imageValidationError = true;
    }
  }

  removeSelectedItem(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Are you sure want to delete?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fullScreenImage = undefined;
        this.listing.comparison.photo = undefined;
        this.closeFullScreen();
      }
    });
  }

  backToEditListing(): void {
    this.back.emit(true);
  }

  submit(): void {
    this.saveChanges.emit({
      ...this.listing,
      comparison: this.isFormEmpty
        ? null
        : {
            ...this.listing.comparison,
          },
    });
  }

  handleAddressChange(event: Address): void {
    this.listing.comparison.address.readable_address = event.formatted_address;
  }

  checkIsFormEmpty(): void {
    const isFormEmptyWithoutAddress = !Object.keys(this.listing.comparison)
      .filter((item) => item !== 'address')
      .some((item) => !!this.listing.comparison[item]);

    const isAddressEmpty = !Object.keys(this.listing.comparison.address).some(
      (item) => !!this.listing.comparison.address[item],
    );

    this.isFormEmpty = isFormEmptyWithoutAddress && isAddressEmpty;
  }

  showFullScreen(): void {
    this.showFullScreenFlag = true;
  }

  closeFullScreen(): void {
    this.showFullScreenFlag = false;
  }

  updateDate(date): void {
    this.listing.comparison.date_sold = this.datePipe.transform(date, 'MM/dd/yyyy');
  }

  adressFieldValidator(address: string): boolean {
    // valid address example: 1222 1st Ave, San Diego, CA 92187, USA
    let err = false;
    if (address) {
      const checkAddressArr = address.split(', ');
      if (checkAddressArr.length !== 4 || checkAddressArr[checkAddressArr.length - 1] !== 'USA') {
        err = true;
      } else if (!parseInt(checkAddressArr[0].split(' ')[0], 10)) {
        err = true;
      }
    }
    return err;
  }
}
