import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnDestroy, AfterViewInit {
  @Input() isLoggedIn: boolean;

  @ViewChild('container') container: ElementRef;

  showNav: BehaviorSubject<boolean>;

  constructor(private navService: NavService) {
    this.showNav = this.navService.getShowNav();
  }

  ngAfterViewInit(): void {
    this.container.nativeElement.ontouchmove = (e): void => {
      e.preventDefault();
      e.stopPropagation();
    };
    this.container.nativeElement.onwheel = (e): void => {
      e.preventDefault();
      e.stopPropagation();
    };
  }

  getSideNavBarStyle(show: boolean): CSSStyleDeclaration {
    const navBarStyle: CSSStyleDeclaration = <CSSStyleDeclaration>{};

    navBarStyle.transition = 'right 1s, visibility 1s';
    navBarStyle.width = '60%';
    navBarStyle.right = `${show ? 0 : '-60'}%`;

    return navBarStyle;
  }

  closeNavBar(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.navService.setShowNav(false);
  }

  ngOnDestroy(): void {
    this.navService.setShowNav(false);
  }
}
