import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { HeaderFooterStateService } from 'src/app/common/services/header-footer-state.service';
import { LatLngBounds } from '@agm/core';
import { Router } from '@angular/router';
import { DealsCarouselData, DealsCarouselInterface } from './deals-carousel.constant';
import { ListingInterface, DealStatusEnum } from '../../listings/listing.interface';
import { ListingService } from '../../listings/listing.service';
import { UserInterface } from '../../user/user.interface';
import { DEFAULT_ZOOM } from '../deals-maps.constant';

@Component({
  selector: 'deals-container',
  templateUrl: './deals-container.component.html',
  styleUrls: ['./deals-container.component.scss'],
})
export class DealsContainerComponent implements AfterViewInit {
  @ViewChild('tutorial') tutorial: ElementRef;

  currentCarouselIndex = 0;

  carouselData: DealsCarouselInterface[] = DealsCarouselData;

  showTutorial = true;

  user: UserInterface;

  listings: ListingInterface[];

  sorting: string = null;

  priceRange: string = null;

  borderCoords: LatLngBounds;

  currentCities: string[];

  mapZoom = DEFAULT_ZOOM;

  startCoords: { x: number; y: number };

  constructor(
    private listingService: ListingService,
    private userService: UserService,
    private headerFooterStateService: HeaderFooterStateService,
    private router: Router,
  ) {
    if (!this.userService.isVerified()) {
      this.headerFooterStateService.setView('unauthorized');
      this.user = null;
    } else {
      this.headerFooterStateService.setView('main');
      this.user = this.userService.getUser();
    }
    this.init();
  }

  init(): void {
    this.showTutorial = !JSON.parse(localStorage.getItem('tutorialClosed'));
    const lastZoom = +JSON.parse(localStorage.getItem('lastZoom'));
    this.mapZoom = lastZoom || DEFAULT_ZOOM;

    const lastCities = JSON.parse(localStorage.getItem('lastCities'));
    this.currentCities = lastCities || (this.user ? this.user.cities : []);
    if (!this.currentCities.length) {
      const lastCoords = JSON.parse(localStorage.getItem('lastCoordinates'));
      if (lastCoords) {
        this.startCoords = lastCoords;

        return;
      }
    }
    this.getCityCoordinates(this.currentCities);
  }

  ngAfterViewInit(): void {
    if (!this.showTutorial) {
      this.tutorial.nativeElement.classList.toggle('d-none');
    }
  }

  prevSlide(): void {
    if (this.currentCarouselIndex === 0) {
      this.currentCarouselIndex = 3;
    }
    this.currentCarouselIndex--;
  }

  nextSlide(): void {
    if (this.currentCarouselIndex === 2) {
      this.currentCarouselIndex = -1;
    }
    this.currentCarouselIndex++;
  }

  changeSlide(index: number): void {
    this.currentCarouselIndex = index;
  }

  changeCoords(coords: LatLngBounds): void {
    this.borderCoords = coords;
  }

  updateListings(): void {
    const JsonedBorderCords = this.borderCoords.toJSON();
    const lastCoordinates = {
      x: (JsonedBorderCords.south + JsonedBorderCords.north) / 2,
      y: (JsonedBorderCords.west + JsonedBorderCords.east) / 2,
    };

    localStorage.setItem('lastCoordinates', JSON.stringify(lastCoordinates));
    localStorage.setItem('lastCities', JSON.stringify(this.currentCities));
    this.listingService
      .getListingsByCoords(JsonedBorderCords, this.currentCities, this.sorting, this.priceRange)
      .subscribe((item) => {
        this.listings = item.filter((listing) => listing.status === DealStatusEnum.negotiation);
        this.listings = this.listings.map((listing) => {
          return {
            ...listing,
            options: {
              className: 'marker-text',
              color: 'black',
              fontWeight: '600',
              text: this.formatNumber(listing.price),
              fontFamily: 'Poppins',
              fontSize: '10px',
            },
          };
        });
      });
  }

  storeZoom(zoom: number): void {
    localStorage.setItem('lastZoom', String(zoom));
  }

  formatNumber(num: number): string {
    const si = [
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' },
    ];
    for (let i = 0; i < si.length; i++) {
      if (num >= si[i].value) {
        return (num / si[i].value).toFixed(0).replace(/\.0+$/, '') + si[i].symbol;
      }
    }

    return String(num).replace(/\.0+$/, '');
  }

  trackByFn(index, item): number {
    return item.id;
  }

  getSource(listing: ListingInterface): string {
    return listing.photo?.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }

  redirectToListing(listing: ListingInterface): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(['listing', listing.id]));
    window.open(url, '_blank');
  }

  cityInputChanged(cities: string[]): void {
    if (cities.length) {
      this.mapZoom = DEFAULT_ZOOM;
      this.getCityCoordinates([cities[cities.length - 1]]);
    } else {
      this.updateListings();
    }
  }

  closeTutorial(): void {
    localStorage.setItem('tutorialClosed', JSON.stringify(true));
    this.tutorial.nativeElement.classList.toggle('close');
    this.showTutorial = false;
  }

  getCityCoordinates(cities: string[]): void {
    this.listingService.getMostPopularCity(cities).subscribe((coords) => {
      this.startCoords = {
        x: +coords.city.x,
        y: +coords.city.y,
      };
    });
  }
}
