import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../user.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { GeneralDialogComponent } from '../../common/dialogs/general-dialog/general-dialog.component';
import { PubnubService } from "../../my-offers/pubnub/pubnub.service";

interface UserData {
  first_name: string;
  last_name: string;
  password: string;
  email: string;
}

interface SignUpResponse {
  email: string;
  first_name: string;
  id: number;
  is_verified: boolean;
  last_name: string;
}

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [],
})
export class SignUpComponent implements OnInit {
  confirmClicked = false;

  cities = [];

  view = 'signUp';

  error = null;

  dialCode = '+1';

  wrongCode = false;

  phoneNumber: string;

  isSocialAuth: boolean;

  user: UserData;

  signUpResponse: SignUpResponse;

  signUpForm: FormGroup = new FormGroup(
    {
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl('', [Validators.required]),
      email: new FormControl('', {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
        updateOn: 'blur',
      }),
    },
    this.checkPasswords,
  );

  phoneForm: FormGroup = new FormGroup({
    phoneNumber: new FormControl('', [Validators.required, Validators.minLength(11)]),
  });

  validationForm: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(4)]),
  });

  constructor(
    private headerFooterStateService: HeaderFooterStateService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private pubnubService: PubnubService,
  ) {
    if (this.userService.isAuthenticated()) {
      if (this.userService.isVerified()) {
        this.headerFooterStateService.setView('main');
        this.router.navigate(['deals']);
      } else {
        const user = this.userService.getUser();
        this.signUpResponse = {
          email: user.email,
          first_name: user.first_name,
          id: user.id,
          is_verified: user.is_verified,
          last_name: user.last_name,
        };
        this.view = 'phone';
        this.confirmClicked = false;
      }
    }
  }

  ngOnInit(): void {
    this.headerFooterStateService.setView('auth');
  }

  goToDeals(): void {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      width: '840px',
      height: '450px',
      panelClass: 'general-dialog',
      disableClose: true,
      data: {
        title: 'Welcome to Dealup',
        text: `Your friendly real estate deal platform.`,
        buttonText: 'Thanks! Let’s get started',
        showProtip: false,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      localStorage.removeItem('lastZoom');
      localStorage.removeItem('lastCities');
      this.headerFooterStateService.setView('main');
      this.router.navigate(['/deals']);
    });
  }

  submit() {
    const user = this.userService.getUser();
    user.cities = this.cities;
    this.userService.updateUser(user).subscribe(() => {
      this.goToDeals();
    });
  }

  updateCities(cities) {
    this.cities = cities;
  }

  checkPasswords(group: FormGroup): { mismatch: boolean } {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  signInWithGoogle(): void {
    this.userService.redirectToGoogleAuth();
  }

  signInWithFB(): void {
    this.userService.redirectToFacebookAuth();
  }

  codeCountryInit(obj) {
    this.dialCode = `+${obj.dialCode}`;
  }

  signUpClick(): void {
    this.confirmClicked = true;
    if (this.signUpForm.valid) {
      this.user = {
        first_name: this.signUpForm.controls.firstName.value,
        last_name: this.signUpForm.controls.lastName.value,
        password: this.signUpForm.controls.password.value,
        email: this.signUpForm.controls.email.value,
      };
      this.userService.signUp(this.user).subscribe(
        (answer: SignUpResponse) => {
          this.signUpResponse = answer;
          this.view = 'phone';
          this.confirmClicked = false;
        },
        (error) => {
          if (error instanceof HttpErrorResponse && error.status === 400) {
            this.error = { text: error.error.email.email };
          }
        },
      );
    }
  }

  sendPhone() {
    this.confirmClicked = true;
    if (this.phoneForm.valid) {
      this.phoneNumber =
        this.dialCode + this.phoneForm.controls.phoneNumber.value.replace(' ', '').replace('-', '');
      this.userService.sendVerificationPhone({ phone_number: this.phoneNumber }).subscribe(() => {
        this.view = 'code';
        this.confirmClicked = false;
      });
    }
  }

  sendCode() {
    this.userService
      .sendVerificationCode({
        user_id: this.signUpResponse.id,
        verification_code: this.validationForm.controls.code.value,
        phone_number: this.phoneNumber,
      })
      .subscribe(
        () => {
          if (!this.userService.isAuthenticated()) {
            this.userService.login(this.user.email, this.user.password).subscribe((loginData) => {
              this.userService.setUser(loginData.data_user);
              this.userService.setAuthToken(loginData.token);
              this.pubnubService.start(loginData.data_user.id);
            });
          } else {
            this.userService.getMe().subscribe((user) => {
              this.userService.setUser(user.data_user);
            });
          }
          this.view = 'buy-box';
        },
        () => {
          this.wrongCode = true;
        },
      );
  }
}
