import { AfterViewInit, Directive, ElementRef, HostBinding, Renderer2 } from '@angular/core';

@Directive({
  selector: '[imageLoader]',
})
export class ImageLoaderDirective implements AfterViewInit {
  initialObjectFit: string;

  initialSrc: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.showLoader();
  }

  showLoader(): void {
    this.initialObjectFit = this.el.nativeElement.style.objectFit;
    this.initialSrc = this.el.nativeElement.src;
    this.renderer.setAttribute(this.el.nativeElement, 'src', '/images/logo-icon.svg');
    this.renderer.setStyle(this.el.nativeElement, 'objectFit', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'transition', 'opacity 0.2s');
    this.renderer.addClass(this.el.nativeElement, 'pulse');
    const img = new Image();
    img.src = this.initialSrc;
    img.onload = (): void => {
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
      this.renderer.removeClass(this.el.nativeElement, 'pulse');
      setTimeout(() => {
        this.renderer.removeClass(this.el.nativeElement, 'pulse');
        this.renderer.setAttribute(this.el.nativeElement, 'src', this.initialSrc);
        this.renderer.setStyle(this.el.nativeElement, 'objectFit', this.initialObjectFit);
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
      }, 200);
    };
  }
}
