/* eslint-disable no-underscore-dangle */
import { trigger, transition, style, animate, state } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { GalleryMediaInterface } from 'src/app/listings/listing.interface';

@Component({
  selector: 'media-fullscreen-view',
  templateUrl: './media-fullscreen-view.component.html',
  styleUrls: ['./media-fullscreen-view.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      state('true', style({ visibility: 'visible', opacity: 1, transform: 'scale(1)' })),
      state('false', style({ visibility: 'hidden', opacity: 0, transform: 'scale(0.5)' })),
      transition('false <=> true', animate('0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86)')),
    ]),
  ],
})
export class MediaFullscreenViewComponent implements OnDestroy {
  @Input() media: GalleryMediaInterface[];

  @Input() mediaIndex: number;

  @Input() hideDelete = false;

  @Input()
  get showPreview(): boolean {
    return this._showPreview;
  }

  set showPreview(val: boolean) {
    if (val) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    this._showPreview = val;
  }

  @Output() removeMediaItem: EventEmitter<boolean> = new EventEmitter<boolean>(); 

  @Output() closePreview: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() nextMedia: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() prevMedia: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _showPreview = false;

  ngOnDestroy(): void {
    document.body.style.overflow = 'auto';
  }

  removeItem(): void {
    this.removeMediaItem.emit(true);
  }

  close(): void {
    this.closePreview.emit(true);
  }

  onPreviousClick(): void {
    this.prevMedia.emit(true);
  }

  onNextClick(): void {
    this.nextMedia.emit(true);
  }
}
