<div class="dropdown" dropdown>
  <a class="dropdown-toggle" *ngIf="user">Share all your listings</a>
  <button class="share dropdown-toggle" *ngIf="!user"></button>
  <div class="dropdown-menu">
    <a class="dropdown-item" (click)="shareFacebook()">Share on Facebook</a>
    <a class="dropdown-item" (click)="shareViaText()">Share via Text</a>
    <a
      class="dropdown-item"
      [cdkCopyToClipboard]="shareLink"
      (cdkCopyToClipboardCopied)="clipboardCopied()"
    >
      Get Shareable link
    </a>
  </div>
</div>
