import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpLoaderService } from './http-loader.service';

type RequestForLoading = [RegExp, string];

const REQUESTS_WITH_LOADING: RequestForLoading[] = [
  [/^listings\/$/, 'POST'],
  [/^listings\/\d+\/$/, 'PATCH'],
  [/^listings\/\d+\/$/, 'DELETE'],
  [/^listings\/\d+\/video\/$/, 'POST'],
  [/^listings\/\d+\/photo\/$/, 'POST'],
  [/^listings\/\d+\/video\/delete\/$/, 'POST'],
  [/^listings\/\d+\/photo\/delete\/$/, 'POST'],
];

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  constructor(private httpLoaderService: HttpLoaderService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = `${req.url.replace(environment.apiUrl, '')}`;
    if (
      REQUESTS_WITH_LOADING.some((obj) => new RegExp(obj[0]).test(url) && obj[1] === req.method)
    ) {
      this.onStart();
    }
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            this.onEnd();
          }
        },
        () => {
          this.onEnd();
        },
      ),
    );
  }

  private onStart(): void {
    this.httpLoaderService.onRequestStart();
  }

  private onEnd(): void {
    this.httpLoaderService.onRequestEnd();
  }
}
