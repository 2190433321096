export interface GeneralListingInterface {
  name: string;
  description: string;
  price: number;
  higher_offer_price: number;
  address: ListingAddressInterface;
  hasUnreadMessage?: boolean;
  bedroom_amount: number;
  bathroom_amount: number;
  lot_size: number;
  repair_price: number;
  is_liked_by_user: boolean;
  ARV_price: number;
  bids_notifications: boolean;
  comparison: ListingComparisonInterface;
  likes: number;
  status: DealStatusEnum;
  photo?: GalleryMediaInterface[];
  video_pak?: GalleryMediaInterface[];
  id: number;
  created_at: string;
  seller: ListingOwner;
  options?: GoogleMapsOptionsInterface;
  is_user_has_offer?: number;
}

export interface ListingInterface extends GeneralListingInterface {
  similar_listings: GeneralListingInterface[];
}

export interface GoogleMapsOptionsInterface {
  className: string;
  color: string;
  fontWeight: string;
  text: string;
  fontFamily: string;
  fontSize: string;
}

export interface ListingAddressInterface {
  readable_address: string;
  city?: string;
  point?: ListingPointInterface;
}

export interface ListingPointInterface {
  x: number;
  y: number;
  srid: number;
}

export interface ListingAndOffers {
  listing: ListingInterface;
  offers: OfferInterface[];
}

export interface ListingComparisonInterface {
  photo: string;
  price: number;
  bedroom_amount: number;
  address: ListingAddressInterface;
  bathroom_amount: number;
  date_sold: string;
  id?: number;
}

export enum DealStatusEnum {
  open = 'open',
  closed = 'closed',
  negotiation = 'negotiation',
}

export interface GalleryMediaInterface {
  image?: string;
  video?: string;
  id?: number;
  preview_image?: string;
}

export interface ListingOwner {
  email: string;
  first_name: string;
  id: number;
  is_verified: boolean;
  last_login: string;
  last_name: string;
  photo: string;
  rating: number;
}

export interface OfferInterface {
  buyer: OfferMember;
  id: number;
  listing: ListingInfo;
  price: string;
  is_buyer_rate_seller: string;
  is_seller_rate_buyer: string;
  hasUnreadMessage?: boolean;
  buyer_last_message_stamp: string;
  seller_last_message_stamp: string;
  status: OfferStatusEnum;
  status_nda: OfferNdaStatusEnum;
  created_at: string;
}

export enum OfferStatusEnum {
  conversation = 'conversation',
  open = 'open',
  offer_closed = 'offer_closed',
  offer_rejected = 'offer_rejected',
  deal_closed = 'deal_closed',
  deal_rejected = 'deal_rejected',
}

export enum OfferNdaStatusEnum {
  accepted = 'accepted',
  rejected = 'rejected',
  nda_shown = 'NDA_shown',
  nda_not_shown = 'NDA_not_shown',
}

export interface UnreadMessageInterface {
  [item: number]: {
    hasNewMessage: boolean;
    message: MessageInterface;
  };
}

export enum MessageStatus {
  Created,
  Sent,
  Readed,
}

export interface MessageInterface {
  channel: string;
  message: string;
  date?: Date;
  status: MessageStatus;
  messageType?: string;
  userMetadata?: {
    user: string;
  };
  timetoken?: string;
  uuid: string;
}

export interface ListingInfo {
  ARV_price: string;
  bathroom_amount: number;
  bedroom_amount: number;
  higher_offer_price: number;
  id: number;
  lot_size: number;
  seller: OfferMember;
  repair_price: string;
  description: string;
  status: string;
  price: number;
  photo: GalleryMediaInterface[];
  video_pak: GalleryMediaInterface[];
  address: ListingAddressInterface;
}

export interface OfferMember {
  phone_number: string;
  email: string;
  first_name: string;
  id: number;
  is_verified: boolean;
  last_login: string;
  last_name: string;
  photo: string;
  rating: number;
}

export interface TimeStamps {
  buyer_last_message_stamp?: string;
  seller_last_message_stamp?: string;
}

export interface OfferStatuses {
  status: OfferStatusEnum;
  status_nda?: OfferNdaStatusEnum;
}

export interface PopularCityInterface {
  name: string;
  city: PointInterface;
}

export interface PointInterface {
  x: string;
  y: string;
}
