<div *ngIf="!isInvestor" class="post-view__seller">
  <div class="d-flex mobile-padding">
    <div class="d-flex w-50" *ngIf="mobileMode">
      <img
        [src]="offer.buyer.photo || '../../../images/icons/avatar.png'"
        alt=""
        class="post-view__buyers-photo"
      />
      <div class="post-view__buyers-text">
        <div class="post-view__seller-title">PROPERTY BUYER</div>
        <div class="post-view__buyers-name buyer-name">
          {{ offer.buyer.first_name }} {{ offer.buyer.last_name }}
        </div>
        <div class="post-view__buyers-rating">
          {{ offer.buyer.rating || 0 | number: '1.1-1' }} Rating
        </div>
      </div>
    </div>
    <div class="post-view__seller-text w-auto">
      <div class="post-view__seller-title">STATUS</div>
      <ng-container [ngSwitch]="offer.listing.status">
        <span *ngSwitchCase="'negotiation'" class="post-view__seller-name">Negotiation Stage</span>
        <span *ngSwitchCase="'open'" class="post-view__seller-name">Open Stage</span>
        <span *ngSwitchCase="'closed'" class="post-view__seller-name">Closed Stage</span>
      </ng-container>
    </div>
  </div>
  <div class="post-view__table w-100 mb-0" *ngIf="mobileMode">
    <img class="post-view__table-img" [src]="getSource(offer?.listing)" alt="" />
    <div class="post-view__table-info">
      <div class="post-view__table-price">${{ offer?.listing.higher_offer_price }}</div>
      <div class="post-view__table-description">
        <span class="post-view__table-description-type"
          >{{ offer?.listing.bedroom_amount }} BED</span
        >
        <span class="post-view__table-description-type"
          >{{ offer?.listing.bathroom_amount }} BATH</span
        >
        <span class="post-view__table-description-type">{{ offer?.listing.lot_size }} SQFT</span>
        <a [routerLink]="'/listing/' + offer.listing.id" class="post-view__table-link">
          SEE LISTING
        </a>
      </div>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="offerCurrentStatus; context: offer"></ng-container>
  <div *ngIf="!mobileMode" class="post-view__seller-buttons">
    <a [routerLink]="'/edit-listing/' + offer.listing.id" class="post-view__edit">EDIT LISTING</a>
    <a class="post-view__delete" (click)="deleteListing(offer.listing.id)">DELETE LISTING</a>
  </div>
</div>
<div *ngIf="isInvestor" class="post-view__seller">
  <div class="post-view__seller-block status" *ngIf="!mobileMode">
    <div class="post-view__seller-text">
      <div class="post-view__seller-title">STATUS</div>
      <span class="post-view__seller-name text-capitalize">{{ offer.listing.status }}</span>
    </div>
  </div>
  <ng-container *ngIf="!mobileMode">
    <ng-container *ngTemplateOutlet="offerCurrentStatus; context: offer"></ng-container>
  </ng-container>
  <div class="d-flex">
    <div class="w-50 post-view__seller-block">
      <img
        alt=""
        [ngStyle]="{ visibility: offer.listing.seller.photo }"
        [src]="offer.listing.seller.photo || '../../../images/icons/avatar.png'"
        class="post-view__seller-photo"
      />
      <div class="post-view__seller-text">
        <div class="post-view__seller-title">PROPERTY SELLER</div>
        <div class="post-view__seller-name">
          {{ offer.listing.seller.first_name }} {{ offer.listing.seller.last_name }}
        </div>
        <div class="post-view__seller-rating">
          {{ offer.listing.seller.rating || 0 | number: '1.1-1' }} Rating
        </div>
      </div>
    </div>
    <div class="post-view__seller-block mobile-status w-auto" *ngIf="mobileMode">
      <div class="post-view__seller-text">
        <div class="post-view__seller-title">STATUS</div>
        <span class="post-view__seller-name text-capitalize">{{ offer.listing.status }}</span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="mobileMode">
    <ng-container *ngTemplateOutlet="offerCurrentStatus; context: offer"></ng-container>
  </ng-container>
</div>
<div class="post-view__table" *ngIf="!mobileMode">
  <img class="post-view__table-img" [src]="getSource(offer?.listing)" alt="" />
  <div class="post-view__table-info">
    <div class="post-view__table-price">${{ offer?.listing.higher_offer_price }}</div>
    <div class="post-view__table-description">
      <span class="post-view__table-description-type">{{ offer?.listing.bedroom_amount }} BED</span>
      <span class="post-view__table-description-type"
        >{{ offer?.listing.bathroom_amount }} BATH</span
      >
      <span class="post-view__table-description-type">{{ offer?.listing.lot_size }} SQFT</span>
    </div>
  </div>
  <a [routerLink]="'/listing/' + offer.listing.id" class="post-view__table-link">SEE LISTING </a>
</div>
<hr class="post-view__hr" />
<div class="post-view__chat-wrapper">
  <div class="post-view__chat-title">
    CONTACT INFORMATION AND PROPERTY ADDRESS WILL BE DISPLAYED ONCE OFFER HAS BEEN ACCEPTED, THIS IS
    ALSO A NON-BINDING AGREEMENT.
  </div>
  <div class="post-view__chat-box">
    <div #messageArray class="post-view__chat-list hide-scrollbar" [scrollTop]="scrolltop">
      <div
        *ngFor="let message of messages; trackBy: trackByFn"
        [ngClass]="{
          'message-out': message.uuid == user.id,
          'message-in': message.uuid != user.id
        }"
        class="message-out"
      >
        <div class="message-body">
          <div class="message-text">{{ message.message }}</div>
          <div
            *ngIf="message.uuid == user.id"
            [ngClass]="{
              'message-double-check': message.status > 0,
              readed: message.status > 1
            }"
            class="message-double-check readed"
          ></div>
          <div class="message-time">{{ message.date | pubnubDate }}</div>
        </div>
      </div>
    </div>
    <ng-template #offerCurrentStatus let-price="price" let-status="status">
      <ng-container *ngIf="!isInvestor">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="'offer_closed'">
            <div class="message-card open-offer-closed">
              <div class="title">Open Offer Closed</div>
              <div class="text">
                Someone made a higher bid.
                <br />
                Last Offer {{ offer.price }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'offer_rejected'">
            <div class="message-card offer-rejected">
              <div class="title">Offer Rejected</div>
              <div class="text">Last Offer {{ offer.price }}</div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'deal_closed'">
            <div class="message-card deal-closed">
              <div class="title">Deal Closed</div>
              <div class="text">Last Offer {{ offer.price }}</div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'deal_rejected'">
            <div class="message-card deal-rejected">
              <div class="title">Deal Rejected</div>
              <div class="text">You have decided to close the deal with another investor.</div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'open'">
            <div class="message-card current-offer">
              <div class="price">{{ offer.price }}</div>
              <div class="text">Current Offer</div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isInvestor">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="'offer_closed'">
            <div class="message-card open-offer-closed">
              <div class="title">Open Offer Closed</div>
              <div class="text">
                Someone made a higher bid than you, and therefor the listing has moved out of Open f
                Offer.
                <br />
                Your Last Offer {{ offer.price }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'offer_rejected'">
            <div class="message-card offer-rejected">
              <div class="title">Offer Rejected</div>
              <div class="text">
                Your initial offer has been rejected, you may try to bid higher to still win it.
                <br />
                Your Last Offer {{ offer.price }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'deal_closed'">
            <div class="message-card deal-closed">
              <div class="title">Deal Closed</div>
              <div class="text">
                Congratulations, the seller has accepted your offer and closed the deal with you.
                <br />
                Your Last Offer {{ offer.price }}
              </div>
              <div class="d-flex flex-row mt-4" *ngIf="offer.listing.status === 'closed'">
                <div class="col-6 border-r">
                  <h5>Seller</h5>
                  {{ offer.listing.seller.first_name }} {{ offer.listing.seller.last_name }}
                  <br />
                  {{ offer.listing.seller.phone_number }}
                </div>
                <div class="col-6">
                  <h5>Address</h5>
                  {{ offer.listing.address.readable_address }}
                  <br />
                  {{ offer.listing.address.city }}
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'deal_rejected'">
            <div class="message-card deal-rejected">
              <div class="title">Deal Rejected</div>
              <div class="text">
                The seller has decided to close the deal with another investor.
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'open'">
            <div class="message-card current-offer">
              <div class="price">{{ offer.price }}</div>
              <div class="text">Your Current Offer</div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
    <label for="message" class="post-view__chat-message">
      <input
        [(ngModel)]="message"
        (keyup.enter)="sendMessage()"
        type="text"
        id="message"
        placeholder="Enter Your Message"
      />
      <button (click)="sendMessage()"></button>
    </label>
  </div>
  <hr class="post-view__hr" />
  <ng-container *ngIf="isMakeOfferBtnDisplayed()">
    <button class="post-view__btn btn make-new-offer" (click)="makeNewOffer()">
      Make New Offer
    </button>
  </ng-container>
  <div
    *ngIf="!isInvestor && offer.status !== 'conversation' && offer.status !== 'offer_rejected'"
    class="post-view__buttons"
  >
    <ng-container [ngSwitch]="offer.listing.status">
      <ng-container *ngSwitchCase="'negotiation'">
        <button
          class="post-view__buttons-btn btn empty"
          (click)="updateOfferStatus('offer_rejected')"
        >
          Deny Offer
        </button>
        <button class="post-view__buttons-btn btn empty" (click)="makeNewOffer()">
          Counter-Offer
        </button>
        <button
          class="post-view__buttons-btn btn primary"
          (click)="updateOfferStatus('deal_closed', 'NDA_shown')"
        >
          Accept Offer
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'open'">
        <ng-container *ngIf="offer.status_nda === 'accepted'">
          <button class="post-view__buttons-btn btn empty" (click)="updateListingStatus('closed')">
            Close Deal
          </button>
        </ng-container>
        <button
          class="post-view__buttons-btn btn primary"
          (click)="updateListingStatus('negotiation')"
        >
          Re-Open Deal
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
