import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../user.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { PubnubService } from "../../my-offers/pubnub/pubnub.service";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: NgForm;

  @ViewChild('form') currentForm: NgForm;

  constructor(
    private headerFooterStateService: HeaderFooterStateService,
    private userService: UserService,
    private pubnubService: PubnubService,
    private router: Router,
  ) {
    if (this.userService.isAuthenticated()) {
      if (this.userService.isVerified()) {
        this.headerFooterStateService.setView('main');
        this.router.navigate(['deals']);
      } else {
        this.userService.logout();
      }
    }
  }

  ngOnInit(): void {
    this.headerFooterStateService.setView('auth');
  }

  signInWithGoogle(): void {
    this.userService.redirectToGoogleAuth();
  }

  signInWithFB(): void {
    this.userService.redirectToFacebookAuth();
  }

  login(form: { email: string; password: string }): void {
    this.userService.login(form.email, form.password).subscribe(
      (user) => {
        this.userService.setUser(user.data_user);
        this.userService.setAuthToken(user.token);
        if (this.userService.isVerified()) {
          this.headerFooterStateService.setView('main');
          this.router.navigate(['deals']);
        } else {
          this.router.navigate(['signup']);
        }
        this.pubnubService.start(user.data_user.id);
      },
      (error) => {
        if (error instanceof HttpErrorResponse && error.status === 400) {
          this.currentForm.control.setErrors({ responseError: true });
        }
      },
    );
  }
}
