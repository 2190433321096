import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type HeaderFooterStateType =
  | 'auth'
  | 'main'
  | 'auth-black'
  | 'additional-footer'
  | 'unauthorized'
  | 'mobile-hidden'
  | 'noFooter';

@Injectable({
  providedIn: 'root',
})
export class HeaderFooterStateService {
  private view$: BehaviorSubject<HeaderFooterStateType> = new BehaviorSubject<HeaderFooterStateType>(
    'unauthorized',
  );

  setView(view: HeaderFooterStateType): void {
    this.view$.next(view);
  }

  getView$(): BehaviorSubject<HeaderFooterStateType> {
    return this.view$;
  }
}
