import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoDataTypeConverter',
})
export class VideoDataTypeConverterPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace('video/quicktime', 'video/mp4');
  }
}
