<div class="modal" id="popup">
  <div id="welcome-to-dealup" class="modal__content">
    <p class="modal__protip" *ngIf="data.showProtip">PROTIP</p>
    <h3 class="modal__title">{{ data.title }}</h3>
    <p class="modal__text">{{ data.text }}</p>
    <p class="modal__sub-text">{{ data.subText }}</p>
    <button type="submit" class="modal__button submit btn" (click)="dialogRef.close()">
      {{ data.buttonText }}
    </button>
    <span class="close close-popup" (click)="dialogRef.close()"></span>
  </div>
</div>
