<div class="d-flex flex-column align-items-center justify-content-between h-100">
  <div class="d-flex flex-column justify-content-center mt-lg-5 mt-md-3 mt-1 pb-2">
    <h4 class="text-center">Non-Disclosure Agreement</h4>
    <p class="text-center pt-3">By Proceeding you agree with this NDA.</p>
  </div> 
  <p class="px-lg-5 px-md-3 px-1 pb-3">onsectetur adipiscing elit. Nam porttitor sapien et massa vulputate, non consectetur libero tincidunt. Curabitur viverra
  iaculis consequat. Pellentesque est ligula, interdum eget lectus non, consectetur tristique urna. Quisque sed luctus
  nisi. Donec tincidunt aliquet sapien. Pellentesque et nulla vitae quam feugiat consequat et sit amet lectus.
  Pellentesque accumsan, mi id pellentesqufaucibus, venenatis neque vel, suscipit justo. Vivamus sit amet ligula purus.
  </p>
  <div class="d-flex flex-column justify-content-center align-items-center w-100">
    <button type="submit" class="submit btn col-lg-6 col-md-8 col-12" (click)="dialogRef.close(true)">I Agree</button>
    <button type="submit" class="default btn col-lg-6 col-md-8 col-12" (click)="dialogRef.close(false)">I Disagree</button>
  </div>
</div>