<div class="modal__content create-popup" *ngIf="data.type === 'listing'">
  <div>
    <h3 class="modal__title">Listing <span class="green">Successfully</span> Posted</h3>
    <p class="modal__text">
      You can keep track of it under
      <a routerLink="/my-listings" class="link" (click)="dialogRef.close()">my listings</a>
    </p>
  </div>
  <button class="modal__button btn submit" (click)="dialogRef.close()">See Live Listing</button>
</div>
<div class="modal__content create-popup" *ngIf="data.type === 'offer'">
  <div>
    <h3 class="modal__title">Offer <span class="green">Successfully</span> Placed</h3>
    <p class="modal__text">
      You can view it under
      <a routerLink="/my-offers" class="link" (click)="dialogRef.close()">my offers</a>
    </p>
  </div>
  <p class="modal__warning" *ngIf="data.warningLabel">
    Please notice that the Offer you entered is lower that Current Price for this Listing
  </p>
  <button class="modal__button btn submit" (click)="dialogRef.close()">Close</button>
</div>
<div class="modal__content create-popup" *ngIf="data.type === 'message'">
  <div>
    <h3 class="modal__title">Message <span class="green">Successfully</span> Sent</h3>
    <p class="modal__text">
      You can view it under
      <a class="link" (click)="dialogRef.close(true)">my messages</a>
    </p>
  </div>
  <button class="modal__button btn submit" (click)="dialogRef.close()">Close</button>
</div>
