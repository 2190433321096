export const validFileFormats = ['.jpeg', 'jpg', 'png', 'mp4', 'mov'];

export const validFileTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'video/mp4',
  'video/quicktime',
];

export const typeFilePattern = /(?:\.([^.]+))?$/;
