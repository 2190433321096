<main class="create-listing">
  <div class="breadcrumbs" itemscope="" itemtype="http://schema.org/BreadcrumbList">
    <ul class="breadcrumbs__list ul-reset">
      <li
        class="breadcrumbs__item"
        itemscope=""
        property="itemListElement"
        itemtype="http://schema.org/ListItem"
      >
        <a href="#" itemprop="item" class="breadcrumbs__link" routerLink="/my-listings">
          <span itemprop="name">BACK TO MY LISTINGS</span>
        </a>
        <meta itemprop="position" content="1" />
      </li>
    </ul>
  </div>
  <section class="create-listing__container">
    <h1 class="create-listing__title" *ngIf="isAddMode">Create Listing</h1>
    <div class="create-listing__title delete-title" *ngIf="!isAddMode">
      <h1>Edit Listing</h1>
      <a class="delete" (click)="deleteListing()">DELETE LISTING</a>
    </div>
    <form
      class="create-listing__form"
      #form="ngForm"
      (ngSubmit)="form.valid && validMediaLength() && next()"
      id="ngForm"
    >
      <div class="create-left-block">
        <div class="title-row">
          <span class="title-row__caption">IMAGES</span>
          <p class="title-row__text">IN ORDER TO POST YOU NEED AT LEAST 3 IMAGES.</p>
        </div>
        <gallery-container [items]="medias" class="w-100"></gallery-container>
        <div class="error w-100" *ngIf="form.submitted && (mediaLength$ | async) < 3">
          Please upload at least 3 medias
        </div>
      </div>
      <div class="create-right-block">
        <div class="title-row">
          <span class="title-row__caption">LISTING DETAILS</span>
          <p class="title-row__text">ALL FIELDS MANDATORY</p>
        </div>
        <div class="create-listing__field">
          <label class="label" for="postName">Post Name</label>
          <input
            type="text"
            name="postName"
            [(ngModel)]="listing.name"
            #postName="ngModel"
            maxlength="256"
            required
            class="create-listing__input input"
            placeholder="Enter Post Name"
          />
          <div
            *ngIf="
              (postName.invalid && (postName.dirty || postName.touched)) ||
              (postName.invalid && form.submitted)
            "
          >
            <div class="error" *ngIf="postName.errors.required">Please enter post name</div>
            <div class="error" *ngIf="postName.errors.maxlength">
              You have reached your maximum limit of characters allowed
            </div>
          </div>
        </div>
        <hr class="hr-line" />
        <div class="create-listing__field">
          <label class="label" for="description">Description</label>
          <textarea
            type="text"
            class="create-listing__textarea input"
            rows="1"
            id="description"
            name="description"
            [(ngModel)]="listing.description"
            #description="ngModel"
            maxlength="1024"
            required
            style="max-height: 260px"
            placeholder="Write a brief description about the property."
            autosize
          ></textarea>
          <div
            *ngIf="
              (description.invalid && (description.dirty || description.touched)) ||
              (description.invalid && form.submitted)
            "
          >
            <div class="error" *ngIf="description.errors.required">
              Please enter brief description
            </div>
            <div class="error" *ngIf="description.errors.maxlength">
              You have reached your maximum limit of characters allowed
            </div>
          </div>
        </div>
        <div class="create-listing__field">
          <label class="label" for="propertyPrice">Price</label>
          <input
            type="number"
            class="create-listing__input input"
            name="propertyPrice"
            ngModel
            required
            #propertyPrice="ngModel"
            [(ngModel)]="listing.price"
            placeholder="$ - Enter Property Price"
          />
          <div
            class="error"
            *ngIf="
              (propertyPrice.invalid &&
                (propertyPrice.dirty || propertyPrice.touched) &&
                propertyPrice.errors.required) ||
              (propertyPrice.invalid && form.submitted)
            "
          >
            Please enter property price
          </div>
        </div>
        <hr class="hr-line" />
        <div class="create-listing__field">
          <label class="label" for="propertyAddress">Address</label>
          <input
            ngx-google-places-autocomplete
            class="sign-input input"
            [options]="googlePlacesOptions"
            placeholder="Example: 7 1st Ave, San Diego, CA 92187, USA"
            name="propertyAddress"
            required
            #propertyAddress="ngModel"
            [(ngModel)]="listing.address.readable_address"
            (onAddressChange)="handleAddressChange($event)"
          />
          <div
            class="error"
            *ngIf="
              (propertyAddress.invalid &&
                (propertyAddress.dirty || propertyAddress.touched) &&
                propertyAddress.errors.required) ||
              (propertyAddress.invalid && form.submitted)
            "
          >
            Please enter property address
          </div>
          <div class="error" *ngIf="adressFieldValidator(propertyAddress.value)">
            Address format should be "1000 1st Ave, San Diego, CA 92187, USA"
          </div>
        </div>
        <div class="create-listing__field short">
          <label class="label" for="bedroomAmount">Bedroom Amount</label>
          <input
            type="number"
            name="bedroomAmount"
            [(ngModel)]="listing.bedroom_amount"
            #bedroomAmount="ngModel"
            required
            class="create-listing__input input"
            placeholder="Bedroom Amount"
          />
          <div
            class="error"
            *ngIf="
              (bedroomAmount.invalid &&
                (bedroomAmount.dirty || bedroomAmount.touched) &&
                bedroomAmount.errors.required) ||
              (bedroomAmount.invalid && form.submitted)
            "
          >
            Please enter bedroom amount
          </div>
        </div>
        <div class="create-listing__field short short-u">
          <label class="label" for="bathroomAmount">Bathroom Amount</label>
          <input
            type="number"
            name="bathroomAmount"
            [(ngModel)]="listing.bathroom_amount"
            #bathroomAmount="ngModel"
            required
            class="create-listing__input input"
            placeholder="Bathroom Amount"
          />
          <div
            class="error"
            *ngIf="
              (bathroomAmount.invalid &&
                (bathroomAmount.dirty || bathroomAmount.touched) &&
                bathroomAmount.errors.required) ||
              (bathroomAmount.invalid && form.submitted)
            "
          >
            Please enter bathroom amount
          </div>
        </div>
        <div class="create-listing__field short short-d">
          <label class="label" for="lotSize">LOT Size</label>
          <input
            type="number"
            name="lotSize"
            [(ngModel)]="listing.lot_size"
            #lotSize="ngModel"
            required
            class="create-listing__input input"
            placeholder="Enter LOT size in SQFT"
          />
          <div
            class="error"
            *ngIf="
              (lotSize.invalid && (lotSize.dirty || lotSize.touched) && lotSize.errors.required) ||
              (lotSize.invalid && form.submitted)
            "
          >
            Please enter LOT size
          </div>
        </div>
        <div class="create-listing__field short short-d">
          <label class="label" for="repairCost">Estimated Repair Cost</label>
          <input
            type="number"
            name="repairCost"
            [(ngModel)]="listing.repair_price"
            #repairCost="ngModel"
            required
            class="create-listing__input input"
            placeholder="Estimated Repair Cost Value"
          />
          <div
            class="error"
            *ngIf="
              (repairCost.invalid &&
                (repairCost.dirty || repairCost.touched) &&
                repairCost.errors.required) ||
              (repairCost.invalid && form.submitted)
            "
          >
            Please enter estimated repair cost value
          </div>
        </div>
        <div class="create-listing__field">
          <label class="label" for="arvPrice">After Repair Value</label>
          <input
            type="number"
            name="arvPrice"
            [(ngModel)]="listing.ARV_price"
            #arvPrice="ngModel"
            required
            class="create-listing__input input"
            placeholder="$ - Enter ARV Price "
          />
          <div
            class="error"
            *ngIf="
              (arvPrice.invalid &&
                (arvPrice.dirty || arvPrice.touched) &&
                arvPrice.errors.required) ||
              (arvPrice.invalid && form.submitted)
            "
          >
            Please enter ARV price
          </div>
          <div class="check-help" data-text="Dealup verified ARV coming soon."></div>
        </div>
      </div>
    </form>
  </section>
</main>
<footer class="footer next-step">
  <div class="next-step__container">
    <div class="next-step__col">
      <div class="next-step__text">
        <div class="next-step__mini-text">EMAIL NOTIFICATION</div>
        <div class="next-step__info">Notify me when someone offers</div>
      </div>
      <label class="switch">
        <input type="checkbox" [(ngModel)]="listing.bids_notifications" />
        <span class="switch__slider round"></span>
      </label>
    </div>
    <div class="next-step__col">
      <a routerLink="/my-listings" class="next-step__back">Back</a>
      <button
        class="submit btn next-step__next"
        form="ngForm"
        [class.submit-disabled]="form.invalid || (mediaLength$ | async) < 3"
        type="submit"
      >
        Next
      </button>
    </div>
  </div>
</footer>
