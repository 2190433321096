import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { UserService } from '../user.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';

@Component({
  selector: 'password-recovery-container',
  templateUrl: './password-recovery-container.component.html',
  styleUrls: ['./password-recovery-container.component.scss'],
})
export class PasswordRecoveryContainerComponent {
  @ViewChild('emailForm') emailForm: NgForm;

  view: 'emailSent' | 'emailPaste' | 'newPassword' | 'passwordChanged';

  token: string;

  responseError = false;

  constructor(
    private headerFooterStateService: HeaderFooterStateService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.headerFooterStateService.setView('auth');
    this.view = 'emailPaste';
    if (this.route.snapshot.queryParams.token) {
      this.token = this.route.snapshot.queryParams.token;
      this.view = 'newPassword';
    }
  }

  emailRequest(email: string): void {
    this.responseError = false;
    this.userService.forgotPassword(email).subscribe(
      () => {
        this.view = 'emailSent';
      },
      (error) => {
        if (error.status === 400) {
          this.emailForm.control.setErrors({ responseError: true });
        }
      },
    );
  }

  changePassword(form: FormGroup): void {
    this.userService
      .changeForgottenPassword(this.token, form.value.newPassword, form.value.newPassword2)
      .subscribe(() => {
        this.view = 'passwordChanged';
      });
  }
}
