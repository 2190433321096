import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './user/login/login.component';
import { SignUpComponent } from './user/signup/sign-up.component';
import { MyAccountComponent } from './user/my-account/my-account.component';
import { DealsContainerComponent } from './deals/deals-container/deals-container.component';
import { AuthGuard } from './common/guards/auth-guard.service';
import { PasswordRecoveryContainerComponent } from './user/password-recovery-container/password-recovery-container.component';
import { FaqComponent } from './documents/faq/faq.component';
import { PrivacePolicyComponent } from './documents/privace-policy/privace-policy.component';
import { TermsOfServiceComponent } from './documents/terms-of-service/terms-of-service.component';
import { MyOffersComponent } from './my-offers/my-offers.component';
import { MyListingsComponent } from './listings/my-listings/my-listings.component';
import { AddEditListingContainerComponent } from './listings/add-edit-listing-container/add-edit-listing-container.component';
import { ListingPostComponent } from './listings/listing-post/listing-post.component';
import { OfferComponent } from './my-offers/offer/offer.component';
import { SharedListingsComponent } from './listings/shared-listings/shared-listings.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
  { path: 'signup', component: SignUpComponent },
  { path: 'deals', component: DealsContainerComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacePolicyComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'password-recovery', component: PasswordRecoveryContainerComponent },
  { path: 'my-offers', component: MyOffersComponent, canActivate: [AuthGuard] },
  { path: 'offer/:id', component: OfferComponent, canActivate: [AuthGuard] },
  {
    path: 'edit-listing/:id',
    component: AddEditListingContainerComponent,
    canActivate: [AuthGuard],
  },
  { path: 'create-listing', component: AddEditListingContainerComponent, canActivate: [AuthGuard] },
  { path: 'my-listings', component: MyListingsComponent, canActivate: [AuthGuard] },
  { path: 'listings/:id', component: SharedListingsComponent },
  { path: 'listing/:id', component: ListingPostComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/deals', pathMatch: 'full' },
  { path: '**', redirectTo: '/deals', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
