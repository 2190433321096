<div class="home">
  <section class="home__banner" #tutorial>
    <div class="home__slider">
      <div class="home__slider-container">
        <ng-container *ngFor="let data of carouselData; let i = index">
          <div class="slider-card" *ngIf="i === currentCarouselIndex">
            <div class="slider-card__left">
              <div class="slider-card__left-title">
                {{ data.header }}
              </div>
              <p class="slider-card__left-text">
                {{ data.text }}
              </p>
            </div>
            <div class="slider-card__right">
              <img imageLoader [src]="data.image" alt="" class="slider-card__img" />
              <div class="card__info" *ngIf="data.card">
                <div class="card__wrapper">
                  <div class="card__price">
                    {{ data.card.price.value }}
                    <span class="card__price-type">{{ data.card.price.type }}</span>
                  </div>
                  <div class="card__description">
                    <span
                      class="card__description-type"
                      *ngFor="let descriptionTypes of data.card.descriptionTypes"
                      >{{ descriptionTypes }}</span
                    >
                  </div>
                  <div class="card__address">{{ data.card.address }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <button class="home__slider-arrow prev" (click)="prevSlide()"></button>
      <button class="home__slider-arrow next" (click)="nextSlide()"></button>

      <span class="home__slider-close link" (click)="closeTutorial()">CLOSE TUTORIAL</span>

      <div class="home__slider-dot">
        <span
          class="dots"
          *ngFor="let data of carouselData; let index = index"
          [ngClass]="{ active: index === currentCarouselIndex }"
          (click)="changeSlide(index)"
        ></span>
      </div>
    </div>
  </section>
  <section class="home__header" *ngIf="!showTutorial">
    <p class="home__header-text">
      Buy and connect with investors and wholesalers to sell your properties.
    </p>
  </section>
  <section class="home__filter">
    <form class="filter">
      <cities-input
        [placeholder]="'Search for deal in Area'"
        [view]="'home'"
        [cities]="currentCities"
        (citiesChanged)="cityInputChanged($event)"
        class="filter__left"
      ></cities-input>
      <div class="filter__right">
        <div class="filter__select select input">
          <select
            class="price-filter input"
            [(ngModel)]="priceRange"
            (ngModelChange)="updateListings()"
            name="price-range"
          >
            <option [value]="null" selected>Price Range</option>
            <option [value]="'$0-$50000'">$0 - $50,000</option>
            <option [value]="'$50000-$100000'">$50,000 - $100,000</option>
            <option [value]="'$100000-$150000'">$100,000 - $150,000</option>
            <option [value]="'$150000-$200000'">$150,000 - $200,000</option>
            <option [value]="'$200000-$250000'">$200,000 - $250,000</option>
            <option [value]="'$250000-$300000'">$250,000 - $300,000</option>
            <option [value]="'$300000'">$300,000+</option>
          </select>
        </div>
        <div class="filter__select select input">
          <select
            class="sorting-filter input"
            [(ngModel)]="sorting"
            (ngModelChange)="updateListings()"
            name="price-sorting"
          >
            <option [value]="null" selected>Sorting</option>
            <option [value]="'newest_first'">Newest</option>
            <option [value]="'oldest_first'">Oldest</option>
            <option [value]="'high_to_low'">Price: High to low</option>
            <option [value]="'low_to_high'">Price: Low to high</option>
            <option [value]="'most_popular'">Most Popular</option>
          </select>
        </div>
      </div>
    </form>
  </section>
  <div class="home__wrapper">
    <section class="home__left">
      <div
        class="card"
        *ngFor="let listing of listings; trackBy: trackByFn"
        [routerLink]="'/listing/' + listing.id"
      >
        <img imageLoader [src]="getSource(listing)" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              $ {{ listing.price }} <span class="card__price-type">AVR</span>
            </div>
            <div class="card__description">
              <span class="card__description-type">{{ listing.bedroom_amount }} BED</span>
              <span class="card__description-type">{{ listing.bathroom_amount }} BATH</span>
              <span class="card__description-type">{{ listing.lot_size }} SQFT</span>
            </div>
            <div class="card__address">{{ listing.address.city }}</div>
          </div>
          <a [routerLink]="'/listing/' + listing.id" class="card__btn"></a>
        </div>
      </div>
    </section>
    <section class="home__right">
      <agm-map
        *ngIf="startCoords"
        class="home__map map"
        [latitude]="startCoords.x"
        [longitude]="startCoords.y"
        [zoom]="mapZoom"
        (zoomChange)="storeZoom($event)"
        [minZoom]="4"
        [disableDefaultUI]="true"
        (boundsChange)="changeCoords($event)"
        (idle)="updateListings()"
      >
        <ng-container *ngFor="let listing of listings; let index = index; trackBy: trackByFn">
          <agm-marker
            [iconUrl]="{
              url: getSource(listing),
              scaledSize: { height: 35, width: 35 },
              anchor: { x: 40, y: 20 },
              size: { height: 35, width: 85 }
            }"
            [latitude]="listing.address.point.x"
            [longitude]="listing.address.point.y"
            [label]="listing.options"
            (markerClick)="redirectToListing(listing)"
            [zIndex]="index + 10"
          ></agm-marker>
          <agm-circle
            [latitude]="listing.address.point.x"
            [longitude]="listing.address.point.y"
            [radius]="200"
            [circleDraggable]="false"
            [fillColor]="'#4268EC'"
            [fillOpacity]="0.25"
            [editable]="false"
          ></agm-circle>
        </ng-container>
      </agm-map>
    </section>
  </div>
</div>
