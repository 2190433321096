import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[autosize]',
})
export class AutosizeDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  @HostListener(':input')
  onInput() {
    this.resize();
  }

  ngOnInit() {
    if (this.elementRef.nativeElement.scrollHeight) {
      setTimeout(() => this.resize());
    }
  }

  resize() {
    this.elementRef.nativeElement.style.height = '0';
    this.elementRef.nativeElement.style.height = 'auto';
    this.elementRef.nativeElement.style.overflow = this.compareHeights(
      this.elementRef.nativeElement.scrollHeight,
      this.elementRef.nativeElement.style.maxHeight,
    )
      ? 'auto'
      : 'hidden';
    this.elementRef.nativeElement.style.height = `${this.elementRef.nativeElement.scrollHeight}px`;
  }

  compareHeights(height1: string, height2: string): boolean {
    if (height1 && height2) {
      const convertedHeight1 = Number(height1);
      const convertedHeight2 = Number(height2.slice(0, height2.length - 2));

      return convertedHeight1 > convertedHeight2;
    }

    return false;
  }
}
