import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OfferMember } from '../../../listings/listing.interface';

@Component({
  selector: 'rate-person-dialog',
  templateUrl: './rate-person-dialog.component.html',
  styleUrls: ['./rate-person-dialog.component.scss'],
})
export class RatePersonDialogComponent {
  rating: number;

  ratingValues = [1, 2, 3, 4, 5];

  constructor(
    public dialogRef: MatDialogRef<RatePersonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isInvestor: boolean; person: OfferMember },
  ) {}

  setRating(rating: number): void {
    this.rating = rating;
  }
}
