<ng-container *ngTemplateOutlet="sideBar; context: { show: showNav | async }"></ng-container>

<ng-template #sideBar let-show="show">
  <div [ngClass]="{ 'side-nav-bar-collapsed': !show }" class="side-nav-bar" #container>
    <div
      class="side-nav-bar-overlay"
      [ngStyle]="{ transition: 'background-color ' + 1 + 's, visibility ' + 1 + 's' }"
      [ngClass]="{ 'side-nav-bar-overlay-collapsed': !show }"
      (click)="closeNavBar($event)"
    ></div>

    <div class="side-nav-bar-menu-container" [ngStyle]="getSideNavBarStyle(show)">
      <div>
        <input type="checkbox" id="menu-btn" class="menu-btn" aria-label="open menu" />
        <label
          for="menu-btn"
          class="menu__burger side-nav-bar-close"
          (click)="closeNavBar($event)"
        ></label>
      </div>

      <div class="side-nav-bar-content-container">
        <nav class="header__menu menu--row menu" *ngIf="isLoggedIn">
          <ul class="menu__list ul-reset">
            <li class="menu__item">
              <a routerLink="/deals" class="menu__link" (click)="closeNavBar($event)">Deals</a>
            </li>
            <li class="menu__item d-flex">
              <a routerLink="/my-offers" class="menu__link" (click)="closeNavBar($event)">
                My Offers
              </a>
            </li>
            <li class="menu__item d-flex">
              <a routerLink="/my-listings" class="menu__link" (click)="closeNavBar($event)">
                My Listings
              </a>
            </li>
            <li class="menu__item">
              <a routerLink="/my-account" class="menu__link" (click)="closeNavBar($event)">
                Account
              </a>
            </li>
          </ul>
        </nav>
        <nav class="header__menu menu--row menu" *ngIf="!isLoggedIn">
          <ul class="menu__list ul-reset">
            <li class="menu__item">
              <a routerLink="/login" class="menu__link">Login or Sign Up Here</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</ng-template>
