export const environment = {
  production: true,
  apiUrl: 'https://dealup.emphasoft.com/api/',
  facebookClientId: '429273661485333',
  googleClientId: '821540941995-m71qs18k3c035avha1937anamnnjggvd.apps.googleusercontent.com',
  sentryDSN: 'https://ff2fe406fee74a9bbdc969310f5e4cd3@o118529.ingest.sentry.io/5682461',
  sentryEnvName: 'development',
  pubNubPublish: 'pub-c-fd17daa7-c454-435e-9eae-84e84500840d',
  pubNubSubscribe: 'sub-c-d92de8a6-7511-11eb-8517-a667e5017b39',
  googleApiKey: 'AIzaSyC4pDKbHg2oR6qHC91CiFMzXlIDm-M_kJw',
};
