import { Attribute, Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[validateEqual]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EqualValidator, multi: true }],
})
export class EqualValidator implements Validator {
  constructor(
    @Attribute('validateEqual') public validateEqual: string,
    @Attribute('reverse') public reverse: string,
  ) {}

  private get isReverse() {
    if (!this.reverse) return false;
    return this.reverse === 'true';
  }

  validate(c: AbstractControl): { [key: string]: any } {
    const e = c.root.get(this.validateEqual);

    if (e && c.value !== e.value && !this.isReverse) {
      return { compare: true };
    }

    if (e && c.value === e.value && this.isReverse) {
      delete e.errors.compare;
      if (!Object.keys(e.errors).length) {
        e.setErrors(null);
      }
    }

    if (e && c.value !== e.value && this.isReverse) {
      e.setErrors({ compare: true });
    }

    return null;
  }
}
