<main class="login">
  <section class="left-block">
    <ng-container [ngSwitch]="view">
      <ng-container *ngSwitchCase="'signUp'">
        <h2 class="left-block__text">
          Simply buy and sell your real estate <span class="blue">deals.</span>
        </h2>
        <div class="left-block__bg signup-1"></div>
      </ng-container>
      <ng-container *ngSwitchCase="'phone'">
        <h2 class="left-block__text">Wholesaling done <span class="blue">right.</span></h2>
        <div class="left-block__bg signup-2"></div>
      </ng-container>
      <ng-container *ngSwitchCase="'code'">
        <h2 class="left-block__text">
          Real Estate Investing the <span class="blue">easy</span> way.
        </h2>
        <div class="left-block__bg signup-3"></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <h2 class="left-block__text">
          Simply buy and sell your real estate <span class="blue">deals.</span>
        </h2>
        <div class="left-block__bg signup-1"></div>
      </ng-container>
    </ng-container>
  </section>
  <section class="login-container">
    <div class="login-wrapper">
      <ng-container [ngSwitch]="view">
        <ng-container *ngSwitchCase="'phone'">
          <h1 class="login-title">Phone Verification</h1>
          <p class="login-subtitle">
            Please enter your phone number so we can text you a code and secure your account.
          </p>
          <form [formGroup]="phoneForm" action="" class="sign-form">
            <div class="sign-field">
              <input
                type="tel"
                class="sign-input input"
                ng2TelInput
                placeholder="Enter Phone Number"
                name="phone"
                phoneMask
                formControlName="phoneNumber"
                (countryChange)="codeCountryInit($event)"
              />
              <div
                class="sign-error"
                *ngIf="
                  phoneForm.controls['phoneNumber'].invalid &&
                  (phoneForm.controls['phoneNumber'].dirty ||
                    phoneForm.controls['phoneNumber'].touched ||
                    confirmClicked)
                "
              >
                Please enter a valid phone number
              </div>
            </div>
            <button
              [class.submit-disabled]="phoneForm.invalid"
              (click)="sendPhone()"
              type="submit"
              class="submit btn"
            >
              Confirm
            </button>
          </form>
          <p class="verification-info">
            Your phone number will not be shared with others. Message and data rate may apply.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'code'">
          <h1 class="login-title">Verification Code</h1>
          <p class="login-subtitle">
            We’ve sent you a verification code, please enter it here so we can secure your account.
          </p>
          <form [formGroup]="validationForm" action="" class="sign-form">
            <div class="sign-field">
              <input
                formControlName="code"
                type="tel"
                class="sign-input input"
                placeholder="Enter Verification Code"
              />
              <div
                *ngIf="
                  validationForm.controls['code'].invalid &&
                  (validationForm.controls['code'].dirty ||
                    validationForm.controls['code'].touched ||
                    confirmClicked)
                "
                class="sign-error"
              >
                Please enter a code
              </div>
              <div *ngIf="wrongCode" class="sign-error">Your code is not right.</div>
            </div>
            <button
              [class.submit-disabled]="validationForm.invalid"
              (click)="sendCode()"
              type="submit"
              class="submit btn"
            >
              Confirm
            </button>
          </form>
          <p class="verification-info">
            Your phone number will not be shared with others. Message and data rate may apply.
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'signUp'">
          <h1 class="login-title">Signup</h1>
          <button class="fb-signup btn" (click)="signInWithFB()">Signup using Facebook</button>
          <button class="google-signup btn" (click)="signInWithGoogle()">
            Signup using Google
          </button>
          <div class="hr-line-text">or</div>
          <form
            [formGroup]="signUpForm"
            (ngSubmit)="signUpClick()"
            id="signup"
            action=""
            class="sign-form"
          >
            <div class="sign-field">
              <input
                formControlName="firstName"
                type="text"
                class="sign-input input"
                placeholder="First Name"
              />
              <div
                class="sign-error"
                *ngIf="
                  signUpForm.controls['firstName'].invalid &&
                  (signUpForm.controls['firstName'].dirty ||
                    signUpForm.controls['firstName'].touched ||
                    confirmClicked)
                "
              >
                Please enter your First Name
              </div>
            </div>
            <div class="sign-field">
              <input
                formControlName="lastName"
                type="text"
                class="sign-input input"
                placeholder="Last Name"
              />
              <div
                class="sign-error"
                *ngIf="
                  signUpForm.controls['lastName'].invalid &&
                  (signUpForm.controls['lastName'].dirty ||
                    signUpForm.controls['lastName'].touched ||
                    confirmClicked)
                "
              >
                Please enter your Last Name
              </div>
            </div>
            <div class="sign-field">
              <input
                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                formControlName="email"
                type="email"
                class="sign-input input"
                placeholder="Email Address"
              />
              <div
                class="sign-error"
                *ngIf="
                  signUpForm.controls['email'].invalid &&
                  (signUpForm.controls['email'].dirty ||
                    signUpForm.controls['email'].touched ||
                    confirmClicked)
                "
              >
                Please enter a valid email address
              </div>
            </div>
            <div class="sign-field">
              <input
                formControlName="password"
                type="password"
                class="sign-input input"
                placeholder="Password"
              />
              <div
                class="sign-error"
                *ngIf="
                  signUpForm.controls['password'].invalid &&
                  (signUpForm.controls['password'].dirty ||
                    signUpForm.controls['password'].touched ||
                    confirmClicked)
                "
              >
                The password must contain at least 8 characters
              </div>
            </div>
            <div class="sign-field">
              <input
                formControlName="confirmPassword"
                type="password"
                class="sign-input input"
                placeholder="Confirm Password"
              />
              <div
                class="sign-error"
                *ngIf="
                  signUpForm.hasError('mismatch') &&
                  (signUpForm.controls['confirmPassword'].dirty ||
                    signUpForm.controls['confirmPassword'].touched ||
                    confirmClicked)
                "
              >
                Passwords are not equal
              </div>
            </div>
            <div class="sign-error" *ngIf="error">{{ error.text }}</div>
            <button [class.submit-disabled]="signUpForm.invalid" class="submit btn">Confirm</button>
            <p class="sign-help">Already have an account? <a routerLink="/login">Login</a></p>
          </form>
          <p class="privacy">
            By signing up you agree to <a routerLink="/privacy-policy">Privacy Policy</a> and
            <a routerLink="/terms-of-service">Terms of Service.</a>
          </p>
        </ng-container>
        <ng-container *ngSwitchCase="'buy-box'">
          <h1 class="login-title">Buy Box</h1>
          <p class="login-subtitle">
            If you’re not an investor looking to invest, you can skip this step.
          </p>
          <form action="" class="sign-form">
            <div class="sign-field">
              <cities-input
                (citiesChanged)="updateCities($event)"
                placeholder="Enter the cities you wish the see deals in."
              ></cities-input>
            </div>
            <button
              [class.submit-disabled]="cities.length < 1"
              (click)="submit()"
              type="submit"
              class="submit btn"
            >
              Confirm
            </button>
            <a class="skip-step" (click)="goToDeals()">SKIP THIS STEP</a>
          </form>
        </ng-container>
      </ng-container>
    </div>
  </section>
</main>
