import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(private toasters: ToastrService) {}

  // Handling HTTP Errors using Toaster
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string;
    if (err.error?.message) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = err.error.message;
    } else if (err.error?.non_field_errors) {
      errorMessage = err.error.non_field_errors[0];
    } else if (err.error?.email) {
      errorMessage = err.error.email.email;
    } else {
      // The backend returned an unsuccessful response code.
      switch (err.status) {
        case 400:
          if (err.error?.error === "Email wasn't provided by oauth provider") {
            errorMessage =
              'Your FB profile does not have email address. Please use Registration with email address. Thank you.';
          } else if (err.error[0] === 'Address not found or openstreetmap is unavailable') {
            errorMessage = 'Entered address is not valid';
          } else {
            errorMessage = null;
          }
          break;
        case 401:
          errorMessage = 'You need to log in to do this action.';
          break;
        case 403:
          errorMessage = "You don't have permission to access the requested resource.";
          break;
        case 404:
          errorMessage = 'The requested resource does not exist.';
          break;
        case 412:
          errorMessage = 'Precondition Failed.';
          break;
        case 413:
          errorMessage = 'Selected media files are too large, you can upload 100 Mb total.';
          break;
        case 422:
          errorMessage = 'Validation Error!';
          break;
        case 500:
          errorMessage = 'Internal Server Error.';
          break;
        case 503:
          errorMessage = 'The requested service is not available.';
          break;
        default:
          errorMessage = null;
      }
    }
    if (errorMessage) {
      this.toasters.error(errorMessage);
    }
  }
}
