import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { GalleryMediaInterface } from 'src/app/listings/listing.interface';
import { HttpLoaderService } from '../interceptor/http-loader.service';

@Component({
  selector: 'media-container',
  templateUrl: './media-container.component.html',
  styleUrls: ['./media-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaContainerComponent implements AfterViewInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  @ViewChild('image') image: ElementRef;

  @Input() media: GalleryMediaInterface;

  @Output() mediaWithPreview = new EventEmitter<GalleryMediaInterface>();

  constructor(private loaderService: HttpLoaderService) {}

  ngAfterViewInit(): void {
    if (this.media.image) {
      this.image.nativeElement.onload = (): void => {
        this.loaderService.onRequestEnd();
      };
    } else {
      this.videoPlayer.nativeElement.currentTime = 1;
      this.videoPlayer.nativeElement.oncanplay = (): void => {
        if (!this.media.preview_image) {
          const canvas = document.createElement('canvas');
          const image = new Image();
          canvas.width = this.videoPlayer.nativeElement.videoWidth * 1;
          canvas.height = this.videoPlayer.nativeElement.videoHeight * 1;
          canvas
            .getContext('2d')
            .drawImage(this.videoPlayer.nativeElement, 0, 0, canvas.width, canvas.height);
          image.src = canvas.toDataURL();
          this.mediaWithPreview.emit({ id: this.media.id, preview_image: image.src });
        }
        this.loaderService.onRequestEnd();
      };
    }
  }
}
