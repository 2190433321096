import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface PostedDialogInterface {
  type: 'listing' | 'message' | 'offer';
  warningLabel: boolean;
}

@Component({
  selector: 'listing-posted-dialog',
  templateUrl: './posted-dialog.component.html',
  styleUrls: ['./posted-dialog.component.scss'],
})
export class PostedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PostedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PostedDialogInterface,
  ) {}
}
