import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../../user/user.service';
import { HeaderFooterStateService } from '../services/header-footer-state.service';
import { PubnubService } from '../../my-offers/pubnub/pubnub.service';

export const ALLOWED_ANAUTHORIZED_URLS = ['/deals', '/listing'];
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private pubnubService: PubnubService,
    private headerFooterStateService: HeaderFooterStateService,
    private router: Router,
  ) {}

  parseSocialAuth(url): { code: string; provider: string } {
    const provider = url.indexOf('state=google-oauth2') > 0 ? 'google-oauth2' : 'facebook';
    let code = '';
    if (provider === 'google-oauth2') {
      code = url.substring(url.indexOf('code=') + 5, url.indexOf('&scope'));
    } else {
      code = url.substring(url.indexOf('code=') + 5, url.indexOf('&state'));
    }
    return { code, provider };
  }

  // eslint-disable-next-line consistent-return
  canActivate(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    route: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = window.location.href;
    if (url.indexOf('code=') > 0) {
      const parsedData = this.parseSocialAuth(url);
      this.userService.signWithSocial(parsedData.code, parsedData.provider).subscribe(
        (answer) => {
          this.userService.setAuthToken(answer.token);
          this.userService.getMe().subscribe(
            (user) => {
              this.userService.setUser(user.data_user);
              this.pubnubService.start(user.data_user.id);
              if (this.userService.isVerified()) {
                this.headerFooterStateService.setView('main');
                this.router.navigate(['deals']);
                return true;
              }
              this.router.navigate(['signup']);
              return false;
            },
            () => {
              this.router.navigate(['login']);
              return false;
            },
          );
        },
        () => {
          this.router.navigate(['login']);
          return false;
        },
      );
    } else {
      if (this.userService.isVerified()) {
        return true;
      }
      if (ALLOWED_ANAUTHORIZED_URLS.some((el) => state.url.includes(el))) {
        return true;
      }
      this.router.navigate(['login']);
      return false;
    }
  }
}
