<section class="terms-container">
  <h1 class="title-centered">Privacy Policy</h1>
  <div class="terms">
    <div class="custom-selector-wrapper">
      <div class="custom-selector">
        <div class="custom-selector__trigger"><span>First Section Goes Here</span>
          <div class="arrow"></div>
        </div>
        <div class="custom-options">
          <span class="custom-option selected" data-value="1">First Section Goes Here</span>
          <span class="custom-option" data-value="2">Second Section Goes Here</span>
          <span class="custom-option" data-value="3">Third Section Goes Here</span>
          <span class="custom-option" data-value="4">Fourth Section Goes Here</span>
          <span class="custom-option" data-value="5">Fifth Section Goes Here</span>
          <span class="custom-option" data-value="6">Sixth Section Goes Here</span>
          <span class="custom-option" data-value="7">Seventh Section Goes Here</span>
          <span class="custom-option" data-value="8">Eight Section Goes Here</span>
        </div>
      </div>
    </div>
    <div class="terms__description">
      <h4 class="terms__title">Lorem ipsum dolor sit amet,</h4>
      <p>
        Cconsectetur adipiscing elit. Nam porttitor sapien et massa vulputate, non consectetur
        libero tincidunt. Curabitur viverra iaculis consequat. Pellentesque est ligula, interdum
        eget lectus non, consectetur tristique urna. Quisque sed luctus nisi. Donec tincidunt
        aliquet sapien. Pellentesque et nulla vitae quam feugiat consequat et sit amet lectus.
        Pellentesque accumsan, mi id pellentesque tristique, arcu eros rutrum augue, ac ultrices
        dolor diam a dui. Nullam non massa vel odio pharetra accumsan. Duis eget augue ipsum. Nunc
        faucibus dolor sed risus suscipit feugiat. Vivamus vulputate velit ut diam lacinia rhoncus.
        Fusce sit amet eros tellus.
      </p>
      <p>
        Integer vel nibh diam. Integer ac semper felis. Ut tincidunt porttitor dui, ut gravida nulla
        pretium eget. Nullam vitae turpis sit amet ex commodo tristique. Maecenas pretium porttitor
        leo. Donec mollis a felis non imperdiet. Suspendisse felis mi, tincidunt vel tristique eu,
        tristique sed metus. Duis sed auctor metus. Nullam a lacus id lacus consectetur vehicula.
      </p>
      <h4 class="terms__title">Aliquam sed condimentum lectus,</h4>
      <p>
        Sit amet euismod nisl. Morbi imperdiet aliquet nisi non tristique. Vestibulum eu faucibus
        ex, at aliquet lectus. Donec elit eros, lacinia in diam ut, facilisis bibendum tortor. Sed
        et metus justo. Nulla non orci consectetur, suscipit enim non, blandit purus. Donec id
        gravida nulla. Praesent aliquam nulla vitae orci mollis, in lobortis metus ultricies. Aenean
        vitae maximus turpis. Sed sagittis elementum dapibus. Phasellus pulvinar et magna sit amet
        eleifend. Mauris lobortis mi faucibus, venenatis neque vel, suscipit justo. Vivamus sit amet
        ligula purus.
      </p>
    </div>
  </div>
</section>
