/* eslint-disable no-param-reassign */
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlaceOfferDialogComponent } from 'src/app/common/dialogs/place-offer-dialog/place-offer-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PostedDialogComponent } from 'src/app/common/dialogs/posted-dialog/posted-dialog.component';
import { Subject } from 'rxjs/internal/Subject';
import { filter, takeUntil } from 'rxjs/operators';
import { ListingService } from 'src/app/listings/listing.service';
import { ConfirmationDialogComponent } from 'src/app/common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Observable } from 'rxjs';
import { GeneralDialogComponent } from 'src/app/common/dialogs/general-dialog/general-dialog.component';
import { PubNubAngular } from 'pubnub-angular2';
import { UserInterface } from '../../user/user.interface';
import { OfferService } from '../offer.service';
import {
  DealStatusEnum,
  ListingInfo,
  MessageInterface,
  MessageStatus,
  OfferInterface,
  OfferNdaStatusEnum,
  OfferStatusEnum,
  OfferStatuses,
} from '../../listings/listing.interface';
import { UserService } from '../../user/user.service';
import { PubnubService } from './pubnub.service';
import { RatePersonDialogComponent } from '../../common/dialogs/rate-person-dialog/rate-person-dialog.component';

@Component({
  selector: 'pubnub',
  templateUrl: './pubnub.component.html',
  styleUrls: ['./pubnub.component.scss'],
})
export class PubnubComponent implements OnInit, OnDestroy {
  @Input() offer: OfferInterface;

  @Input() user: UserInterface;

  @Input() dissable = false;

  @Input() isInvestor: boolean;

  @Input() mobileMode = false;

  @Input() investors$: Observable<OfferInterface[]>;

  @Output() newMessages = new EventEmitter<number>();

  @ViewChild('messageArray') messageArray: ElementRef;

  @Output() refreshData = new EventEmitter<boolean>();

  message = '';

  messagesLoaded = false;

  offerID: number;

  messages: MessageInterface[] = [];

  isMessageStampUpdated = true;

  scrolltop: number = null;

  lastReadMessageStamp = '0';

  lastMessageStamp = '0';

  private destroy$ = new Subject<void>();

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private offersService: OfferService,
    private listingService: ListingService,
    private router: Router,
    private pubnubService: PubnubService,
    private pubnub: PubNubAngular,
  ) {}

  scrollDown(): void {
    this.scrolltop = this.messageArray.nativeElement.scrollHeight;
  }

  ngOnInit(): void {
    this.pubnubService
      .getSentMessage$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((item) => {
        this.messages[item.messageNumber - 1] = item.messageBody;
      });
    this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.offerID = params.id;
      this.messages = [];
      this.pubnubService.subscribePubnub();
      this.getMessages(params.id);
      if (!this.isInvestor) {
        this.investors$.pipe(takeUntil(this.destroy$)).subscribe((item) => {
          const channels = item.map((offer) => {
            return offer.id.toString();
          });
          this.findNewMessages(channels);
        });
      }
    });
    this.pubnubService
      .getNewMessages$()
      .pipe(
        takeUntil(this.destroy$),
        filter((res) => Object.keys(res).length !== 0),
      )
      .subscribe((messages) => this.solveNewMessage(messages));
  }

  findNewMessages(channels: string[]): void {
    channels.forEach((channel) => {
      this.pubnub.fetchMessages({ channels: [channel], count: 100 }, (status, msg) => {
        let lastReadMessageStamp = null;
        let lastMessageStamp = null;
        if (msg?.channels) {
          msg.channels[channel].forEach((message) => {
            if (
              typeof message.message === 'string' &&
              message.uuid !== this.user.id.toString() &&
              lastMessageStamp < message.timetoken
            ) {
              lastMessageStamp = message.timetoken;
            }
            if (
              typeof message.message !== 'string' &&
              message.uuid === this.user.id.toString() &&
              lastReadMessageStamp < message.message.lastRead
            ) {
              lastReadMessageStamp = message.message.lastRead;
            }
          });
        }
        if (
          lastMessageStamp &&
          (!lastReadMessageStamp || lastMessageStamp > lastReadMessageStamp)
        ) {
          this.newMessages.emit(+channel);
        }
      });
    });
  }

  solveNewMessage(item): void {
    const msg = item[this.offerID]?.message;
    if (msg && msg.message && msg.channel === this.offerID && !this.dissable) {
      if (typeof msg.message === 'string') {
        const scroll = this.messageArray?.nativeElement;
        let isLastMsg = false;
        if (scroll) {
          const scrollPosition = scroll.scrollHeight - scroll.offsetHeight;
          isLastMsg = scroll.scrollTop >= scrollPosition;
        }
        msg.date = new Date(Math.ceil(+msg.timetoken / 10000));
        this.messages.push(msg);
        if (!this.dissable) {
          this.pubnubService.readMessage(this.offerID.toString(), msg.timetoken);
        }
        if (isLastMsg) {
          setTimeout(() => this.scrollDown(), 0);
        }
      } else {
        this.checkReadedMessage(msg.message.lastRead);
      }
    } else if (msg && typeof msg.message === 'string') {
      this.newMessages.emit(msg.channel);
    } else {
      Object.keys(item).forEach((key) => {
        if (item[key].hasNewMessage) {
          if (this.pubnubService.offerToListing.get(+key)) {
            this.newMessages.emit(+key);
          }
        }
      });
    }
  }

  checkUserHaveFirstOffer(): void {
    const modalState = this.userService.getModalState();
    if (!modalState.firstBuyerOffer) {
      modalState.firstBuyerOffer = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Your First Bid',
          text: 'Please do your due diligence to make sure that this is a good deal.',
          subText:
            'This includes: making sure the lister has the home under contract, double check your number, etc.',
          buttonText: 'Gotcha, Let’s Go.',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  checkSellersFirstMessage(): void {
    const modalState = this.userService.getModalState();
    if (!modalState.firstBuyerOpenedChat && this.isInvestor) {
      modalState.firstBuyerOpenedChat = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Be Smart About It.',
          text: `In your messages, explain to the lister why you're the right buyer for this deal.
Being as detailed as possible helps build trust with the lister.`,
          buttonText: 'Gotcha, Let’s Go.',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  checkFirstAcceptedOffer(): void {
    const modalState = this.userService.getModalState();
    if (!modalState.firstAcceptedOfferSeller && !this.isInvestor) {
      modalState.firstAcceptedOfferSeller = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Congratulations',
          text: `You are one step closer to closing your first deal using dealup. 
If you haven't already, we highly recommend requesting all information from the buyer to open escrow and work on getting clear title. `,
          buttonText: 'Thank you',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  checkReadedMessage(time: string): void {
    this.messages = this.messages.map((message) => {
      return this.addStatusForMessage(message, time);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  trackByFn(index: number, item: MessageInterface): string {
    return item.uuid;
  }

  sendMessage(): void {
    if (this.message.trim()) {
      this.createMessage(this.message);
      this.message = '';
      this.checkSellersFirstMessage();
    }
  }

  createMessage(message: string): void {
    this.messages.push({
      channel: this.offerID.toString(),
      message,
      status: MessageStatus.Created,
      uuid: this.user.id.toString(),
      date: new Date(),
      timetoken: null,
    });
    this.pubnubService.sendMessage(this.offerID, message, this.user.id, this.messages.length);
    setTimeout(() => this.scrollDown(), 0);
  }

  addStatusForMessage(message, time): MessageInterface {
    message.date = new Date(Math.ceil(+message.timetoken / 10000));
    if (this.isInvestor && message.status !== MessageStatus.Readed) {
      message.status = message.timetoken > time ? MessageStatus.Sent : MessageStatus.Readed;
    } else {
      message.status = message.timetoken > time ? MessageStatus.Sent : MessageStatus.Readed;
    }
    return message;
  }

  getMessages(id: number, timeToken?: string, messages: MessageInterface[] = []): void {
    const request = timeToken
      ? { channels: [id], count: 100, start: timeToken }
      : { channels: [id], count: 100 };
    this.pubnubService.pubnub.fetchMessages(request, (status, msg) => {
      if (msg?.channels) {
        messages.unshift(
          ...msg.channels[id]
            .filter((item) => typeof item.message === 'string')
            .map((message) => {
              if (
                message.uuid !== this.user.id.toString() &&
                this.lastMessageStamp < message.timetoken
              ) {
                this.lastMessageStamp = message.timetoken;
              }
              return message;
            }),
        );
        msg.channels[id]
          .filter((item) => typeof item.message !== 'string')
          .forEach((message) => {
            if (
              message.uuid !== this.user.id.toString() &&
              this.lastReadMessageStamp < message.message.lastRead
            ) {
              this.lastReadMessageStamp = message.message.lastRead;
            }
          });
        setTimeout(() => this.scrollDown(), 0);
        if (msg.channels[id].length === 100) {
          this.getMessages(id, msg.channels[id][0].timetoken, messages);
        } else {
          this.messages = messages;
          this.checkReadedMessage(this.lastReadMessageStamp);
          if (!this.dissable) {
            this.pubnubService.readMessage(this.offerID.toString(), this.lastMessageStamp);
          }
        }
      }
      this.messagesLoaded = true;
    });
  }

  updateOfferStatus(status: OfferStatusEnum, ndaStatus?: OfferNdaStatusEnum): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Do you want to proceed?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pubnubService.sendRefreshListing(this.offer.listing.id);
        const data: OfferStatuses = { status };
        if (ndaStatus) {
          data.status_nda = ndaStatus;
        }
        this.offersService.updateOffer(this.offer.id, data).subscribe(() => {
          if (status === OfferStatusEnum.deal_closed) {
            this.checkFirstAcceptedOffer();
          }
          this.refreshData.emit(true);
        });
      }
    });
  }

  updateListingStatus(status: DealStatusEnum): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Do you want to proceed?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.pubnubService.sendRefreshListing(this.offer.listing.id);
        this.listingService.updateListingStaus(this.offer.listing.id, status).subscribe(() => {
          this.refreshData.emit(true);
          if (status === 'closed') {
            this.rateOffer();
          }
        });
      }
    });
  }

  deleteListing(id: number): void {
    this.pubnubService.deleteListing(id);
  }

  rateOffer(): void {
    const dialogRef = this.dialog.open(RatePersonDialogComponent, {
      width: '800px',
      height: '200px',
      data: {
        isInvestor: this.isInvestor,
        person: this.isInvestor ? this.offer.listing.seller : this.offer.buyer,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.offersService.setRate(this.offer.id, result).subscribe(() => {});
      }
    });
  }

  makeNewOffer(): void {
    const dialogRef = this.dialog.open(PlaceOfferDialogComponent, {
      width: '800px',
      height: '600px',
      data: this.offer.listing.higher_offer_price,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((price) => {
        if (price) {
          (this.isInvestor
            ? this.offersService.updateOffer(this.offer.id, { price })
            : this.offersService.counterOffer(this.offer.id, { price })
          )
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
              const user = this.userService.getUser();
              if (!this.isInvestor) {
                const message = this.pubnubService.createCounterOfferMessage(
                  user.id,
                  this.offer.id.toString(),
                  price,
                  this.offer.buyer.first_name,
                );
                this.createMessage(message);
              } else {
                const postedDialogRef = this.dialog.open(PostedDialogComponent, {
                  width: '800px',
                  height: '375px',
                  panelClass: 'posted-dialog',
                  data: {
                    type: 'offer',
                    warningLabel: this.offer.listing.higher_offer_price > price,
                  },
                });
                postedDialogRef.afterClosed().subscribe(() => {
                  if (this.isInvestor) {
                    this.checkUserHaveFirstOffer();
                    this.refreshData.emit(true);
                  }
                });
              }
              this.pubnubService.sendRefreshListing(this.offer.listing.id);
            });
        }
      });
  }

  isMakeOfferBtnDisplayed(): boolean {
    return (
      this.isInvestor &&
      ![OfferStatusEnum.deal_closed, OfferStatusEnum.deal_rejected].includes(this.offer.status)
    );
  }

  getSource(listing: ListingInfo): string {
    return listing.photo.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }
}
