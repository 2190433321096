import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AppComponent } from '../app.component';
import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './signup/sign-up.component';
import { UserService } from './user.service';
import { PhoneMaskDirective } from '../common/directives/phone-mask/phone-mask.directive';
import { AuthInterceptor } from './auth.interceptor';
import { CitiesInputComponent } from '../common/cities-input/cities-input.component';
import { PasswordRecoveryContainerComponent } from './password-recovery-container/password-recovery-container.component';
import { EqualValidator } from '../common/directives/equal-validator/equal-validator.directive';
import { SharedDependenciesModule } from '../common/shared-dependencies/shared-dependencies.module';

@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    PhoneMaskDirective,
    CitiesInputComponent,
    PasswordRecoveryContainerComponent,
    EqualValidator,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    MatIconModule,
    RouterModule,
    RouterModule,
    Ng2TelInputModule,
    SharedDependenciesModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  exports: [SignUpComponent, PhoneMaskDirective, EqualValidator, CitiesInputComponent],
  providers: [
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class UserModule {}
