import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderFooterStateService } from '../services/header-footer-state.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  viewSubscription: Subject<string>;

  constructor(private headerFooterStateService: HeaderFooterStateService) {}

  ngOnInit(): void {
    this.viewSubscription = this.headerFooterStateService.getView$();
  }
}
