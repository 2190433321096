import { ModuleWithProviders, NgModule } from '@angular/core';
import { PubNubAngular } from "pubnub-angular2";


@NgModule({})
export class SharedDependenciesModule {
  static forRoot(): ModuleWithProviders<SharedDependenciesModule> {
    return {
      ngModule: SharedDependenciesModule,
      providers: [PubNubAngular],
    };
  }
}
