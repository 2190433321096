import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { validFileTypes } from '../directives/photo-file.constant';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { GalleryMediaInterface } from '../../listings/listing.interface';
import { ListingService } from '../../listings/listing.service';
import { HttpLoaderService } from '../interceptor/http-loader.service';

@Component({
  selector: 'gallery-container',
  templateUrl: './gallery-container.component.html',
  styleUrls: ['./gallery-container.component.scss'],
})
export class GalleryContainerComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() items: GalleryMediaInterface[] = [];

  @Input() minNumberOfItems = 0;

  width = 728;

  height = 558;

  thumbnailsTransition = 0;

  thumbnailsMargin = 0;

  selectedImage = 0;

  showFullScreenFlag = false;

  imageValidationError = false;

  constructor(
    private dialog: MatDialog,
    private listingService: ListingService,
    private loaderService: HttpLoaderService,
  ) {}

  imageMoveLeft(): void {
    if (this.selectedImage !== 0) {
      this.selectedImage--;
    }
  }

  imageMoveRight(): void {
    if (this.selectedImage !== this.items.length - 1) {
      this.selectedImage++;
    }
  }

  selectImage(index: number): void {
    this.selectedImage = index;
  }

  isLeftButtonDisabled(): boolean {
    return this.thumbnailsTransition === 0;
  }

  isRightButtonDisabled(): boolean {
    return this.items.length - Math.abs(this.thumbnailsTransition) / (this.width / 5) <= 5;
  }

  showFullScreen(): void {
    this.showFullScreenFlag = true;
  }

  closeFullScreen(): void {
    this.showFullScreenFlag = false;
  }

  mediaUpload(files: FileList): void {
    Array.from(files).forEach((file) => {
      if (validFileTypes.includes(file.type)) {
        // Unload is peformed in media container component
        this.loaderService.onRequestStart();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (event): void => {
          const res = <string>event.target.result;
          let media: GalleryMediaInterface;
          if (res.includes('video')) {
            media = { video: res, id: this.items.length };
          } else {
            media = { image: res, id: this.items.length };
          }
          this.items.push(media);
          this.listingService.toAddMedia.push(media);
          this.listingService.mediaLength.next(this.items.length);
          this.listingService.mediaFiles = this.items;
        };
      } else {
        this.imageValidationError = true;
      }
    });
  }

  removeSelectedItem(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Are you sure want to delete?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.listingService.toAddMedia = this.listingService.toAddMedia.filter(
          (el) => el.id !== this.items[this.selectedImage].id,
        );
        this.listingService.toDeleteMedia.push(this.items[this.selectedImage]);
        this.items = this.items.filter((el) => el.id !== this.items[this.selectedImage].id);
        this.showFullScreenFlag = false;
        this.selectedImage =
          this.items.length !== 1 && this.selectedImage !== 0 ? this.selectedImage - 1 : 0;
        this.listingService.mediaLength.next(this.items.length);
        this.listingService.mediaFiles = this.items;
      }
    });
  }

  handleMediaPreview(item: GalleryMediaInterface): void {
    const i = this.items.findIndex((el) => el.id === item.id && el.video);
    this.items[i].preview_image = item.preview_image;
  }

  trackByFn(index: number, item: GalleryMediaInterface): number {
    return item.id;
  }
}
