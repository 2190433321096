<ng-container *ngIf="state === 'listing' && listing">
  <add-edit-listing
    [listing]="listing"
    [isAddMode]="!id"
    (submitListing)="nextState()"
  ></add-edit-listing>
</ng-container>
<ng-container *ngIf="state === 'comparison' && listing">
  <add-edit-comparison
    [listing]="listing"
    (back)="backState()"
    [isAddMode]="!id"
    (saveChanges)="submit($event)"
  ></add-edit-comparison>
</ng-container>
