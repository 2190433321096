<div [ngClass]="{ 'filter__search input': view === 'home' }">
  <button type="submit" class="search__btn" *ngIf="view === 'home'"></button>
  <input
    ngx-google-places-autocomplete
    [options]="googlePlacesOptions"
    [placeholder]="placeholder"
    #placesRef="ngx-places"
    [(ngModel)]="cityInput"
    (onAddressChange)="handleAddressChange($event)"
    type="text"
    class="search sign-input input"
  />
</div>
<div class="selected-place" [ngClass]="{ 'mt-2 d-flex': view === 'default' }">
  <div class="selected-place__item" *ngFor="let city of cities; let i = index">
    {{ city }}
    <mat-icon class="m-auto" (click)="remove(i)">close</mat-icon>
  </div>
</div>
