import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from '../../user/user.service';
import { UserInterface } from '../../user/user.interface';
import { DealStatusEnum, ListingInterface } from '../listing.interface';
import { ListingService } from '../listing.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';

@Component({
  selector: 'shared-listings',
  templateUrl: './shared-listings.component.html',
  styleUrls: ['./shared-listings.component.scss'],
})
export class SharedListingsComponent {
  user: UserInterface;

  listings: ListingInterface[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private listingService: ListingService,
    private headerFooterStateService: HeaderFooterStateService,
  ) {
    this.activatedRoute.params.subscribe((route) => {
      if (route) {
        forkJoin([
          this.userService.getUserById(route.id),
          this.listingService.getListingsByUserId(route.id),
        ]).subscribe(([user, listings]) => {
          this.user = user.data_user;
          this.listings = listings.filter((listing) => listing.status !== DealStatusEnum.closed);
        });

        if (this.userService.isVerified()) {
          this.headerFooterStateService.setView('main');
        }
      }
    });
  }

  getSource(listing: ListingInterface): string {
    return listing.photo?.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }
}
