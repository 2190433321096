<ng-container *ngIf="viewSubscription | async as view" [ngSwitch]="view">
  <header *ngSwitchCase="'auth'" class="header static with-bg w-100">
    <a routerLink="/deals" class="logo logo-colored-white">
      <img src="/images/logo-white.svg" alt="dealup" class="logo_img" />
    </a>
    <nav class="menu">
      <ul class="menu__list ul-reset">
        <li class="menu__item">
          <a routerLink="/my-account" class="menu__link">Contact Us</a>
        </li>
      </ul>
    </nav>
  </header>
  <header *ngSwitchCase="'auth-black'" class="header static with-bg w-100">
    <a routerLink="/deals" class="logo logo-colored-white">
      <img src="/images/logo.svg" alt="dealup" class="logo_img" (click)="navigateBack()" />
    </a>
    <nav class="menu">
      <ul class="menu__list ul-reset">
        <li class="menu__item">
          <a routerLink="/my-account" class="menu__link">Contact Us</a>
        </li>
      </ul>
    </nav>
  </header>
  <header *ngSwitchCase="'unauthorized'" class="header">
    <a routerLink="/deals" class="logo">
      <img src="/images/logo.svg" alt="dealup" class="logo_img" />
    </a>
    <label class="menu__burger" (click)="openSideBar()"></label>
    <side-bar [isLoggedIn]="false"></side-bar>
    <nav class="header__menu menu--row menu">
      <ul class="menu__list ul-reset">
        <li class="menu__item">
          <a routerLink="/login" class="menu__link">Login or Sign Up Here</a>
        </li>
      </ul>
    </nav>
  </header>
  <header class="header" *ngSwitchDefault>
    <a routerLink="/deals" class="logo">
      <img src="/images/logo.svg" alt="dealup" class="logo_img" />
    </a>
    <label class="menu__burger" (click)="openSideBar()"></label>
    <side-bar [isLoggedIn]="true"></side-bar>
    <nav class="header__menu menu--row menu">
      <ul class="menu__list ul-reset">
        <li class="menu__item">
          <a routerLink="/deals" class="menu__link">Deals</a>
        </li>
        <li class="menu__item d-flex">
          <a routerLink="/my-offers" class="menu__link">My Offers</a>
          <div class="blue-circle" [hidden]="!isMessageInOffer"></div>
        </li>
        <li class="menu__item d-flex">
          <a routerLink="/my-listings" class="menu__link">My Listings</a>
          <div class="blue-circle" [hidden]="!isMessageInListing"></div>
        </li>
        <li class="menu__item">
          <a routerLink="/my-account" class="menu__link">Account</a>
        </li>
      </ul>
    </nav>
  </header>
  <header *ngSwitchCase="'mobile-hidden'" class="header m-hidden">
    <a routerLink="/deals" class="logo">
      <img src="/images/logo.svg" alt="dealup" class="logo_img" />
    </a>
    <input type="checkbox" id="menu-btn" class="menu-btn" aria-label="open menu" />
    <label for="menu-btn" class="menu__burger"></label>
    <nav class="header__menu menu--row menu">
      <ul class="menu__list ul-reset">
        <li class="menu__item">
          <a routerLink="/deals" class="menu__link">Deals</a>
        </li>
        <li class="menu__item d-flex">
          <a routerLink="/my-offers" class="menu__link">My Offers</a>
          <div class="blue-circle" [hidden]="!isMessageInOffer"></div>
        </li>
        <li class="menu__item d-flex">
          <a routerLink="/my-listings" class="menu__link">My Listings</a>
          <div class="blue-circle" [hidden]="!isMessageInListing"></div>
        </li>
        <li class="menu__item">
          <a routerLink="/my-account" class="menu__link">Account</a>
        </li>
      </ul>
    </nav>
  </header>
</ng-container>
