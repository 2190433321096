<div class="gallery-container">
  <input
    class="input-file-upload"
    id="image_upload"
    name="image_upload"
    type="file"
    multiple
    (change)="mediaUpload($event.target.files)"
    #fileInput
  />
  <div
    class="photo-box big drag-n-drop"
    *ngIf="!items.length"
    dragAndDrop
    (fileDropped)="mediaUpload($event)"
  >
    <h4 class="photo-box__caption">
      Drag and Drop
      <br />
      Your Video or Image
    </h4>
    <span class="photo-box__caption2"
      >or click <label for="image_upload" class="link">here</label> to upload</span
    >
    <div class="error"></div>
  </div>
  <div
    class="position-relative margin-bt"
    *ngIf="items.length"
    dragAndDrop
    (fileDropped)="mediaUpload($event)"
  >
    <div
      *ngFor="let item of items; let index = index; trackBy: trackByFn"
      [ngClass]="{ 'd-none': index !== selectedImage }"
    >
      <div class="image image__big">
        <media-container [media]="item" class="media-container"></media-container>
      </div>
    </div>
    <div class="full-screen" (click)="showFullScreen()"></div>
    <media-fullscreen-view
      [media]="items"
      [mediaIndex]="selectedImage"
      [showPreview]="showFullScreenFlag"
      (removeMediaItem)="removeSelectedItem()"
      (closePreview)="closeFullScreen()"
      (nextMedia)="imageMoveRight()"
      (prevMedia)="imageMoveLeft()"
    >  
    </media-fullscreen-view>
    <ng-container *ngIf="items.length > 1">
      <div class="d-flex arrows arrows__left" [ngClass]="{ arrows__disabled: selectedImage === 0 }">
        <i class="fa fa-arrow-circle-left" (click)="imageMoveLeft()"></i>
      </div>
      <div
        class="d-flex arrows arrows__right"
        (click)="imageMoveRight()"
        [ngClass]="{ arrows__disabled: selectedImage === items.length - 1 }"
      >
        <i class="fa fa-arrow-circle-right"></i>
      </div>
    </ng-container>
  </div>
  <div class="position-relative d-flex">
    <div class="add">
      <div class="photo-box mini">
        <label for="image_upload" class="add-new">ADD NEW</label>
      </div>
    </div>
    <div
      class="d-flex flex-row thumbnails hide-scrollbar"
      [style.transform]="'translateX(' + thumbnailsTransition + 'px)'"
      [style.marginLeft]="thumbnailsMargin + 'px'"
    >
      
      <div *ngFor="let item of items; let index = index; trackBy: trackByFn" class="thumbnail">
        <div (click)="selectImage(index)" class="thumbnail__item image image__small" [ngClass]="{ selected: index === selectedImage }">
          <media-container [media]="item" class="media-container" (mediaWithPreview)="handleMediaPreview($event)"></media-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="error" *ngIf="imageValidationError">
  Supported formats for avatar are mp4, .mov, .jpg, .jpeg and .png only. 
</div>
