import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GalleryMediaInterface, ListingInterface } from '../listing.interface';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { ListingService } from '../listing.service';

@Component({
  selector: 'add-edit-listing',
  templateUrl: './add-edit-listing.component.html',
  styleUrls: ['./add-edit-listing.component.scss'],
})
export class AddEditListingComponent implements OnInit {
  constructor(
    private headerFooterStateService: HeaderFooterStateService,
    private listingService: ListingService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.headerFooterStateService.setView('mobile-hidden');
  }

  @Input() listing: ListingInterface;

  @Input() isAddMode: boolean;

  @Output() submitListing: EventEmitter<boolean> = new EventEmitter<boolean>();

  medias: GalleryMediaInterface[] = [];

  mediaLength$: Observable<number>;

  googlePlacesOptions = {
    types: ['address'],
    componentRestrictions: { country: 'USA' },
  };

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.mediaLength$ = this.listingService.mediaLength;
    this.medias = this.listing.photo ? [...this.listing.photo, ...this.listing.video_pak] : [];
    if (this.listingService.mediaFiles.length) {
      this.medias = this.listingService.mediaFiles;
    }
    this.listingService.mediaLength.next(this.medias.length);
  }

  validMediaLength(): boolean {
    return this.listingService.mediaLength.value >= 3;
  }

  next(): void {
    this.listing.photo = this.medias.filter((el) => Boolean(el.image));
    this.listing.video_pak = this.medias.filter((el) => Boolean(el.video));
    this.submitListing.emit(true);
  }

  handleAddressChange(event: Address): void {
    this.listing.address = {
      readable_address: event.formatted_address,
      point: null,
    };
  }

  adressFieldValidator(address: string): boolean {
    // valid address example: 1222 1st Ave, San Diego, CA 92187, USA
    let err = false;
    if (address) {
      const checkAddressArr = address.split(', ');
      if (checkAddressArr.length !== 4 || checkAddressArr[checkAddressArr.length - 1] !== 'USA') {
        err = true;
      } else if (!parseInt(checkAddressArr[0].split(' ')[0], 10)) {
        err = true;
      }
    }
    return err;
  }

  deleteListing(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '800px',
      height: '200px',
      data: 'Are you sure want to delete this Listing?',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.listingService
          .deleteListing(this.listing.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.router.navigate(['/my-listings']));
      }
    });
  }
}
