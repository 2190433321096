import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { HeaderFooterStateService } from '../services/header-footer-state.service';
import { PubnubService } from '../../my-offers/pubnub/pubnub.service';
import { UnreadMessageInterface } from '../../listings/listing.interface';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  viewSubscription: Subject<string>;

  isMessageInOffer = false;

  isMessageInListing = false;

  private destroy$ = new Subject<void>();

  constructor(
    private headerFooterStateService: HeaderFooterStateService,
    private location: Location,
    private pubnubService: PubnubService,
    private navService: NavService,
  ) {}

  ngOnInit(): void {
    this.viewSubscription = this.headerFooterStateService.getView$();
    this.pubnubService
      .getNewMessages$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((messages) => this.updateUnreadStatus(messages));
  }

  updateUnreadStatus(unreadMessage: UnreadMessageInterface): void {
    this.isMessageInOffer = false;
    this.isMessageInListing = false;
    Object.keys(unreadMessage).forEach((key) => {
      if (unreadMessage[key].hasNewMessage) {
        if (this.pubnubService.offerToListing.get(+key)) {
          // todo add later this.isMessageInListing = true;
        } else {
          // todo add later this.isMessageInOffer = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  navigateBack(): void {
    this.location.back();
  }

  openSideBar(): void {
    this.navService.setShowNav(true);
  }
}
