<div id="place-message" class="modal__content offer-popup">
  <h4 class="modal__title">Leave a message</h4>
  <p class="modal__text">What do you want to ask the seller?</p>
  <textarea
    id="message"
    name="message"
    class="modal__textarea"
    rows="1"
    placeholder="Enter Your Message Here"
    [(ngModel)]="message"
    autosize
  ></textarea>
  <span class="close close-popup" (click)="dialogRef.close()"></span>
</div>
<button class="modal__button btn submit" (click)="dialogRef.close(message)">Send Message</button>
