import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import * as moment from 'moment';
import { GalleryMediaInterface, OfferInterface } from '../../listings/listing.interface';

@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info.component.html',
  styleUrls: ['./offer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferInfoComponent implements OnInit {
  @Input() offer: OfferInterface;

  @Input() isInvestor = false;

  daysAfterCreation: number;

  imageIndex = 0;

  ngOnInit(): void {
    this.daysAfterCreation = moment().diff(moment(this.offer?.created_at), 'days');
  }

  changeImage(i: number): void {
    this.imageIndex = i;
  }

  getSrc(): string {
    if (this.offer) {
      return this.getArr()[this.imageIndex].image || this.getArr()[this.imageIndex].preview_image;
    }
    return '';
  }

  getArr(): GalleryMediaInterface[] {
    if (this.offer) {
      return [...this.offer?.listing.photo, ...this.offer?.listing.video_pak];
    }
    return [];
  }

  trackByFn(index: number, item: GalleryMediaInterface): number {
    return item.id;
  }
}
