import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { PhoneNumberDialogComponent } from '../dialogs/phone-number-dialog/phone-number-dialog.component';
import { environment } from '../../../environments/environment';
import { UserInterface } from '../../user/user.interface';
import { ListingService } from '../../listings/listing.service';

@Component({
  selector: 'share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input() shareLink: string;

  @Input() user: UserInterface = null;

  message: string;

  constructor(
    private dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService,
    private toasterService: ToastrService,
    private listingService: ListingService,
  ) {}

  ngOnInit(): void {
    this.message = this.user
      ? 'Hi!\n' +
        `You’ve received all ${this.user.first_name} ${this.user.last_name} listings on DealUp.` +
        `You can see listings, place an offers,` +
        ` communicate and negotiate with the Seller here: ${this.shareLink}.\n` +
        'Best,\n' +
        '-Team DealUp'
      : 'Hi!\n' +
        'You’ve received a listing link on DealUp. ' +
        'You can see the listing, place an offer, ' +
        `communicate and negotiate with the Seller here: ${this.shareLink}.\n` +
        'Best,\n' +
        '-Team DealUp';
  }

  shareViaText(): void {
    if (this.deviceDetectorService.isMobile()) {
      if (this.user) {
        window.location.href = `sms:?body=${encodeURIComponent(this.message)}`;
      } else {
        window.location.href = `sms:?body=${encodeURIComponent(this.message)}`;
      }
    } else {
      const dialoRef = this.dialog.open(PhoneNumberDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
      });
      dialoRef.afterClosed().subscribe((phone) => {
        if (phone) {
          this.listingService.shareListingBySms(this.message, phone).subscribe(() => {});
        }
      });
    }
  }

  clipboardCopied(): void {
    this.toasterService.info('Link copied to the clipboard');
  }

  shareFacebook(): void {
    window.open(
      `https://www.facebook.com/dialog/share?` +
        `app_id=${environment.facebookClientId}` +
        `&display=popup&href=${this.shareLink}`,
      'facebook-popup',
      'height=350,width=600',
    );
  }
}
