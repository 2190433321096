<main class="my-listings">
  <section class="my-listings__wrapper">
    <div class="my-listings__title">
      <h3>Active Offers</h3>
      <p class="my-listings__subtitle">Offers that you’ve made a bid on that are still active.</p>
    </div>
    <div class="my-listings__list">
      <div
        class="card"
        *ngFor="let offer of openOffers; trackBy: trackByFn"
        [routerLink]="'/offer/' + offer.id"
      >
        <div *ngIf="offer.hasUnreadMessage" class="card__new-message">You Have A New Message</div>
        <img imageLoader [src]="getSource(offer.listing)" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              <span class="card__price-type">Your Offer</span> $ {{ offer.price | number: '.2' }}
            </div>
            <div class="card__description">
              <span class="card__description-type">{{ offer.listing.bedroom_amount }} BED</span>
              <span class="card__description-type">{{ offer.listing.bathroom_amount }} BATH</span>
              <span class="card__description-type"
                >{{ offer.listing.lot_size | number: '.2' }} SQFT</span
              >
            </div>
            <div class="card__address">{{ offer.listing.address.city }}</div>
          </div>
          <a class="card__btn"></a>
        </div>
      </div>
    </div>

    <div class="my-listings__title">
      <h3>Closed Deals</h3>
      <p class="my-listings__subtitle">Deals that you’ve succesfully Closed</p>
    </div>
    <div
      class="my-listings__list"
      *ngIf="closedOffers && closedOffers[0]"
      [routerLink]="'/offer/' + closedOffers[0].id"
    >
      <div class="card">
        <img imageLoader [src]="getSource(closedOffers[0].listing)" alt="img" class="card__img" />
        <div class="card__info">
          <div class="card__wrapper">
            <div class="card__price">
              $ {{ closedOffers[0].price | number: '.2' }} <span class="card__price-type">AVR</span>
            </div>
            <div class="card__description">
              <span class="card__description-type"
                >{{ closedOffers[0].listing.bedroom_amount }} BED</span
              >
              <span class="card__description-type"
                >{{ closedOffers[0].listing.bathroom_amount }} BATH</span
              >
              <span class="card__description-type"
                >{{ closedOffers[0].listing.lot_size | number: '.2' }} SQFT</span
              >
            </div>
            <div class="card__address">{{ closedOffers[0].listing.address.city }}</div>
          </div>
          <a [routerLink]="'/offer/' + closedOffers[0].id" class="card__btn"></a>
        </div>
      </div>
    </div>
    <div class="my-listings__table">
      <div class="my-listings__row" *ngFor="let offer of closedOffers | slice: 1">
        <div class="my-listings__img">
          <img [src]="getSource(offer.listing)" alt="" />
        </div>
        <div class="my-listings__info">
          <div class="my-listings__price">$ {{ offer.price | number: '.2' }}</div>
          <div class="my-listings__description">
            <span class="my-listings__description-type"
              >{{ offer.listing.bedroom_amount }} BED</span
            >
            <span class="my-listings__description-type"
              >{{ offer.listing.bathroom_amount }} BATH</span
            >
            <span class="my-listings__description-type"
              >{{ offer.listing.lot_size | number: '.2' }} SQFT</span
            >
          </div>
        </div>
        <div class="my-listings__address">{{ offer.listing.address.city }}</div>
        <div class="my-listings__link">
          <a [routerLink]="'/offer/' + offer.id" class="my-listings__link-btn"></a>
        </div>
      </div>
    </div>
  </section>
</main>
