<section class="faq-container">
  <div class="faq">
    <h1 class="title-centered">Frequently Asked Questions</h1>
    <div class="faq__row">
      <h4 class="faq__title">Title for the question goes here,</h4>
      <p class="faq__text">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </p>
    </div>
    <div class="faq__row">
      <h4 class="faq__title">
        Title for the question goes here, Title for the question goes here,
      </h4>
      <p class="faq__text">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </p>
    </div>
    <div class="faq__row">
      <h4 class="faq__title">
        Title for the question goes here, Title for the question goes here, Title for the question
        goes here,
      </h4>
      <p class="faq__text">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
        minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat
        duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </p>
    </div>
    <div class="faq__row">
      <h4 class="faq__title">Title for the question goes here,</h4>
      <p class="faq__text">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </p>
    </div>
    <div class="faq__row">
      <h4 class="faq__title">Title for the question goes here,</h4>
      <p class="faq__text">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
        consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
      </p>
    </div>
  </div>
</section>
