import { Component } from '@angular/core';
import { UserService } from '../../user/user.service';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';

declare function customSelect(): any;

@Component({
  selector: 'terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss'],
})
export class TermsOfServiceComponent {
  constructor(
    private userService: UserService,
    private headerFooterStateService: HeaderFooterStateService,
  ) {
    if (!this.userService.isAuthenticated()) {
      this.headerFooterStateService.setView('auth-black');
    } else {
      this.headerFooterStateService.setView('main');
    }
  }
  ngOnInit() {
    customSelect();
  }
}
