import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {
  LoginResponseInterface,
  ModalState,
  UserInterface,
  UserResponseInterface,
} from './user.interface';
import { PubnubService } from '../my-offers/pubnub/pubnub.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user$: BehaviorSubject<UserInterface> = new BehaviorSubject<UserInterface>(
    JSON.parse(localStorage.getItem('user')),
  );

  constructor(
    private http: HttpClient,
    private router: Router,
    private pubnubService: PubnubService,
  ) {
    if (this.isVerified()) {
      this.getMe().subscribe((user) => {
        this.setUser(user.data_user);
        this.pubnubService.start(user.data_user.id);
      });
    }
  }

  setAuthToken(authToken: string): void {
    localStorage.setItem('token', authToken);
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('token') !== null && localStorage.getItem('user') !== null;
  }

  isVerified(): boolean {
    return this.isAuthenticated() && JSON.parse(localStorage.getItem('user')).is_verified;
  }

  logout(): void {
    this.pubnubService.unsubscribePubnub();
    this.pubnubService.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('lastZoom');
    localStorage.removeItem('lastCities');
    this.router.navigate(['/login']);
  }

  public getUser(): UserInterface {
    return this.user$.getValue();
  }

  signUp(user): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}signup/`, user);
  }

  signWithSocial(code: string, provider: string): Observable<LoginResponseInterface> {
    const data = {
      code: decodeURIComponent(code),
      provider,
    };
    return this.http.post<LoginResponseInterface>(
      `${environment.apiUrl}login_social/social/token/`,
      data,
    );
  }

  redirectToGoogleAuth(): void {
    window.location.href = `${
      'https://accounts.google.com/o/oauth2/v2/auth?' +
      'scope=https%3A//www.googleapis.com/auth/userinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&' +
      'access_type=offline&' +
      'include_granted_scopes=true&' +
      'response_type=code&' +
      'state=google-oauth2&' +
      'redirect_uri='
    }${window.location.origin}/&client_id=${environment.googleClientId}`;
  }

  redirectToFacebookAuth(): void {
    window.location.href =
      `${'https://www.facebook.com/v10.0/dialog/oauth?client_id='}${
        environment.facebookClientId
      }&` +
      `redirect_uri=${window.location.origin}/&` +
      `state=facebook&` +
      `response_type=code&` +
      `scope=email,public_profile`;
  }

  login(username: string, password: string): Observable<LoginResponseInterface> {
    return this.http
      .post<LoginResponseInterface>(`${environment.apiUrl}login/`, {
        username,
        password,
      })
      .pipe(shareReplay());
  }

  sendVerificationPhone(phone): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}send_verification_code/`, phone);
  }

  sendVerificationCode(code): Observable<unknown> {
    return this.http.post(`${environment.apiUrl}verify_sms/`, code);
  }

  public getUser$(): BehaviorSubject<UserInterface> {
    return this.user$;
  }

  public setUser(user: UserInterface): void {
    this.user$.next(user);
    localStorage.setItem('user', JSON.stringify(user));
  }

  public getUserById(id: number): Observable<UserResponseInterface> {
    return this.http.get<UserResponseInterface>(`${environment.apiUrl}users/${id}`);
  }

  changePassword(
    password: string,
    newPassword: string,
    newPassword2: string,
  ): Observable<{ message: string; token: string }> {
    const body = {
      old_password: password,
      new_password: newPassword,
      new_password_confirm: newPassword2,
    };
    return this.http.put<{ message: string; token: string }>(
      `${environment.apiUrl}change_password/`,
      body,
    );
  }

  getModalState(): ModalState {
    return this.user$.value.modals_state?.length
      ? JSON.parse(this.user$.value.modals_state)
      : {
          firstSellerOffer: false,
          firstBuyerOffer: false,
          firstBuyerOpenedChat: false,
          firstAcceptedOffer: false,
        };
  }

  updateModalState(state: ModalState): Observable<UserResponseInterface> {
    const user = this.getUser();
    user.modals_state = JSON.stringify(state);
    this.setUser(user);
    return this.updateUser(user);
  }

  updateUser(user: UserInterface): Observable<UserResponseInterface> {
    const data = { ...user };
    if (user.photo == null || user.photo.indexOf('https') === 0) {
      delete data.photo;
    }
    return this.http.patch<UserResponseInterface>(`${environment.apiUrl}users/${user.id}/`, data);
  }

  public getMe(): Observable<UserResponseInterface> {
    return this.http.get<UserResponseInterface>(`${environment.apiUrl}whoami/`);
  }

  public forgotPassword(email: string): Observable<{ email: string }> {
    return this.http.post<{ email: string }>(`${environment.apiUrl}forgot_password/`, {
      email,
    });
  }

  public changeForgottenPassword(
    token,
    newPassword,
    newPassword2,
  ): Observable<{ message: string }> {
    const body = {
      reset_password_token: token,
      new_password: newPassword,
      new_password_confirm: newPassword2,
    };

    return this.http.post<{ message: string }>(
      `${environment.apiUrl}change_forgotten_password/`,
      body,
    );
  }
}
