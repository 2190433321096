import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from 'src/app/common/dialogs/general-dialog/general-dialog.component';
import { Subscription } from 'rxjs';
import { ListingService } from '../listing.service';
import {
  DealStatusEnum,
  GalleryMediaInterface,
  GeneralListingInterface,
  ListingInterface,
} from '../listing.interface';
import { UserService } from '../../user/user.service';
import { UserInterface } from '../../user/user.interface';
import { PostedDialogComponent } from '../../common/dialogs/posted-dialog/posted-dialog.component';
import { PlaceOfferDialogComponent } from '../../common/dialogs/place-offer-dialog/place-offer-dialog.component';
import { PlaceMessageDialogComponent } from '../../common/dialogs/place-message-dialog/place-message-dialog.component';
import { HeaderFooterStateService } from '../../common/services/header-footer-state.service';
import { OfferService } from '../../my-offers/offer.service';
import { PubnubService } from '../../my-offers/pubnub/pubnub.service';

@Component({
  selector: 'listing',
  templateUrl: './listing-post.component.html',
  styleUrls: ['./listing-post.component.scss'],
})
export class ListingPostComponent implements OnDestroy {
  listing: ListingInterface;

  user: UserInterface;

  comparisonDateSold: Date;

  showFullScreenFlag = false;

  selectedImage = 0;

  notFoundError = false;

  displayAdditionalFooter = false;

  listingMedia: GalleryMediaInterface[] = [];

  labelOptions = {
    className: 'marker-text',
    color: '#4268EC',
    fontWeight: 'bold',
    text: 'General\nLocation',
    fontFamily: 'Poppins',
    fontSize: '12px',
  };

  mapIconOptions = {
    url: '',
    scaledSize: { height: 35, width: 35 },
    anchor: { x: 35, y: 20 },
    size: { height: 35, width: 70 },
  };

  shareLink = window.location.href;

  subscription: Subscription;

  constructor(
    private router: Router,
    private listingService: ListingService,
    private offersService: OfferService,
    private route: ActivatedRoute,
    private userService: UserService,
    private pubnubService: PubnubService,
    private dialog: MatDialog,
    private headerFooterStateService: HeaderFooterStateService,
  ) {
    this.subscription = this.route.params.subscribe((item) => {
      this.listingService.getListing(item.id).subscribe(
        (listing) => {
          this.listing = listing;
          this.listingMedia = [];
          this.displayAdditionalFooter = false;

          if (this.listing.video_pak?.length) {
            this.listingMedia.push(...this.listing.video_pak);
          }
          if (this.listing.photo?.length) {
            this.listingMedia.push(...this.listing.photo);
          }
          if (this.listing.seller.id !== this.userService.getUser()?.id) {
            if (!this.userService.isVerified()) {
              this.headerFooterStateService.setView('unauthorized');
            } else {
              this.headerFooterStateService.setView('additional-footer');
            }
            this.displayAdditionalFooter = true;
          } else {
            this.headerFooterStateService.setView('main');
          }
          this.comparisonDateSold = this.listing.comparison
            ? new Date(this.listing.comparison.date_sold)
            : null;
          this.user = { ...this.user, ...this.listing.seller };
        },
        () => {
          this.notFoundError = true;
        },
      );
    });
  }

  openFullScreen(number: number): void {
    this.showFullScreenFlag = true;
    this.selectedImage = number;
  }

  imageMoveLeft(): void {
    if (this.selectedImage !== 0) {
      this.selectedImage--;
    }
  }

  imageMoveRight(): void {
    if (this.selectedImage !== this.listingMedia.length - 1) {
      this.selectedImage++;
    }
  }

  closeFullScreen(): void {
    this.showFullScreenFlag = false;
  }

  toggleLike(): void {
    if (this.checkAuthority()) {
      return;
    }
    this.listingService.toggleLikeListing(this.listing.id).subscribe((item) => {
      this.listing = item;
    });
  }

  checkUserHaveFirstOffer(): void {
    const modalState = this.userService.getModalState();
    if (!modalState.firstBuyerOffer) {
      modalState.firstBuyerOffer = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Your First Bid',
          text: 'Please do your due diligence to make sure that this is a good deal.',
          subText:
            'This includes: making sure the lister has the home under contract, double check your number, etc.',
          buttonText: 'Gotcha, Let’s Go.',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  checkSellersFirstMessage(): void {
    const modalState = this.userService.getModalState();
    if (!modalState.firstBuyerOpenedChat) {
      modalState.firstBuyerOpenedChat = true;
      const dialoRef = this.dialog.open(GeneralDialogComponent, {
        width: '840px',
        height: '450px',
        panelClass: 'general-dialog',
        disableClose: true,
        data: {
          title: 'Be Smart About It.',
          text: `In your messages, explain to the lister why you're the right buyer for this deal.
Being as detailed as possible helps build trust with the lister.`,
          buttonText: 'Gotcha, Let’s Go.',
          showProtip: true,
        },
      });
      dialoRef.afterClosed().subscribe(() => {});
      this.userService.updateModalState(modalState).subscribe(() => {});
    }
  }

  placeOffer(): void {
    if (this.checkAuthority()) {
      return;
    }
    const dialogRef = this.dialog.open(PlaceOfferDialogComponent, {
      width: '800px',
      height: '600px',
      data: this.listing.higher_offer_price,
    });

    dialogRef.afterClosed().subscribe((item) => {
      if (item) {
        this.offersService.createOffer(this.listing.id, item).subscribe((offer) => {
          this.pubnubService.newOffer(this.listing.seller.id, offer.id, this.listing.id);
          const postedDialogRef = this.dialog.open(PostedDialogComponent, {
            width: '800px',
            height: '375px',
            panelClass: 'posted-dialog',
            data: {
              type: 'offer',
              warningLabel: this.listing.higher_offer_price > item,
            },
          });
          postedDialogRef.afterClosed().subscribe(() => {
            this.checkUserHaveFirstOffer();
            this.router.navigate([`/offer/${offer.id}`]);
          });
        });
      }
    });
  }

  placeMessage(): void {
    if (this.checkAuthority()) {
      return;
    }
    const dialogRef = this.dialog.open(PlaceMessageDialogComponent, {
      width: '800px',
      height: '600px',
    });

    dialogRef.afterClosed().subscribe((item) => {
      if (item) {
        this.offersService.createOffer(this.listing.id, null).subscribe((offer) => {
          this.pubnubService.newOffer(this.listing.seller.id, offer.id, this.listing.id, item);
          const postedDialogRef = this.dialog.open(PostedDialogComponent, {
            width: '800px',
            height: '375px',
            panelClass: 'posted-dialog',
            data: {
              type: 'message',
              warningLabel: false,
            },
          });
          postedDialogRef.afterClosed().subscribe(() => {
            this.checkSellersFirstMessage();
            this.router.navigate([`/offer/${offer.id}`]);
          });
        });
      }
    });
  }

  private checkAuthority(): boolean {
    if (!this.userService.isVerified()) {
      this.router.navigate(['login']);
      return true;
    }
    return false;
  }

  getSource(listing: GeneralListingInterface): string {
    return listing.photo.length ? listing.photo[0].image : listing.video_pak[0].preview_image;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isClosedListing(listing: ListingInterface): boolean {
    return listing.status === DealStatusEnum.closed || listing.status === DealStatusEnum.open;
  }
}
