import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'place-message-dialog',
  templateUrl: './place-message-dialog.component.html',
  styleUrls: ['./place-message-dialog.component.scss'],
})
export class PlaceMessageDialogComponent {
  message: string;

  constructor(public dialogRef: MatDialogRef<PlaceMessageDialogComponent>) {}
}
