<div id="place-offer" class="modal__content offer-popup">
  <h4 class="modal__title">Place Your Offer</h4>
  <p class="modal__text">What are you willing to pay for this deal?</p>
  <label for="price">
    Enter Your Offer Amount
    <span class="price-container">
      <input
        id="price"
        name="price"
        type="number"
        required
        [(ngModel)]="offer_price"
        class="modal__input-price"
        placeholder="Enter Your Offer Amount"
        [min]="0"
        #price="ngModel"
      />
    </span>
  </label>
  <div class="error" *ngIf="error">Please enter offer price</div>
  <p class="modal__info">CURRENT PRICE IS {{ listing_price | number }}</p>
  <button
    class="modal__button btn submit"
    [class.submit-disabled]="price.invalid"
    (click)="submit()"
  >
    Place Offer
  </button>
  <div class="close close-popup" (click)="dialogRef.close()"></div>
</div>
