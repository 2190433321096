import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { LatLngBoundsLiteral } from '@agm/core';
import {
  DealStatusEnum,
  GalleryMediaInterface,
  ListingAndOffers,
  ListingInterface,
  PopularCityInterface,
} from './listing.interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  toAddMedia: GalleryMediaInterface[] = [];

  toDeleteMedia: GalleryMediaInterface[] = [];

  mediaLength: BehaviorSubject<number> = new BehaviorSubject(0);

  mediaFiles: GalleryMediaInterface[] = [];

  constructor(private http: HttpClient) {}

  getListing(id: number): Observable<ListingInterface> {
    return this.http.get<ListingInterface>(`${environment.apiUrl}listings/${id}`);
  }

  toggleLikeListing(id: number): Observable<ListingInterface> {
    return this.http.post<ListingInterface>(`${environment.apiUrl}listings/${id}/like/`, {});
  }

  getOffersForListening(id: number): Observable<ListingAndOffers> {
    return this.http.get<ListingAndOffers>(`${environment.apiUrl}listings/${id}/offer/`);
  }

  createListing(listing: ListingInterface): Observable<Object> {
    return this.http.post(`${environment.apiUrl}listings/`, listing);
  }

  getMyListings(): Observable<ListingInterface[]> {
    return this.http.get<ListingInterface[]>(`${environment.apiUrl}listings/get_created/`);
  }

  addMedia(id: number): Observable<unknown[]> {
    const request$ = [];
    const bodyPhoto = {
      photo: this.toAddMedia.filter((el) => el.image),
    };
    const bodyVideo = {
      video_pak: this.toAddMedia.filter((el) => el.video),
    };
    if (bodyPhoto.photo.length) {
      request$.push(
        this.http.post<ListingInterface>(`${environment.apiUrl}listings/${id}/photo/`, bodyPhoto),
      );
    }
    if (bodyVideo.video_pak.length) {
      request$.push(
        this.http.post<ListingInterface>(`${environment.apiUrl}listings/${id}/video/`, bodyVideo),
      );
    }
    return forkJoin(request$);
  }

  deleteMedia(id: number): Observable<unknown[]> {
    const request$ = [];
    const bodyPhoto = {
      photo_ids: this.toDeleteMedia.filter((el) => el.image).map((el) => el.id),
    };
    const bodyVideo = {
      video_ids: this.toDeleteMedia.filter((el) => el.video).map((el) => el.id),
    };
    if (bodyPhoto.photo_ids.length) {
      request$.push(
        this.http.post<ListingInterface>(
          `${environment.apiUrl}listings/${id}/photo/delete/`,
          bodyPhoto,
        ),
      );
    }
    if (bodyVideo.video_ids.length) {
      request$.push(
        this.http.post<ListingInterface>(
          `${environment.apiUrl}listings/${id}/video/delete/`,
          bodyVideo,
        ),
      );
    }
    return forkJoin(request$);
  }

  /* eslint-disable no-param-reassign */
  updateListing(
    id: number,
    listing: ListingInterface,
    removeAdress: boolean,
  ): Observable<ListingInterface> {
    delete listing.photo;
    delete listing.video_pak;
    if (
      !listing.comparison?.photo.includes('data:image') &&
      !listing.comparison?.photo.includes('base64')
    ) {
      delete listing.comparison?.photo;
    }
    if (removeAdress) {
      delete listing.address;
    }
    return this.http.patch<ListingInterface>(`${environment.apiUrl}listings/${id}/`, listing);
  }

  updateListingStaus(id: number, status: DealStatusEnum): Observable<ListingInterface> {
    return this.http.patch<ListingInterface>(`${environment.apiUrl}listings/${id}/`, { status });
  }

  deleteListing(id: number): Observable<ListingInterface> {
    return this.http.delete<ListingInterface>(`${environment.apiUrl}listings/${id}/`);
  }

  getListingsByCoords(
    coords: LatLngBoundsLiteral,
    cities: string[] = undefined,
    sorting: string = null,
    priceRange: string = null,
  ): Observable<ListingInterface[]> {
    const body = {
      bbox: coords,
      cities,
    };
    let params = new HttpParams();

    if (sorting) {
      params = params.append('sort', sorting);
    }

    if (priceRange) {
      params = params.append('price_range', priceRange);
    }

    return this.http.post<ListingInterface[]>(`${environment.apiUrl}listings/get_by_area/`, body, {
      params,
    });
  }

  getMostPopularCity(cities: string[]): Observable<PopularCityInterface> {
    return this.http.post<PopularCityInterface>(`${environment.apiUrl}most_popular_city/`, {
      cities,
    });
  }

  getListingsByUserId(userId: number): Observable<ListingInterface[]> {
    return this.http.get<ListingInterface[]>(`${environment.apiUrl}users/${userId}/get_listings/`);
  }

  shareListingBySms(message: string, phone: number): Observable<{ message_sent: boolean }> {
    const body = {
      message,
      phone_number: phone,
    };
    return this.http.post<{ message_sent: boolean }>(`${environment.apiUrl}send_sms/`, body);
  }
}
