import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { HeaderFooterStateService } from 'src/app/common/services/header-footer-state.service';
import { UserInterface } from '../user.interface';
import { UserService } from '../user.service';
import { CropImageComponent } from '../../common/dialogs/crop-image/crop-image.component';
import { validFileTypes } from '../../common/directives/photo-file.constant';

export enum PriceRangeFromArray {
  '$0 - $50,000' = 0,
  '$50,000 - $100,000' = 50000,
  '$100,000 - $150,000' = 100000,
  '$150,000 - 200,000' = 150000,
  '$200,000 - 250,000' = 200000,
  '$250,000 - $300,000' = 250000,
  '$300,000+' = 300000,
}

export const PriceRangeFromString = {
  '$0 - $50,000': [0, 50000],
  '$50,000 - $100,000': [50000, 100000],
  '$100,000 - $150,000': [100000, 150000],
  '$150,000 - 200,000': [150000, 200000],
  '$200,000 - 250,000': [200000, 250000],
  '$250,000 - $300,000': [250000, 300000],
  '$300,000+': [300000, 9999999],
};

@Component({
  selector: 'my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent {
  @ViewChild('form') currentForm: NgForm;

  user: UserInterface;

  wantToChangePassword = false;

  imageValidationError = false;

  image: File = null;

  selectedItem: string;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private headerFooterStateService: HeaderFooterStateService,
    private toasterService: ToastrService,
  ) {
    this.user = this.userService.getUser();
    this.selectedItem = PriceRangeFromArray[this.user.price_range.lower];
    if (this.userService.isVerified()) {
      this.headerFooterStateService.setView('main');
    }
  }

  toggleWantToChangePassword(): void {
    this.wantToChangePassword = !this.wantToChangePassword;
  }

  submit(form: NgForm): void {
    const priceRange = PriceRangeFromString[this.selectedItem];
    if (priceRange) {
      this.user.price_range = {
        lower: priceRange[0],
        upper: priceRange[1],
        bounds: '[)',
      };
    }
    this.userService.updateUser(this.user).subscribe((item) => {
      this.toasterService.success('Account information is successfully saved');
      this.userService.setUser(item.data_user);
    });
    if (this.wantToChangePassword) {
      this.userService
        .changePassword(form.value.password, form.value.newPassword, form.value.newPassword2)
        .subscribe(
          (item) => {
            this.userService.setAuthToken(item.token);
            this.toasterService.success('New password successfully saved');
          },
          (error) => {
            if (error instanceof HttpErrorResponse && error.status === 400) {
              this.currentForm.control.setErrors({ responseError: true });
            }
          },
        );
    }
  }

  photoUpload(file: File): void {
    if (validFileTypes.includes(file.type)) {
      this.imageValidationError = false;
      const dialogRef = this.dialog.open(CropImageComponent, {
        width: '800px',
        data: file,
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.user.photo = result;
      });
    } else {
      this.imageValidationError = true;
    }
  }

  logout(): void {
    this.userService.logout();
  }
}
